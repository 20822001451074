<template>
  <div
    class="
      container
      d-flex d-xl-flex
      flex-column
      align-items-center align-items-xl-center
      py-4 py-xl-5
    "
  >
    <div class="row">
      <div class="col-lg-1">
        <i
          class="icon ion-ios-pricetag-outline mt-2"
          :class="{
            'text-white': theme == 'dark',
            'text-dark': theme == 'light',
          }"
          style="font-size: 50px; margin: 0px"
        ></i>
      </div>

      <div class="col-lg-11">
        <p
          :class="{
            'text-white': theme == 'dark',
            'text-dark': theme == 'light',
          }"
        >
          {{ t("valuation") }}
        </p>
      </div>
    </div>
    <div
      class="text-center text-white-50 border rounded border-0 p-3"
      :class="{ 'bg-primary': theme == 'dark', 'bg-dark': theme == 'light' }"
      style="width: 100%"
    >
      <div
        class="
          row
          d-flex d-xl-flex
          justify-content-center justify-content-xl-center
        "
      >
        <div class="col-12 col-md-4">
          <Price
            :valuationPrice="`${valuationData['price_min']} ${currency}`"
            :description="t('min_price')"
          />
        </div>
        <div class="col-12 col-md-4">
          <Price
            :valuationPrice="`${valuationData['price_med']} ${currency}`"
            :description="t('med_price')"
          />
        </div>
        <div class="col-12 col-md-4">
          <Price
            :valuationPrice="`${valuationData['price_max']} ${currency}`"
            :description="t('max_price')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Price from "./Price";
import { useI18n } from "vue-i18n";

export default {
  name: "PriceSection",
  components: {
    Price,
  },
  props: {
    valuationData: Object,
    theme: String,
    currency: String,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<style>
</style>