export const ru = {
  car_valuation: "КЛАССИФИКАЦИЯ ТРАНСПОРТНОГО СРЕДСТВА",
  toggle_navigation: "переключить навигацию",
  vin: "ВИН",
  plate: "стол",
  mileage: "пробег",
  first_reg_date: "Дата первой регистрации",
  check_valuation: "оценка",
  contact: "Контакт",
  privacy_policy: "Политика конфиденциальности",
  regulations: "устав",
  valuation:
    "Оценка автомобиля подготовлена ​​на основе многолетнего опыта работы наших специалистов на автомобильном рынке. Процедура оценки использует искусственный интеллект (ИИ) для сравнения меняющихся тенденций цен на автомобили на европейском и американском рынках. При оценке, конечно же, учитываются такие параметры, как возраст автомобиля и его пробег. Наша оценка также учитывает последние автомобильные и социальные проблемы, такие как нехватка узлов или компонентов и инфляция, которые влияют на цену подержанных автомобилей.",
  min_price: "Минимальная цена",
  med_price: "Средняя цена",
  max_price: "максимальная цена",
  we_have_report_for_you: "У нас есть отчет по этому автомобилю!",
  incident_history: "история инцидента",
  odometer_reading: "прочитать пробег",
  vehicles_inspection: "техосмотр транспортных средств",
  archive_photos: "архив фото",
  check_now: "ПОСМОТРЕТЬ ОТЧЕТ",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Оценить другое транспортное средство",
  type_plate: "Введите регистрационный номер автомобиля.",
  type_first_reg_date: "",
  price_odometer: "цена",
  price_year: "цена",
  year: "Год",
  next: "Следующий",
  help: "Помощь",
  no_data_valuation: "Нет данных для оценки",
  check_if_stolen: "Проверить, не украли",
  decode_vin_number: "Расшифровать вин номер",
  additional_links: "Дополнительные ссылки",
  check_vin_correct: "Проверить правильность VIN номера",
  how_to_vin_valuation: "Как оценить автомобиль перед покупкой/продажей?",
  calculator_valuation: "Онлайн калькулятор оценки автомобиля",
  calculator_valuation_desc:
    "Есть много способов оценить стоимость автомобиля, самым быстрым и относительно точным будет проверка на портале оценки vin. Наш запатентованный алгоритм, поддерживаемый искусственным интеллектом (ИИ), собирает данные из тысяч интернет-рекламы, точно анализируя возраст, марку, оснащение и состояние выставленных автомобилей. Исходя из этого, мы оцениваем, сколько должна стоить машина, которую вы проверяете.",
  you_get_valuation_for_free:
    "Вы получите <strong>предложение</strong> через несколько минут, не выходя из дома, <strong>совершенно бесплатно</strong> . Все, что нам нужно, это несколько параметров, таких как номер VIN или дата регистрации автомобиля.",
  in_response_you_receive_not_only_valuation:
    "В ответ вы получите не только оценку винтажа, который вы проверяете, но и несколько ваших самых близких, младших и старших. Благодаря этому у вас есть полное представление о том, например, стоит ли им еще владеть, или же вы не слишком много потеряете на нем и хорошо бы сразу его продать.",
  compare_market_price: "Сравните цены на рынке",
  what_we_offer_free:
    "То, что мы предлагаем бесплатно, вы, конечно, можете сделать вручную. <strong>Просмотрите объявления в Интернете</strong> или посетите несколько автосалонов, чтобы узнать текущие цены на предлагаемые автомобили.",
  if_you_are_not_sure:
    "Если вы не уверены, какие данные необходимы для оценки автомобиля, вы можете обратиться к <strong>оценщику автомобилей</strong> или обратиться к <strong>дилеру</strong> или в салон по продаже подержанных автомобилей. Они также помогут вам оценить стоимость вашего автомобиля. Однако примите во внимание, что цена, предлагаемая поставщиком или продавцом, скорее всего, будет намного ниже. Им также нужно зарабатывать деньги, чтобы купить у вас машину.",
  regardless_of_which_method:
    "Независимо от того, какой метод вы выберете, помните, что оценка автомобиля является приблизительной ценой. Окончательная стоимость автомобиля зависит от многих факторов, таких как техническое состояние, комплектация, история обслуживания и общее впечатление, которое автомобиль производит на потенциального покупателя. Перед продажей или покупкой автомобиля важно тщательно проверить его состояние.",
  main_tip:
    "Воспользуйтесь нашим веб-сайтом для онлайн-оценки автомобилей с использованием VIN-номера. Благодаря этому вы быстро и точно узнаете, сколько стоит ваш автомобиль. Все, что вам нужно сделать, это ввести VIN-номер вашего автомобиля, и мы поможем вам оценить его стоимость. Не ждите, проверьте сейчас!",
  h1_title: "Бесплатная оценка автомобиля",
  meta_title:
    "Онлайн калькулятор оценки автомобиля - быстро и точно оцените свой автомобиль.",
  meta_desc:
    "Оценка автомобиля онлайн, быстро и точно оцените стоимость вашего автомобиля. Наш калькулятор выдаст цену проверенного автомобиля, а также немного старше и моложе для сравнения.",
};
