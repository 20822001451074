<template>
  <div class="row my-5">
    <div v-if="!isLoadingNearYearChart" class="col-lg-6">
      <canvas id="nearOdometerChart"></canvas>
    </div>

    <div
      :class="{ 'dark': theme == 'dark', 'light': theme == 'light' }"
      v-else-if="isLoadingNearYearChart"
      class="col-lg-6"
    >
      -
    </div>

    <div v-if="!isLoadingNearYearChart" class="col-lg-6">
      <canvas id="nearYearChart"></canvas>
    </div>

    <div
      :class="{ 'dark': theme == 'dark', 'light': theme == 'light' }"
      v-else-if="isLoadingNearYearChart"
      class="col-lg-6"
    >
      -
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { computed, onMounted, ref, toRef, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "Chart",
  props: {
    currency: String,
  },
  setup(props) {
    const chartCurrency = toRef(props, 'currency');
    const store = useStore();
    const labelsChartOdometer = ref([]);
    const labelsChartPrices = ref([]);
    const labelsChartColors = ref([]);

    const labelsNearYearChartYears = ref([]);
    const labelsNearYearChartPrices = ref([]);
    const labelsNearYearChartColors = ref([]);
    const { t } = useI18n();

    console.log(chartCurrency.value);

    const renderChart = (ctx) => {
      if (ctx) {
        let chartStatus = Chart.getChart(ctx.id ?? null); // <canvas> id
        if (chartStatus != undefined) {
          chartStatus.destroy(); // usuwanie wykresu gdy za drugim razem chcemy wyrenderować ten sam typ
        }
        if (ctx.id == "nearOdometerChart") {
          new Chart(ctx, {
            type: "bar",
            data: {
              labels: labelsChartOdometer.value,
              datasets: [
                {
                  label: t("price_odometer"),
                  data: labelsChartPrices.value,
                  backgroundColor: labelsChartColors.value,
                  borderColor: ["#4aa2ef"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  title: {
                    display: true,
                    text: t("mileage"),
                  },
                },
                y: {
                  min:
                    Math.floor(
                      (labelsChartPrices.value[
                        labelsChartPrices.value.length - 1
                      ] *
                        0.9) /
                        500
                    ) * 500,
                  max:
                    Math.ceil((labelsChartPrices.value[0] * 1.03) / 500) * 500,
                  ticks: {
                    callback: (label) => `${label} ${chartCurrency.value}`,
                  },
                },
              },
            },
          });
        } else if (ctx.id == "nearYearChart") {
          new Chart(ctx, {
            type: "bar",
            data: {
              labels: labelsNearYearChartYears.value,
              datasets: [
                {
                  label: t("price_year"),
                  data: labelsNearYearChartPrices.value,
                  backgroundColor: labelsNearYearChartColors.value,
                  borderColor: ["#4aa2ef"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  title: {
                    display: true,
                    text: t("year"),
                  },
                },
                y: {
                  min:
                    Math.ceil(
                      (labelsNearYearChartPrices.value[
                        labelsNearYearChartPrices.value.length - 1
                      ] *
                        1.1) /
                        500
                    ) * 500,
                  max:
                    Math.floor(
                      (labelsNearYearChartPrices.value[0] * 0.7) / 500
                    ) * 500,
                  ticks: {
                    callback: (label) => `${label} ${chartCurrency.value}`,
                  },
                },
              },
            },
          });
        }
      }
    };

    const odometerData = computed(() => {
      return store.state.nearOdometerData;
    });
    const yearData = computed(() => {
      return store.state.nearYearData;
    });
    const currentOdometer = computed(() => {
      return store.state.carData.currentOdometer;
    });
    const prodYear = computed(() => {
      return store.state.carData.prodYear;
    });

    const isLoadingNearYearChart = computed(() => {
      return store.state.nearYearChart;
    });

    const isLoadingNearOdometerChart = computed(() => {
      return store.state.nearOdometerChart;
    });

    const prepareOdometerData = (data) => {
      const arr = [];
      const odometers = [];
      const prices = [];
      const colors = [];
      let added = false;
      for (const values of data) {
        for (const [type, value] of Object.entries(values)) {
          if (type == "odometer_avr") {
            if (
              !added &&
              currentOdometer.value > 0 &&
              parseInt(value) / currentOdometer.value > 0.98 &&
              parseInt(value) / currentOdometer.value < 1.02
            ) {
              added = true;
              odometers.push(`${currentOdometer.value} km`);
              colors.push("#26be00");
            } else {
              odometers.push(`${value} km`);
              colors.push("#3763f4");
            }
          } else if (type == "price_avr") {
            prices.push(value);
          }
        }
      }

      arr["odometers"] = odometers;
      arr["prices"] = prices;
      arr["colors"] = colors;

      return arr;
    };
    const prepareYearData = (data) => {
      const arr = [];
      const prices = [];
      const years = [];
      const colors = [];
      for (const values of data) {
        for (const [type, value] of Object.entries(values)) {
          if (type == "year") {
            if (parseInt(value) == parseInt(prodYear.value)) {
              colors.push("#26be00");
            } else {
              colors.push("#3763f4");
            }
            years.push(value);
          } else if (type == "price") {
            prices.push(value);
          }
        }
      }

      arr["years"] = years;
      arr["prices"] = prices;
      arr["colors"] = colors;

      return arr;
    };

    watchEffect(() => {
      if (odometerData.value && Object.keys(odometerData.value).length != 0) {
        const prepareOdometerDataResult = prepareOdometerData(
          odometerData.value
        );
        labelsChartOdometer.value = prepareOdometerDataResult.odometers;
        labelsChartPrices.value = prepareOdometerDataResult.prices;
        labelsChartColors.value = prepareOdometerDataResult.colors;
        const nearOdometerChart = document.getElementById("nearOdometerChart");
        renderChart(nearOdometerChart);
      } else {
        let chartStatus = Chart.getChart("nearOdometerChart");
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
      }
      if (yearData.value && Object.keys(yearData.value).length != 0) {
        const prepareYearDataResult = prepareYearData(yearData.value);
        labelsNearYearChartYears.value = prepareYearDataResult.years;
        labelsNearYearChartPrices.value = prepareYearDataResult.prices;
        labelsNearYearChartColors.value = prepareYearDataResult.colors;
        const nearYearChart = document.getElementById("nearYearChart");
        renderChart(nearYearChart);
      } else {
        let chartStatus = Chart.getChart("nearYearChart");
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
      }
    });

    onMounted(() => {});

    return {
      isLoadingNearYearChart,
      isLoadingNearOdometerChart,
    };
  },
};
</script>

<style scoped>
.dark {
  background-color: #2d2c36;
  animation: pulse-bg-dark 1s infinite;
}

.light {
  background-color: #eee;
  animation: pulse-bg-light 1s infinite;
}

@keyframes pulse-bg-dark {
  0% {
    background-color: #2d2c36;
  }
  50% {
    background-color: #2a2a36;
  }
  100% {
    background-color: #2d2c36;
  }
}

@keyframes pulse-bg-light {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
</style>