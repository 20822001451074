export const pt = {
  car_valuation: "CLASSIFICAÇÃO DO VEÍCULO",
  toggle_navigation: "Alternar de navegação",
  vin: "VIN",
  plate: "tabela",
  mileage: "quilometragem",
  first_reg_date: "Data do primeiro registro",
  check_valuation: "avaliação",
  contact: "Contato",
  privacy_policy: "Política de Privacidade",
  regulations: "estatuto",
  valuation:
    "A avaliação do veículo foi elaborada com base em muitos anos de experiência de nossos especialistas no mercado automotivo. O procedimento de avaliação usa inteligência artificial (IA) para comparar as tendências de mudança nos preços dos veículos motorizados nos mercados europeu e americano. A avaliação, é claro, leva em consideração parâmetros como a idade do veículo e sua quilometragem. Nossa avaliação também leva em consideração os problemas automotivos e sociais mais recentes, como a falta de subconjuntos ou componentes e a inflação, que afetam o preço dos veículos usados.",
  min_price: "Preço minimo",
  med_price: "Preço médio",
  max_price: "preço máximo",
  we_have_report_for_you: "Temos um relatório para este veículo!",
  incident_history: "história do incidente",
  odometer_reading: "ler quilometragem",
  vehicles_inspection: "inspeções de veículos",
  archive_photos: "arquivar fotos",
  check_now: "VER RELATÓRIO",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Avalie outro veículo",
  type_plate: "Digite o número de registro do veículo.",
  type_first_reg_date: "",
  price_odometer: "preço",
  price_year: "preço",
  year: "Ano",
  meta_title: "Calculadora de valor de carro online - avalie rapidamente e precisamente o valor do seu carro",
  meta_desc: "Calculadora de valor de carro online com VIN - avalie rapidamente e precisamente o valor do seu carro",
  h1_title: "Avalie seu carro online gratuitamente",
  main_tip:
    "Se você quiser saber quanto vale o seu carro, use nosso site de avaliação de carros online. Tudo o que você precisa fazer é inserir o número VIN do seu carro e nós o ajudaremos a avaliar seu valor com rapidez e precisão. Você não precisa mais perder tempo procurando preços de carros semelhantes no mercado ou entrando em contato com avaliadores ou revendedores. Nossa avaliação é gratuita e totalmente anônima. Não espere, confira agora quanto vale o seu carro!.",
  enter_vin_number: "Insira o número do seu chassi",
  next: "Próximo",
  help: "Ajuda",
  check_if_stolen: "Verifique se roubado",
  decode_vin_number: "Decodificar número VIN",
  additional_links: "Links adicionais",
  no_data_valuation: "Sem dados para avaliação",
  check_vin_correct: "Verifique a exatidão do número VIN",
  how_to_vin_valuation: "Como avaliar um veículo antes de comprar/vender?",
  calculator_valuation: "Calculadora de avaliação de carros online",
  calculator_valuation_desc:
    "Existem muitas maneiras de estimar o valor de um carro, o mais rápido e relativamente preciso será verificá-lo em um portal de avaliação de vin. Nosso algoritmo proprietário apoiado por inteligência artificial (IA) agrega dados de milhares de anúncios online, analisando com precisão a idade, marca, equipamento e condição dos veículos em exibição. Com base nisso, estimamos quanto deve valer o carro que você está verificando.",
  you_get_valuation_for_free:
    "Você <strong>receberá uma cotação</strong> em poucos instantes sem sair de casa, <strong>totalmente grátis</strong>. Tudo o que precisamos são alguns parâmetros, como o número VIN ou a data de registro do veículo.",
  in_response_you_receive_not_only_valuation:
    "Em resposta, você receberá não apenas uma avaliação da safra que está verificando, mas também algumas das mais próximas, mais jovens e mais velhas. Graças a isso, você tem uma visão completa se, por exemplo, ainda vale a pena possuí-lo ou se não perderá muito com ele e seria bom vendê-lo imediatamente.",
  compare_market_price: "Compare preços no mercado",
  what_we_offer_free:
    "O que oferecemos gratuitamente, é claro que você pode fazer manualmente. <strong>Navegue pelos classificados na Internet</strong> ou visite várias <strong>concessionárias</strong> de carros para saber os preços atuais dos veículos oferecidos.",
  if_you_are_not_sure:
    "Se você não tiver certeza de quais dados são necessários para avaliar um carro, entre em contato com um avaliador de carros ou dirija-se a uma concessionária ou revendedora de carros usados. Eles também irão ajudá-lo a estimar o valor do seu carro. No entanto, leve em consideração que o preço oferecido pelo fornecedor ou varejista provavelmente será muito menor. Eles também precisam ganhar dinheiro para comprar um carro de você.",
  regardless_of_which_method:
    "Independentemente do método escolhido, lembre-se de que a avaliação do carro é um preço aproximado. O valor final do veículo depende de muitos fatores, como condição técnica, equipamentos, histórico de manutenção e a impressão geral que o carro causa no potencial comprador. É importante verificar cuidadosamente as condições do seu carro antes de vendê-lo ou comprá-lo",
};
