<template>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <span
      class="step"
      :class="{
        'bg-primary': countStep == 1 && theme == 'dark',
        'bg-danger': countStep == 1 && theme == 'light',
        dark: theme == 'dark',
        light: theme == 'light',
      }"
      >1</span
    >
    <hr :class="{ 'hr-dark': theme == 'dark',
        'hr-light': theme == 'light', }" />
    <span
      class="step"
      :class="{
        'bg-primary': countStep == 2 && theme == 'dark',
        'bg-danger': countStep == 2 && theme == 'light',
        dark: theme == 'dark',
        light: theme == 'light',
      }"
      >2</span
    >
    <hr :class="{ 'hr-dark': theme == 'dark',
        'hr-light': theme == 'light', }"/>
    <span
      class="step"
      :class="{
        'bg-primary': countStep == 3 && theme == 'dark',
        'bg-danger': countStep == 3 && theme == 'light',
        dark: theme == 'dark',
        light: theme == 'light',
      }"
      >3</span
    >
    <hr :class="{ 'hr-dark': theme == 'dark',
        'hr-light': theme == 'light', }" v-if="country == 'PL' || country == 'pl-PL'" />
    <span
      v-if="country == 'PL' || country == 'pl-PL'"
      class="step"
      :class="{
        'bg-primary': countStep == 4 && theme == 'dark',
        'bg-danger': countStep == 4 && theme == 'light',
        dark: theme == 'dark',
        light: theme == 'light',
      }"
      >4</span
    >
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "Steps",
  setup() {
    const store = useStore();

    const countStep = computed(() => {
      return store.state.formStep;
    });

    const country = computed(() => {
      return store.state.regCountry;
    });

    const theme = computed(() => {
      return store.state.theme;
    });

    return {
      countStep,
      country,
      theme,
    };
  },
};
</script>

<style scoped>
.step {
  background: transparent;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 5px;
  transition: 0.5s;
}

.dark {
  color: white;
  border: 1px solid white;
}

.light {
  color: dark;
  border: 1px solid black;
}

.hr-light {
  color: black;
}

.hr-dark {
  color: white;
}

hr {
  height: 2px;
  width: 2%;
}
</style>