<template>
  <div>
    <h1 class="text-white">Kontakt</h1>
    <div class="container">
      <span class="mt-5"><router-link to="/">{{ t("back") }}</router-link></span>
      <div class="row w-100">
        <div class="col-12 mt-5 mb-5">
          <img
            src="../../public/assets/img/mail_black.png"
            class="img-fluid"
            alt="email"
            width="470"
            height="40"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Contact",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: underline;
}
</style>