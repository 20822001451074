export const cs = {
  car_valuation: "KLASIFIKACE VOZIDLA",
  toggle_navigation: "přepnout navigaci",
  vin: "VIN",
  plate: "stůl",
  mileage: "najetých kilometrů",
  first_reg_date: "Datum první registrace",
  check_valuation: "ocenění",
  contact: "Kontakt",
  privacy_policy: "Zásady ochrany osobních údajů",
  regulations: "statut",
  valuation:
    "Ocenění vozidla bylo zpracováno na základě dlouholetých zkušeností našich odborníků na automobilovém trhu. Oceňovací procedura využívá umělou inteligenci (AI) k porovnání měnících se trendů cen motorových vozidel na evropském a americkém trhu. Ocenění samozřejmě zohledňuje takové parametry, jako je stáří vozidla a jeho ujeté kilometry. Naše ocenění také zohledňuje nejnovější automobilové a společenské problémy, jako je nedostatek podsestav či komponentů a inflace, které ovlivňují cenu ojetých vozidel.",
  min_price: "Minimální cena",
  med_price: "Průměrná cena",
  max_price: "maximální cena",
  we_have_report_for_you: "Máme zprávu pro toto vozidlo!",
  incident_history: "historie incidentů",
  odometer_reading: "číst ujeté kilometry",
  vehicles_inspection: "prohlídky vozidel",
  archive_photos: "archivní fotografie",
  check_now: "VIZ ZPRÁVA",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Ohodnoťte jiné vozidlo",
  type_plate: "Zadejte registrační značku vozidla.",
  type_first_reg_date: "",
  price_odometer: "cena",
  price_year: "cena",
  year: "Rok",
  meta_title: "Online kalkulačka výpočtu ceny auta - rychle a přesně oceněte své auto",
  meta_desc: "Online kalkulačka výpočtu ceny auta podle VIN - rychle a přesně zjistěte, kolik je vaše auto v hodnotě",
  h1_title: "Ocenění vozidla online vše, co potřebujete, je číslo vin",
  main_tip:
    "Pokud chcete vědět, jakou hodnotu má vaše auto, využijte naši online stránku oceňování aut. Stačí zadat VIN číslo vašeho vozu a my vám pomůžeme rychle a přesně posoudit jeho hodnotu. Už nemusíte ztrácet čas hledáním podobných cen aut na trhu nebo kontaktováním odhadců či prodejců. Naše ocenění je bezplatné a zcela anonymní. Nečekejte a zjistěte, kolik stojí vaše auto!",
  enter_vin_number: "Zadejte číslo podvozku",
  next: "další",
  help: "Pomoc",
  no_data_valuation: "Žádná data pro ocenění",
  check_if_stolen: "Zkontrolujte, zda není odcizen",
  decode_vin_number: "Dekódujte VIN číslo",
  additional_links: "Další odkazy",
  check_vin_correct: "Zkontrolujte správnost čísla VIN",
  how_to_vin_valuation: "Jak zhodnotit vozidlo před koupí/prodejem?",
  calculator_valuation: "Online kalkulačka ocenění auta",
  calculator_valuation_desc:
    "Je mnoho způsobů, jak hodnotu auta odhadnout, nejrychlejší a relativně přesné bude ověřit si ji na portálu pro oceňování vin. Náš proprietární algoritmus podporovaný umělou inteligencí (AI) shromažďuje data z tisíců online reklam a přesně analyzuje stáří, značku, vybavení a stav vystavených vozidel. Na základě toho odhadneme, jakou hodnotu by měl mít vůz, který kontrolujete.",
  you_get_valuation_for_free:
    "<strong>Cenovou nabídku</strong> obdržíte za několik okamžiků, aniž byste opustili svůj domov, <strong>zcela zdarma</strong>. Stačí nám pár parametrů, jako je VIN číslo nebo datum registrace vozidla.",
  in_response_you_receive_not_only_valuation:
    "Jako odpověď obdržíte nejen ocenění kontrolovaného ročníku, ale také pár vašich nejbližších, mladších i starších. Díky tomu máte plný přehled o tom, zda se jej například ještě vyplatí vlastnit, nebo na něm příliš neztratíte a bylo by dobré ho hned prodat.",
  compare_market_price: "Porovnejte ceny na trhu",
  what_we_offer_free:
    "To, co nabízíme zdarma, můžete samozřejmě udělat ručně. <strong>Prohlédněte si inzeráty</strong> na internetu nebo navštivte <strong>několik autobazarů</strong> a zjistěte aktuální ceny nabízených vozidel.",
  if_you_are_not_sure:
    "Pokud si nejste jisti, jaké údaje jsou k ocenění vozu potřeba, můžete se obrátit na <strong>odhadce vozů</strong> nebo se obrátit na <strong>prodejce či autobazar</strong>. Pomohou vám také odhadnout hodnotu vašeho vozu. Počítejte však s tím, že cena nabízená dodavatelem či prodejcem bude s největší pravděpodobností mnohem nižší. Potřebují také vydělat peníze, aby si od vás koupili auto.",
  regardless_of_which_method:
    "Bez ohledu na to, jakou metodu zvolíte, nezapomeňte, že ocenění vozu je přibližná cena. Konečná hodnota vozidla závisí na mnoha faktorech, jako je technický stav, výbava, servisní historie a celkový dojem, kterým vůz na potenciálního kupce působí. Před prodejem nebo koupí vozu je důležité důkladně zkontrolovat stav",
};
