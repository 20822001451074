import { createStore } from "vuex";
import { defaultState } from "../utils/config";
import reqPrecheck from "../composables/reqPrecheck";
import reqCarValuation from "../composables/reqCarValuation";
import { useI18n } from "vue-i18n";

export default createStore({
  state() {
    return defaultState();
  },
  getters: {},
  mutations: {
    updateToken(state, payload) {
      state.token = payload;
    },
    updateCarData(state, payload) {
      state.carData = payload;
    },
    updateNearYearData(state, payload) {
      state.nearYearData = payload;
    },
    updateNearOdometerData(state, payload) {
      state.nearOdometerData = payload;
    },
    updateValuationData(state, payload) {
      state.valuation = payload;
    },
    updatePrecheckData(state, payload) {
      state.precheck = payload;
    },
    updateLang(state, payload) {
      state.lang = payload;
    },
    updateTheme(state, payload) {
      state.theme = payload;
    },
    updateResultCurrency(state, payload) {
      state.resultCurrency = payload;
    },
    updateLoadingSectionsVehicleInfo(state, payload) {
      state.loadingSections.vehicleInfo = payload;
    },
    updateLoadingSectionsPriceStolen(state, payload) {
      state.loadingSections.priceSection = payload;
    },
    updateLoadingSectionsNearYearChart(state, payload) {
      state.loadingSections.nearYearChart = payload;
    },
    updateLoadingSectionsNearOdometerChart(state, payload) {
      state.loadingSections.nearOdometerChart = payload;
    },
    updateLoadingSectionsPrecheckDataSection(state, payload) {
      state.loadingSections.precheckDataSection = payload;
    },
    updateCountryCode(state, payload) {
      state.regCountry = payload;
    },
    updateFormStep(state, payload) {
      state.formStep = payload;
    },
    resetState(state) {
      for (const singleData in state) {
        if (singleData == "lang" || singleData == "regCountry" || singleData == "token") {
          continue;
        }
        state[singleData] = defaultState()[singleData];
      }
    },
  },
  actions: {
    resetData({ commit }) {
      commit("resetState");
    },
    setCarData({ commit }, payload) {
      commit("updateCarData", payload);
    },
    setNearYearData({ commit }, payload) {
      commit("updateNearYearData", payload);
    },
    setNearOdometerData({ commit }, payload) {
      commit("updateNearOdometerData", payload);
    },
    setValuationData({ commit }, payload) {
      commit("updateValuationData", payload);
    },
    setPrecheckData({ commit }, payload) {
      commit("updatePrecheckData", payload);
    },
    SET_LANG(context) {
      const { locale } = useI18n();
      const index = locale.value.indexOf("-");
      let result = null;

      if (index < 0) {
        result = locale.value;
      } else {
        result = locale.value.slice(0, index);
      }
      context.commit("updateLang", result);
    },
    async getPrecheckApi({ commit, state }, payload) {
      const { sendReqPrecheck, responsePrecheck } = reqPrecheck();
      await sendReqPrecheck(payload.vin, state.token);
      commit("updateLoadingSectionsPrecheckDataSection", false);
      if (responsePrecheck.value?.data?.message == "captcha_failed") {
        console.log('captcha failed');
        return;
      }
      commit("updatePrecheckData", responsePrecheck.value.data.result);
    },
    async getValuationApi({ commit, state }, payload) {
      const { sendReqValuation, responseValuation } = reqCarValuation();
      await sendReqValuation({ ...payload, token: state.token });

      if (responseValuation) {
        commit("updateLoadingSectionsVehicleInfo", false);
        commit("updateLoadingSectionsPriceStolen", false);
        commit("updateLoadingSectionsNearYearChart", false);
        commit("updateLoadingSectionsNearOdometerChart", false);
        if (responseValuation.value?.data?.message == "captcha_failed") {
          console.log('captcha failed');
          return;
        }
        const nearYear =
          responseValuation.value?.data?.result?.calcNearYear || false;

        const nearOdometer =
          responseValuation.value?.data?.result?.calcNearOdometer || false;

        const valuationData =
          responseValuation.value?.data?.result?.calcValuation || false;

        const resultCurrency =
          responseValuation.value?.data?.result?.currency || false;

        if (valuationData) {
          commit("updateValuationData", valuationData);
        } else {
          commit("updateLoadingSectionsVehicleInfo", false);
          commit("updateValuationData", false);
        }

        if (nearYear) {
          commit("updateNearYearData", nearYear.values);
        } else {
          commit("updateNearYearData", false);
        }

        if (nearOdometer) {
          commit("updateNearOdometerData", nearOdometer.values);
        } else {
          commit("updateNearOdometerData", false);
        }

        if (resultCurrency) {
          commit("updateResultCurrency", resultCurrency);
        }
      }
    },
  },
});
