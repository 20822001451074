export const partnerLink = (lang) => {
  const partnersId = partnerIds();
  const link = `https://www.automoli.com/${partnersId[lang].domain}/page/order-reports/partnerid:${partnersId[lang].id}`;
  return link;
};

export const getCurrency = (lang) => {
  const langs = {
    bg: "BGN",
    ar: "EUR",
    cs: "CZK",
    da: "EUR",
    de: "EUR",
    el: "EUR",
    en: "GBP",
    es: "EUR",
    fr: "EUR",
    hu: "HUF",
    it: "EUR",
    lt: "EUR",
    nl: "EUR",
    no: "EUR",
    pl: "PLN",
    pt: "EUR",
    ro: "RON",
    ru: "EUR",
    sk: "EUR",
    sr: "EUR",
    sv: "EUR",
    tr: "EUR",
    uk: "EUR",
  };
  return langs[lang] ?? "EUR";
}

export const getCountryCode = (lang) => {
  const langs = {
    bg: "BG",
    ar: "AR",
    cs: "CS",
    da: "DA",
    de: "DE",
    el: "EL",
    en: "GB",
    es: "ES",
    fr: "FR",
    hu: "HU",
    it: "IT",
    lt: "LT",
    nl: "NL",
    no: "NO",
    pl: "PL",
    pt: "PT",
    ro: "RO",
    ru: "RU",
    sk: "SK",
    sr: "SR",
    sv: "SV",
    tr: "TR",
    uk: "UK",
  };
  return langs[lang] ?? lang;
}

const partnerIds = () => {
  return {
    bg: {
      id: "80000489",
      domain: "bg",
    },
    ar: {
      id: "80000489",
      domain: "en",
    },
    cs: {
      id: "80000489",
      domain: "cs",
    },
    da: {
      id: "80000489",
      domain: "da",
    },
    de: {
      id: "80000489",
      domain: "de",
    },
    el: {
      id: "80000489",
      domain: "en",
    },
    en: {
      id: "80000489",
      domain: "en",
    },
    es: {
      id: "80000489",
      domain: "es",
    },
    fr: {
      id: "80000489",
      domain: "fr",
    },
    hu: {
      id: "80000489",
      domain: "hu",
    },
    it: {
      id: "80000489",
      domain: "it",
    },
    lt: {
      id: "80000489",
      domain: "lt",
    },
    nl: {
      id: "80000489",
      domain: "nl",
    },
    no: {
      id: "80000489",
      domain: "no",
    },
    pl: {
      id: "80000489",
      domain: "pl",
    },
    pt: {
      id: "80000489",
      domain: "pt",
    },
    ro: {
      id: "80000489",
      domain: "ro",
    },
    ru: {
      id: "80000489",
      domain: "ru",
    },
    sk: {
      id: "80000489",
      domain: "sk",
    },
    sr: {
      id: "80000489",
      domain: "sr",
    },
    sv: {
      id: "80000489",
      domain: "sv",
    },
    tr: {
      id: "80000489",
      domain: "en",
    },
    uk: {
      id: "80000489",
      domain: "uk",
    },
  };
};

export const defaultState = () => {
  return {
    token: null,
    carData: {
      vin: "",
      make: "",
      model: "",
      prodYear: "",
      currentOdometer: 0,
    },
    nearYearData: {},
    nearOdometerData: {},
    valuation: {},
    precheck: {
      odometers: 0,
      damages: 0,
      events: 0,
      photos: 0,
    },
    lang: navigator.language,
    theme: 'dark',
    resultCurrency: "",
    loadingSections: {
      vehicleInfo: null,
      priceSection: null,
      nearYearChart: null,
      nearOdometerChart: null,
      precheckDataSection: null,
    },
    regCountry: getCountryCode(navigator.language),
    showInput: 'vin',
    formStep: 1,
  };
};
