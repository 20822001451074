export const lt = {
  car_valuation: "TRANSPORTO PRIEMONIŲ KLASIFIKACIJA",
  toggle_navigation: "perjungti navigaciją",
  vin: "VIN",
  plate: "stalo",
  mileage: "rida",
  first_reg_date: "Pirmosios registracijos data",
  check_valuation: "vertinimas",
  contact: "kontaktas",
  privacy_policy: "Privatumo politika",
  regulations: "statutas",
  valuation:
    "Transporto priemonės vertinimas parengtas remiantis ilgamete mūsų ekspertų automobilių rinkos patirtimi. Vertinimo procedūroje naudojamas dirbtinis intelektas (AI), siekiant palyginti kintančias variklinių transporto priemonių kainų tendencijas Europos ir Amerikos rinkose. Vertinant, žinoma, atsižvelgiama į tokius parametrus kaip transporto priemonės amžius ir rida. Vertinant taip pat atsižvelgiama į naujausias automobilių ir socialines problemas, tokias kaip mazgų ar komponentų trūkumas ir infliacija, kurios turi įtakos naudotų transporto priemonių kainai.",
  min_price: "Minimali kaina",
  med_price: "Vidutinė kaina",
  max_price: "maksimali kaina",
  we_have_report_for_you: "Turime ataskaitą apie šią transporto priemonę!",
  incident_history: "incidento istorija",
  odometer_reading: "skaityti rida",
  vehicles_inspection: "transporto priemonių apžiūros",
  archive_photos: "archyvo nuotraukos",
  check_now: "ŽR. ATASKAITĄ",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Įvertinkite kitą transporto priemonę",
  type_plate: "Įveskite transporto priemonės registracijos numerį.",
  type_first_reg_date: "",
  price_odometer: "kaina",
  price_year: "kaina",
  year: "Metai",
  next: "Kitas",
  help: "Pagalba",
  no_data_valuation: "Duomenų vertinimui nėra",
  check_if_stolen: "Patikrinkite, ar nepavogta",
  decode_vin_number: "Iššifruokite VIN numerį",
  additional_links: "Papildomos nuorodos",
  check_vin_correct: "Patikrinkite VIN numerio teisingumą",
  how_to_vin_valuation:
    "Kaip įvertinti transporto priemonę prieš perkant/parduodant?",
  calculator_valuation: "Internetinė automobilių vertinimo skaičiuoklė",
  calculator_valuation_desc:
    "Yra daug būdų, kaip įvertinti automobilio vertę, greičiausia ir santykinai tiksliausia bus patikrinti vin vertinimo portale. Mūsų patentuotas dirbtinio intelekto (AI) palaikomas algoritmas apibendrina duomenis iš tūkstančių internetinių skelbimų, tiksliai išanalizuodamas demonstruojamų transporto priemonių amžių, markę, įrangą ir būklę. Tuo remdamiesi įvertiname, kiek turėtų būti vertas jūsų tikrinamas automobilis.",
  you_get_valuation_for_free:
    "<strong>Citatą</strong> gausite per kelias akimirkas neišeidami iš namų, <strong>visiškai nemokamai</strong> . Mums tereikia kelių parametrų, tokių kaip VIN numeris arba transporto priemonės registracijos data.",
  in_response_you_receive_not_only_valuation:
    "Atsakydami gausite ne tik tikrinamo derliaus įvertinimą, bet ir keletą artimiausių, jaunesnių ir vyresnių. Dėl to jūs puikiai suprantate, ar, pavyzdžiui, vis dar verta jį turėti, ar per daug neprarasite ir būtų gerai jį iš karto parduoti.",
  compare_market_price: "Palyginkite kainas rinkoje",
  what_we_offer_free:
    "Tai, ką siūlome nemokamai, žinoma, galite padaryti rankiniu būdu. <strong>Naršykite skelbimus internete</strong> arba apsilankykite keliose automobilių prekybos vietose ir sužinokite aktualias siūlomų transporto priemonių kainas.",
  if_you_are_not_sure:
    "Jei nesate tikri, kokių duomenų reikia norint įvertinti automobilį, galite kreiptis į <strong>automobilių vertintoją</strong> arba kreiptis į <strong>pardavėją</strong> ar naudotų automobilių pardavėją. Jie taip pat padės įvertinti jūsų automobilio vertę. Tačiau atsižvelkite į tai, kad tiekėjo ar pardavėjo pasiūlyta kaina greičiausiai bus daug mažesnė. Jie taip pat turi užsidirbti pinigų, kad galėtų iš jūsų nusipirkti automobilį.",
  regardless_of_which_method:
    "Nepriklausomai nuo to, kokį būdą pasirinksite, atminkite, kad automobilio vertinimas yra apytikslė kaina. Galutinė transporto priemonės vertė priklauso nuo daugelio veiksnių, tokių kaip techninė būklė, komplektacija, serviso istorija ir bendras įspūdis, kurį automobilis sukuria potencialiam pirkėjui. Prieš parduodant ar perkant automobilį svarbu kruopščiai patikrinti jo būklę.",
  main_tip:
    "Naudokite mūsų svetainę, kad nustatytumėte automobilių kainas internetu naudodami VIN numerį. Dėl to greitai ir kruopščiai išsiaiškinsite, kiek vertas jūsų automobilis. Tereikia įvesti savo automobilio VIN numerį ir mes padėsime įvertinti jo vertę. Nelaukite, patikrinkite dabar!",
  h1_title: "Nemokamas automobilio vertinimas",
  meta_title:
    "Internetinė automobilio vertinimo skaičiuoklė – greitai ir kruopščiai įvertinkite savo automobilį.",
  meta_desc:
    "Automobilio vertinimas internetu, greitai ir tiksliai įvertinkite savo automobilio vertę. Mūsų skaičiuoklė palyginimui pateiks patikrintos ir šiek tiek senesnės bei jaunesnės transporto priemonės kainą.",
};
