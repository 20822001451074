export const no = {
  car_valuation: "KJØRETØYKLASSIFISERING",
  toggle_navigation: "veksle mellom navigering",
  vin: "VIN",
  plate: "bord",
  mileage: "kjørelengde",
  first_reg_date: "Dato for første registrering",
  check_valuation: "verdivurdering",
  contact: "Ta kontakt med",
  privacy_policy: "Personvernerklæring",
  regulations: "vedtekter",
  valuation:
    "Verdivurderingen av kjøretøyet er utarbeidet på grunnlag av mange års erfaring fra våre eksperter i bilmarkedet. Verdsettelsesprosedyren bruker kunstig intelligens (AI) for å sammenligne de endrede trendene i prisene på motorkjøretøyer på det europeiske og amerikanske markedet. Verdivurderingen tar selvfølgelig hensyn til parametere som kjøretøyets alder og kjørelengde. Vår verdivurdering tar også hensyn til de siste bil- og sosiale problemene, som mangel på underenheter eller komponenter og inflasjon, som påvirker prisen på brukte kjøretøy.",
  min_price: "Minimumspris",
  med_price: "Gjennomsnittspris",
  max_price: "maksimal pris",
  we_have_report_for_you: "Vi har en rapport for dette kjøretøyet!",
  incident_history: "hendelseshistorie",
  odometer_reading: "lese kilometerstand",
  vehicles_inspection: "kjøretøykontroll",
  archive_photos: "arkivbilder",
  check_now: "SE RAPPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Vurder et annet kjøretøy",
  type_plate: "Skriv inn kjøretøyets registreringsnummer.",
  type_first_reg_date: "",
  price_odometer: "pris",
  price_year: "pris",
  year: "År",
  enter_vin_number: "Skriv inn chassisnummeret ditt",
  next: "Neste",
  help: "Hjelp",
  no_data_valuation: "Ingen data for verdivurdering",
  check_if_stolen: "Sjekk om det er stjålet",
  decode_vin_number: "Dekode VIN-nummer",
  additional_links: "Ytterligere lenker",
  check_vin_correct: "Kontroller riktigheten av VIN-nummeret",
  how_to_vin_valuation: "Hvordan vurdere et kjøretøy før du kjøper/selger?",
  calculator_valuation: "Online kalkulator for bilvurdering",
  calculator_valuation_desc:
    "Det er mange måter å anslå verdien av en bil på, den raskeste og relativt nøyaktige vil være å sjekke den på en vin-vurderingsportal. Vår proprietære algoritme støttet av kunstig intelligens (AI) samler data fra tusenvis av nettannonser, og analyserer nøyaktig alder, merke, utstyr og tilstand til kjøretøyene som vises. På dette grunnlaget anslår vi hvor mye bilen du sjekker skal være verdt.",
  you_get_valuation_for_free:
    "Du vil motta et <strong>tilbud</strong> noen få øyeblikk uten å forlate hjemmet ditt, <strong>helt gratis</strong> . Alt vi trenger er noen få parametere, for eksempel VIN-nummeret eller kjøretøyets registreringsdato.",
  in_response_you_receive_not_only_valuation:
    "Som svar vil du motta ikke bare en verdivurdering av årgangen du sjekker, men også noen av dine nærmeste, yngre og eldre. Takket være dette har du full innsikt i om det for eksempel fortsatt er verdt å eie det, eller om du ikke vil tape for mye på det og det vil være greit å selge det med en gang.",
  compare_market_price: "Sammenlign priser på markedet",
  what_we_offer_free:
    "Det vi tilbyr gratis, kan du selvsagt gjøre manuelt. <strong>Bla gjennom rubrikkannonsene på Internett</strong> eller besøk flere bilforhandlere for å finne ut gjeldende priser på kjøretøyene som tilbys.",
  if_you_are_not_sure:
    "Hvis du ikke er sikker på hvilke data som trengs for å verdsette en bil, kan du kontakte en <strong>biltakst</strong> eller henvende deg til en <strong>forhandler</strong> eller bruktbilforhandler. De vil også hjelpe deg å anslå verdien av bilen din. Vær imidlertid oppmerksom på at prisen som tilbys av leverandøren eller forhandleren mest sannsynlig vil være mye lavere. De må også tjene penger for å kjøpe en bil av deg.",
  regardless_of_which_method:
    "Uansett hvilken metode du velger, husk at bilvurderingen er en omtrentlig pris. Den endelige verdien på kjøretøyet avhenger av mange faktorer, som teknisk tilstand, utstyr, servicehistorikk og helhetsinntrykket bilen gjør på den potensielle kjøperen. Det er viktig å sjekke tilstanden til bilen din grundig før du selger eller kjøper den.",
  main_tip:
    "Bruk nettsiden vår til å prise nettbiler ved å bruke VIN-nummeret. Takket være dette vil du raskt og nøye finne ut hvor mye bilen din er verdt. Alt du trenger å gjøre er å skrive inn VIN-nummeret til bilen din, så hjelper vi deg med å vurdere verdien. Ikke vent, sjekk nå!",
  h1_title: "Gratis verdivurdering av kjøretøy",
  meta_title:
    "Online bilvurderingskalkulator - raskt og nøye ranger bilen din.",
  meta_desc:
    "Verdivurdering av bilen på nett, raskt og nøyaktig vurdere verdien av bilen din. Vår kalkulator vil gi prisen på kjøretøyet som er sjekket og litt eldre og yngre for sammenligning.",
};
