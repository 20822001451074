export const sk = {
  car_valuation: "KLASIFIKÁCIA VOZIDLA",
  toggle_navigation: "prepnúť navigáciu",
  vin: "VIN",
  plate: "tabuľky",
  mileage: "najazdených kilometrov",
  first_reg_date: "Dátum prvej registrácie",
  check_valuation: "oceňovanie",
  contact: "Kontakt",
  privacy_policy: "Zásady ochrany osobných údajov",
  regulations: "štatút",
  valuation:
    "Ocenenie vozidla bolo vypracované na základe dlhoročných skúseností našich odborníkov na automobilovom trhu. Postup oceňovania využíva umelú inteligenciu (AI) na porovnanie meniacich sa trendov cien motorových vozidiel na európskom a americkom trhu. Ocenenie samozrejme zohľadňuje také parametre ako vek vozidla a jeho najazdené kilometre. Naše ocenenie zohľadňuje aj najnovšie automobilové a spoločenské problémy, ako je nedostatok podzostáv či komponentov a inflácia, ktoré ovplyvňujú cenu jazdených vozidiel.",
  min_price: "Minimálna cena",
  med_price: "Priemerná cena",
  max_price: "maximálna cena",
  we_have_report_for_you: "Máme prehľad o tomto vozidle!",
  incident_history: "históriu incidentov",
  odometer_reading: "čítať najazdené kilometre",
  vehicles_inspection: "prehliadky vozidiel",
  archive_photos: "archívne fotografie",
  check_now: "POZRITE SI SPRÁVU",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Ohodnoťte iné vozidlo",
  type_plate: "Zadajte evidenčné číslo vozidla.",
  type_first_reg_date: "",
  price_odometer: "cena",
  price_year: "cena",
  year: "rok",
  meta_title: "Online kalkulačka hodnoty auta - rýchlo a presne oceníte hodnotu svojho auta",
  meta_desc: "Online kalkulačka hodnoty auta podľa VIN - rýchlo a presne oceníte hodnotu svojho auta",
  h1_title: "Zdarma ohodnoťte svoje auto online",
  main_tip:
    "Ak chcete vedieť, akú hodnotu má vaše auto, použite našu online stránku ohodnocovania áut. Stačí zadať VIN číslo vášho auta a my vám pomôžeme rýchlo a presne posúdiť jeho hodnotu. Už nemusíte strácať čas hľadaním podobných cien áut na trhu alebo kontaktovaním odhadcov či predajcov. Naše ocenenie je bezplatné a úplne anonymné. Nečakajte a skontrolujte, koľko stojí vaše auto!",
  next: "Ďalšie",
  help: "Pomoc",
  check_if_stolen: "Skontrolujte, či vám nebolo ukradnuté",
  decode_vin_number: "Dekódujte VIN číslo",
  additional_links: "Ďalšie odkazy",
  no_data_valuation: "Žiadne údaje na ocenenie",
  check_vin_correct: "Skontrolujte správnosť čísla VIN",
  how_to_vin_valuation: "Ako ohodnotiť vozidlo pred kúpou/predajom?",
  calculator_valuation: "Online kalkulačka ocenenia auta",
  calculator_valuation_desc:
    "Existuje mnoho spôsobov, ako odhadnúť hodnotu auta, najrýchlejšie a relatívne presné bude overenie na portáli ohodnocovania vín. Náš vlastný algoritmus podporovaný umelou inteligenciou (AI) zhromažďuje údaje z tisícok online reklám a presne analyzuje vek, značku, vybavenie a stav vystavených vozidiel. Na základe toho odhadneme, akú hodnotu by malo mať kontrolované auto.",
  you_get_valuation_for_free:
    "<strong>Cenovú ponuku</strong> dostanete niekoľko minút bez toho, aby ste opustili svoj domov, <strong>úplne zadarmo</strong>. Stačí nám pár parametrov, ako je VIN číslo alebo dátum registrácie vozidla.",
  in_response_you_receive_not_only_valuation:
    "Ako odpoveď dostanete nielen ocenenie ročníka, ktorý kontrolujete, ale aj niekoľkých vašich najbližších, mladších a starších. Vďaka tomu máte plný prehľad o tom, či sa ho napríklad ešte oplatí vlastniť, alebo na ňom príliš nestratíte a bolo by dobré ho hneď predať.",
  compare_market_price: "Porovnajte ceny na trhu",
  what_we_offer_free:
    "To, čo ponúkame zadarmo, môžete samozrejme urobiť ručne. <strong>Prezrite si inzeráty na internete</strong> alebo navštívte viacero <strong>autobazárov</strong> a zistite aktuálne ceny ponúkaných vozidiel.",
  if_you_are_not_sure:
    "Ak si nie ste istí, aké údaje sú potrebné na ocenenie auta, môžete sa obrátiť na <strong>odhadcu áut</strong> alebo sa obrátiť na predajcu či <strong>autobazár</strong>. Pomôžu vám tiež odhadnúť hodnotu vášho auta. Počítajte však s tým, že cena, ktorú ponúkne dodávateľ alebo predajca, bude s najväčšou pravdepodobnosťou oveľa nižšia. Na kúpu auta od vás potrebujú aj zarobiť.",
  regardless_of_which_method:
    "Bez ohľadu na to, ktorý spôsob si vyberiete, nezabudnite, že ocenenie auta je približná cena. Konečná hodnota vozidla závisí od mnohých faktorov, akými sú technický stav, výbava, servisná história a celkový dojem, ktorým auto na potenciálneho kupca pôsobí. Pred predajom alebo kúpou auta je dôležité dôkladne skontrolovať jeho stav",
};
