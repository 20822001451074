<template>
  <nav
    class="navbar navbar-dark navbar-expand-md sticky-top navbar-shrink"
    id="mainNav"
    :class="{ dark: theme == 'dark', light: theme == 'light' }"
  >
    <div class="container d-flex justify-content-center">
      <div
        class="navbar transition"
        :class="{ dark: theme == 'dark', light: theme == 'light' }"
      >
        <a class="navbar-brand d-flex align-items-center" href="/">
          <img
            v-if="theme == 'light'"
            src="../../public/assets/img/logo_light.svg"
            width="450"
            height="450
          "
            alt="logo"
            class="img-fluid"
          />
          <img
            v-else
            src="../../public/assets/img/logo_light.svg"
            width="450"
            height="450"
            alt="logo"
            class="img-fluid"
          />
        </a>
      </div>
    </div>
    <i
      v-if="theme == 'light'"
      @click="switcher = 'dark'"
      class="bi bi-moon-fill switcher switcher-moon"
    ></i>
    <i
      v-if="theme == 'dark'"
      @click="switcher = 'light'"
      class="bi bi-sun switcher switcher-sun"
    ></i>
  </nav>
</template>

<script>
import { computed, ref, watchEffect } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  name: "Navbar",
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const theme = computed(() => {
      return store.state.theme;
    });

    const switcher = ref(theme.value);

    watchEffect(() => {
      if (switcher.value != theme.value) {
        store.commit("updateTheme", switcher.value);
      }
    });

    return {
      t,
      theme,
      switcher,
    };
  },
};
</script>

<style scoped>
.switcher {
  position: absolute;
  top: 2%;
  right: 5%;
  font-size: 20px;
  cursor: pointer;
}
.switcher-moon {
  color: #2d2c37;
  transition: 0.2s;
}

.switcher-moon:hover {
  color: #2d2c39;
}

.switcher-sun {
  color: #fff;
  transition: 0.2s;
}

.switcher-sun:hover {
  color: #efefef;
}
.dark {
  background-color: #2d2c37;
}

.light {
  background-color: #fff;
}

.transition {
  transition: 0.2s;
}

.nav-link {
  cursor: default;
}
</style>