export const da = {
  car_valuation: "KØRETØJSKLASSIFIKATION",
  toggle_navigation: "skifte navigation",
  vin: "VIN",
  plate: "bord",
  mileage: "kilometertal",
  first_reg_date: "Dato for første registrering",
  check_valuation: "vurdering",
  contact: "Kontakt",
  privacy_policy: "Fortrolighedspolitik",
  regulations: "vedtægt",
  valuation:
    "Værdiansættelsen af ​​køretøjet er udarbejdet på baggrund af mange års erfaring fra vores eksperter på bilmarkedet. Værdiansættelsesproceduren bruger kunstig intelligens (AI) til at sammenligne de skiftende tendenser i priserne på motorkøretøjer på det europæiske og amerikanske marked. Værdiansættelsen tager selvfølgelig højde for sådanne parametre som køretøjets alder og dets kilometertal. Vores værdiansættelse tager også højde for de seneste bil- og sociale problemer, såsom manglen på underenheder eller komponenter og inflation, som påvirker prisen på brugte køretøjer.",
  min_price: "Minimumspris",
  med_price: "Gennemsnitspris",
  max_price: "maksimal pris",
  we_have_report_for_you: "Vi har en rapport for dette køretøj!",
  incident_history: "hændelseshistorie",
  odometer_reading: "aflæse kilometertal",
  vehicles_inspection: "bilsyn",
  archive_photos: "arkivfotos",
  check_now: "SE RAPPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Bedøm et andet køretøj",
  type_plate: "Indtast køretøjets registreringsnummer.",
  type_first_reg_date: "",
  price_odometer: "pris ",
  price_year: "pris",
  year: "År",
  meta_title: "Online bilværdi calculator - vurder hurtigt og præcist værdien af din bil",
  meta_desc: "Online bilværdi calculator ved hjælp af VIN - vurder hurtigt og præcist værdien af din bil",
  h1_title: "Vurder din bil gratis online",
  main_tip:
    "Hvis du vil vide, hvor meget din bil er værd, kan du bruge vores online bilvurderingsside. Du skal blot indtaste din bils VIN-nummer, så hjælper vi dig med hurtigt og præcist at vurdere bilens værdi. Du behøver ikke længere at spilde tid på at søge efter priser på lignende biler på markedet eller kontakte vurderingsmænd eller forhandlere. Vores vurdering er gratis og helt anonym. Vent ikke, find ud af nu, hvor meget din bil er værd!",
  enter_vin_number: "Indtast dit stelnummer",
  next: "Næste",
  help: "Hjælp",
  check_if_stolen: "Tjek om det er stjålet",
  decode_vin_number: "Afkode VIN-nummer",
  additional_links: "Yderligere links",
  no_data_valuation: "Ingen data til værdiansættelse",
  check_vin_correct: "Kontroller rigtigheden af ​​VIN-nummeret",
  how_to_vin_valuation: "Hvordan vurderer man et køretøj før køb/salg?",
  calculator_valuation: "Online bilvurderingsberegner",
  calculator_valuation_desc:
    "Der er mange måder at anslå værdien af en bil på, den hurtigste og relativt nøjagtige vil være at tjekke den på en vinvurderingsportal. Vores proprietære algoritme understøttet af kunstig intelligens (AI) samler data fra tusindvis af onlineannoncer og analyserer præcist alder, mærke, udstyr og tilstand af de udstillede køretøjer. På den baggrund vurderer vi, hvor meget den bil du tjekker skal være værd.",
  you_get_valuation_for_free:
    "Du modtager et <strong>tilbud</strong> et øjeblik uden at forlade dit hjem, <strong>helt gratis</strong>. Alt, hvad vi behøver, er et par parametre, såsom VIN-nummeret eller køretøjets registreringsdato.",
  in_response_you_receive_not_only_valuation:
    "Som svar får du ikke kun en vurdering af den årgang, du tjekker, men også et par af dine nærmeste, yngre og ældre. Takket være dette har du fuld indsigt i, om det fx stadig kan betale sig at eje det, eller om du ikke vil tabe for meget på det og det vil være godt at sælge det med det samme.",
  compare_market_price: "Sammenlign priser på markedet",
  what_we_offer_free:
    "Det vi tilbyder gratis, kan du selvfølgelig gøre manuelt. <strong>Gennemse rubrikannoncerne på internettet</strong> eller besøg flere <strong>bilforhandlere</strong> for at finde ud af de aktuelle priser på de udbudte køretøjer.",
  if_you_are_not_sure:
    "Hvis du ikke er sikker på, hvilke data der skal til for at <strong>værdiansætte en bil</strong>, kan du kontakte en <strong>bilvurdering</strong> eller henvende dig til en forhandler eller en brugtbilforhandler. De vil også hjælpe dig med at vurdere værdien af din bil. Tag dog højde for, at den pris, leverandøren eller forhandleren tilbyder, højst sandsynligt vil være meget lavere. De skal også tjene penge for at købe en bil af dig.",
  regardless_of_which_method:
    "Uanset hvilken metode du vælger, så husk at bilvurderingen er en cirkapris. Køretøjets endelige værdi afhænger af mange faktorer, såsom teknisk stand, udstyr, servicehistorik og det helhedsindtryk, bilen gør på den potentielle køber. Det er vigtigt at tjekke din bils stand grundigt, inden du sælger eller køber den",
};
