export const ar = {
  car_valuation: "تصنيف المركبات",
  toggle_navigation: "تبديل التنقل",
  vin: "فين",
  plate: "الطاولة",
  mileage: "عدد الأميال",
  first_reg_date: "تاريخ التسجيل الأول",
  check_valuation: "تصنيف",
  contact: "اتصال",
  privacy_policy: "سياسة الخصوصية",
  regulations: "النظام الأساسي",
  valuation:
    "تم إعداد تقييم السيارة على أساس سنوات عديدة من الخبرة لخبرائنا في سوق السيارات. يستخدم إجراء التقييم الذكاء الاصطناعي (AI) لمقارنة الاتجاهات المتغيرة في أسعار السيارات في الأسواق الأوروبية والأمريكية. التقييم ، بالطبع ، يأخذ في الاعتبار عوامل مثل عمر السيارة وعدد الأميال التي قطعتها. يأخذ تقييمنا أيضًا في الاعتبار أحدث مشاكل السيارات والمشكلات الاجتماعية ، مثل عدم وجود مجموعات فرعية أو مكونات والتضخم ، مما يؤثر على أسعار السيارات المستعملة.",
  min_price: "سعر الحد الأدنى",
  med_price: "متوسط السعر",
  max_price: "أقصى سعر",
  we_have_report_for_you: "لدينا تقرير لهذه السيارة!",
  incident_history: "تاريخ الحادث",
  odometer_reading: "قراءة الأميال",
  vehicles_inspection: "فحص السيارة",
  archive_photos: "أرشفة الصور",
  check_now: "انظر التقرير",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "قيم مركبة أخرى",
  type_plate: "أدخل رقم تسجيل السيارة.",
  type_first_reg_date: "",
  price_odometer:"سعر",
  price_year: "سعر",
  year: "سنة",
  next: "التالي",
  help: "يساعد",
  check_if_stolen: "تحقق مما إذا كانت مسروقة",
  decode_vin_number: "فك شفرة رقم VIN",
  additional_links: "روابط إضافية",
  no_data_valuation: "لا توجد بيانات للتقييم",
  check_vin_correct: "تحقق من صحة رقم VIN",
  how_to_vin_valuation: "كيف تقيم المركبة قبل الشراء / البيع؟",
  calculator_valuation: "حاسبة تقييم السيارة عبر الإنترنت",
  calculator_valuation_desc:
    "هناك العديد من الطرق لتقدير قيمة السيارة ، والأسرع والأكثر دقة نسبيًا هو التحقق من ذلك على بوابة تقييم فين. تعمل خوارزمية الملكية الخاصة بنا والمدعومة بالذكاء الاصطناعي (AI) على تجميع البيانات من آلاف الإعلانات عبر الإنترنت ، لتحليل عمر المركبات المعروضة وصنعها ومعداتها وحالتها بدقة. على هذا الأساس ، نقدر المبلغ الذي يجب أن تكون عليه السيارة التي تقوم بفحصها.",
  you_get_valuation_for_free:
    "سوف تتلقى عرض <strong>أسعار</strong> بضع لحظات دون مغادرة منزلك ، <strong>مجانًا تمامًا</strong> . كل ما نحتاجه هو بعض المعلمات ، مثل رقم VIN أو تاريخ تسجيل السيارة.",
  in_response_you_receive_not_only_valuation:
    "رداً على ذلك ، لن تتلقى فقط تقييمًا للنسخة القديمة التي تقوم بفحصها ، ولكن أيضًا ستتلقى عددًا قليلاً من أقربائك وأصغرهم وكبارهم. بفضل هذا ، لديك نظرة ثاقبة حول ما إذا كان ، على سبيل المثال ، لا يزال يستحق امتلاكه ، أو ما إذا كنت لن تخسر الكثير منه وسيكون من الجيد بيعه على الفور.",
  compare_market_price: "قارن الأسعار في السوق",
  what_we_offer_free:
    "ما نقدمه مجانًا ، يمكنك بالطبع القيام به يدويًا. <strong>تصفح الإعلانات المبوبة على الإنترنت</strong> أو قم بزيارة العديد من وكلاء السيارات لمعرفة الأسعار الحالية للسيارات المعروضة.",
  if_you_are_not_sure:
    "إذا لم تكن متأكدًا من البيانات المطلوبة لتقييم السيارة ، يمكنك الاتصال <strong>بخبير تقييم السيارة</strong> أو الرجوع إلى <strong>تاجر</strong> أو تاجر سيارات مستعملة. سوف يساعدونك أيضًا في تقدير قيمة سيارتك. ومع ذلك ، ضع في الاعتبار أن السعر الذي يقدمه المورد أو بائع التجزئة سيكون على الأرجح أقل بكثير. يحتاجون أيضًا إلى كسب المال لشراء سيارة منك.",
  regardless_of_which_method:
    "بغض النظر عن الطريقة التي تختارها ، تذكر أن تقييم السيارة هو سعر تقريبي. تعتمد القيمة النهائية للسيارة على العديد من العوامل ، مثل الحالة الفنية والمعدات وسجل الخدمة والانطباع العام الذي تتركه السيارة على المشتري المحتمل. من المهم فحص حالة سيارتك بدقة قبل بيعها أو شرائها.",
  main_tip:
    "استخدم موقعنا لتسعير السيارات عبر الإنترنت باستخدام رقم VIN. بفضل هذا ، ستكتشف بسرعة وبدقة قيمة سيارتك. كل ما عليك فعله هو إدخال رقم VIN لسيارتك وسنساعدك في تقييم قيمتها. لا تنتظر ، تحقق الآن!",
  h1_title: "تقييم مجاني للمركبة",
  meta_title:
    "حاسبة تقييم السيارة عبر الإنترنت - قم بتقييم سيارتك بسرعة وبدقة.",
  meta_desc:
    "تقييم السيارة عبر الإنترنت ، بسرعة وبدقة تقييم قيمة سيارتك. ستعطي الآلة الحاسبة الخاصة بنا سعر السيارة التي تم فحصها وأقدم وأصغر قليلاً للمقارنة.",
};
