export const uk = {
  car_valuation: "КЛАСИФІКАЦІЯ АВТОМОБІЛІВ",
  toggle_navigation: "перемикати навігацію",
  vin: "VIN номер",
  plate: "стіл",
  mileage: "пробіг",
  first_reg_date: "Дата першої реєстрації",
  check_valuation: "оцінка",
  contact: "контакт",
  privacy_policy: "Політика конфіденційності",
  regulations: "статут",
  valuation:
    "Оцінка автомобіля була підготовлена ​​на основі багаторічного досвіду наших експертів на автомобільному ринку. Процедура оцінки використовує штучний інтелект (AI) для порівняння тенденцій зміни цін на автомобілі на європейському та американському ринках. При оцінці, звичайно, враховуються такі параметри, як вік автомобіля та його пробіг. Наша оцінка також бере до уваги останні автомобільні та соціальні проблеми, такі як відсутність вузлів або компонентів та інфляція, які впливають на ціни вживаних транспортних засобів.",
  min_price: "Мінімальна ціна",
  med_price: "Середня ціна",
  max_price: "максимальна ціна",
  we_have_report_for_you: "У нас є звіт щодо цього автомобіля!",
  incident_history: "історія інциденту",
  odometer_reading: "читати пробіг",
  vehicles_inspection: "перевірки транспортних засобів",
  archive_photos: "архівні фото",
  check_now: "ДИВІТЬСЯ ЗВІТ",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Оцініть інший автомобіль",
  type_plate: "Введіть реєстраційний номер автомобіля.",
  type_first_reg_date: "",
  price_odometer: "ціна",
  price_year: "ціна",
  year: "рік",
  meta_title: "Онлайн калькулятор вартості автомобіля - швидко і точно оцініть вартість свого автомобіля",
  meta_desc: "Онлайн калькулятор вартості автомобіля за допомогою VIN - швидко і точно оцініть вартість свого автомобіля",
  h1_title: "Оцініть свій автомобіль онлайн безкоштовно",
  main_tip:
    "Якщо ви хочете дізнатися, скільки коштує ваш автомобіль, скористайтеся нашим сайтом оцінки автомобілів онлайн. Просто введіть VIN-номер вашого автомобіля і ми допоможемо вам швидко і точно оцінити його вартість. Вам більше не потрібно витрачати час на пошук цін на аналогічні автомобілі на ринку або звертатися до оцінювачів чи дилерів. Наша оцінка є безкоштовною та повністю анонімною. Не чекай, дізнайся вже зараз, скільки коштує твоє авто!",
  enter_vin_number: "Введіть свій номер шасі",
  next: "Далі",
  help: "Довідка",
  check_if_stolen: "Перевірте, чи не вкрали",
  decode_vin_number: "Розшифруйте номер VIN",
  additional_links: "Додаткові посилання",
  no_data_valuation: "Немає даних для оцінки",
  check_vin_correct: "Перевірте правильність номера VIN",
  how_to_vin_valuation: "Як оцінити автомобіль перед покупкою/продажем?",
  calculator_valuation: "Онлайн калькулятор оцінки автомобіля",
  calculator_valuation_desc:
    "Є багато способів оцінити вартість автомобіля, найшвидшим і відносно точним буде перевірити це на порталі оцінки vin. Наш власний алгоритм, який підтримується штучним інтелектом (ШІ), збирає дані з тисяч онлайн-реклами, точно аналізуючи вік, марку, обладнання та стан транспортних засобів, що демонструються. На основі цього ми оцінюємо, скільки має коштувати автомобіль, який ви перевіряєте.",
  you_get_valuation_for_free:
    "Ви отримаєте <strong>пропозицію</strong> за кілька хвилин, не виходячи з дому, <strong>абсолютно безкоштовно</strong>. Все, що нам потрібно, це кілька параметрів, наприклад номер VIN або дата реєстрації автомобіля.",
  in_response_you_receive_not_only_valuation:
    "У відповідь ви отримаєте не тільки оцінку перевіреного вами врожаю, а й кількох ваших найближчих, молодших і старших. Завдяки цьому ви маєте повне уявлення про те, чи, наприклад, все ще варто володіти ним, чи ви не втратите на ньому занадто багато і було б добре продати його відразу.",
  compare_market_price: "Порівняйте ціни на ринку",
  what_we_offer_free:
    "Те, що ми пропонуємо безкоштовно, ви, звичайно, можете зробити вручну. Перегляньте <strong>оголошення в Інтернеті</strong> або відвідайте кілька <strong>автосалонів</strong>, щоб дізнатися поточні ціни на пропоновані транспортні засоби.",
  if_you_are_not_sure:
    "Якщо ви не впевнені, які дані потрібні для оцінки автомобіля, ви можете зв’язатися з <strong>оцінювачем автомобіля</strong> або звернутися до дилера чи <strong>салону вживаних автомобілів</strong>. Вони також допоможуть вам оцінити вартість вашого автомобіля. Однак враховуйте, що ціна, запропонована постачальником або продавцем, швидше за все, буде значно нижчою. Їм також потрібно заробити гроші, щоб купити у вас автомобіль.",
  regardless_of_which_method:
    "Незалежно від того, який спосіб ви виберете, пам'ятайте, що оцінка автомобіля - це приблизна ціна. Кінцева вартість автомобіля залежить від багатьох факторів, таких як технічний стан, комплектація, історія обслуговування та загальне враження, яке автомобіль справляє на потенційного покупця. Важливо ретельно перевірити стан вашого автомобіля перед його продажем або покупкою",
};
