export const sv = {
  car_valuation: "FORDONSKLASSIFICERING",
  toggle_navigation: "växla navigering",
  vin: "VIN",
  plate: "tabell",
  mileage: "miltal",
  first_reg_date: "Datum för första registrering",
  check_valuation: "värdering",
  contact: "Kontakt",
  privacy_policy: "Integritetspolicy",
  regulations: "stadga",
  valuation:
    "Värderingen av fordonet har utarbetats på basis av många års erfarenhet av våra experter på fordonsmarknaden. Värderingsförfarandet använder artificiell intelligens (AI) för att jämföra de förändrade trenderna i priserna på motorfordon på de europeiska och amerikanska marknaderna. Värderingen tar naturligtvis hänsyn till sådana parametrar som fordonets ålder och dess körsträcka. Vår värdering tar också hänsyn till de senaste fordons- och sociala problemen, såsom bristen på underenheter eller komponenter och inflation, som påverkar priset på begagnade fordon.",
  min_price: "Lägsta pris",
  med_price: "Genomsnittspris",
  max_price: "maxpris",
  we_have_report_for_you: "Vi har en rapport för detta fordon!",
  incident_history: "incidenthistorik",
  odometer_reading: "läs av körsträcka",
  vehicles_inspection: "fordonsbesiktningar",
  archive_photos: "arkivfoton",
  check_now: "SE RAPPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Betygsätt ett annat fordon",
  type_plate: "Ange fordonets registreringsnummer.",
  type_first_reg_date: "",
  price_odometer: "pris",
  price_year: "pris",
  year: "År",
  next: "Nästa",
  help: "Hjälp",
  no_data_valuation: "Inga data för värdering",
  check_if_stolen: "Kontrollera om den är stulen",
  decode_vin_number: "Avkoda VIN-nummer",
  check_vin_correct: "Kontrollera att VIN-numret är korrekt",
  additional_links: "Ytterligare länkar",
  how_to_vin_valuation: "Hur utvärderar man ett fordon innan man köper/säljer?",
  calculator_valuation: "Bilvärderingsräknare online",
  calculator_valuation_desc:
    "Det finns många sätt att uppskatta värdet på en bil, det snabbaste och relativt korrekta är att kontrollera det på en vin-värderingsportal. Vår egenutvecklade algoritm som stöds av artificiell intelligens (AI) samlar data från tusentals onlineannonser, och analyserar exakt ålder, märke, utrustning och skick på fordonen som visas. Utifrån detta uppskattar vi hur mycket bilen du kontrollerar ska vara värd.",
  you_get_valuation_for_free:
    "Du får en <strong>offert</strong> ett par ögonblick utan att lämna ditt hem, <strong>helt gratis</strong> . Allt vi behöver är några parametrar, såsom VIN-nummer eller fordonets registreringsdatum.",
  in_response_you_receive_not_only_valuation:
    "Som svar får du inte bara en värdering av årgången du kollar utan även några av dina närmaste, yngre och äldre. Tack vare detta har du full insyn i om det till exempel fortfarande är värt att äga den, eller om du inte kommer att förlora för mycket på den och det skulle vara bra att sälja den direkt.",
  compare_market_price: "Jämför priser på marknaden",
  what_we_offer_free:
    "Det vi erbjuder gratis kan du självklart göra manuellt. <strong>Bläddra bland annonserna på Internet</strong> eller besök flera bilhandlare för att ta reda på de aktuella priserna på de fordon som erbjuds.",
  if_you_are_not_sure:
    "Om du är osäker på vilka uppgifter som behövs för att värdera en bil kan du kontakta en <strong>bilvärderingsman</strong> eller vända dig till en <strong>återförsäljare</strong> eller en begagnad bilhandlare. De hjälper dig också att uppskatta värdet på din bil. Tänk dock på att priset som erbjuds av leverantören eller återförsäljaren med största sannolikhet kommer att vara mycket lägre. De måste också tjäna pengar för att köpa en bil av dig.",
  regardless_of_which_method:
    "Oavsett vilken metod du väljer, kom ihåg att bilvärderingen är ett ungefärligt pris. Fordonets slutvärde beror på många faktorer, såsom tekniskt skick, utrustning, servicehistorik och det helhetsintryck som bilen gör på den potentiella köparen. Det är viktigt att noggrant kontrollera din bils skick innan du säljer eller köper den.",
  main_tip:
    "Använd vår webbplats för att prissätta onlinebilar med hjälp av VIN-numret. Tack vare detta kommer du snabbt och noggrant ta reda på hur mycket din bil är värd. Allt du behöver göra är att ange VIN-numret på din bil så hjälper vi dig att utvärdera dess värde. Vänta inte, kolla nu!",
  h1_title: "Gratis fordonsvärdering",
  meta_title:
    "Bilvärderingskalkylator online - betygsätt din bil snabbt och noggrant.",
  meta_desc:
    "Värdering av bilen online, snabbt och noggrant bedöm värdet på din bil. Vår kalkylator kommer att ge priset på det kontrollerade fordonet och något äldre och yngre för jämförelse.",
};
