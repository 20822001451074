export const el = {
  car_valuation: "ΤΑΞΙΝΟΜΗΣΗ ΟΧΗΜΑΤΩΝ",
  toggle_navigation: "εναλλαγή πλοήγησης",
  vin: "VIN",
  plate: "τραπέζι",
  mileage: "απόσταση σε μίλια",
  first_reg_date: "Ημερομηνία πρώτης εγγραφής",
  check_valuation: "εκτίμηση",
  contact: "Επικοινωνία",
  privacy_policy: "Πολιτική Απορρήτου",
  regulations: "νόμος",
  valuation:
    "Η αποτίμηση του οχήματος έχει εκπονηθεί με βάση την πολυετή εμπειρία των ειδικών μας στην αγορά αυτοκινήτων. Η διαδικασία αποτίμησης χρησιμοποιεί τεχνητή νοημοσύνη (AI) για να συγκρίνει τις μεταβαλλόμενες τάσεις στις τιμές των μηχανοκίνητων οχημάτων στην ευρωπαϊκή και την αμερικανική αγορά. Η αποτίμηση, φυσικά, λαμβάνει υπόψη παραμέτρους όπως η ηλικία του οχήματος και τα χιλιόμετρα του. Η αποτίμησή μας λαμβάνει επίσης υπόψη τα τελευταία αυτοκινητιστικά και κοινωνικά προβλήματα, όπως η έλλειψη υποσυστημάτων ή εξαρτημάτων και ο πληθωρισμός, που επηρεάζουν την τιμή των μεταχειρισμένων οχημάτων.",
  min_price: "Κατώτατη τιμή",
  med_price: "Μέση τιμή",
  max_price: "μέγιστη τιμή",
  we_have_report_for_you: "Έχουμε μια αναφορά για αυτό το όχημα!",
  incident_history: "ιστορικό περιστατικών",
  odometer_reading: "διαβάστε χιλιόμετρα",
  vehicles_inspection: "επιθεωρήσεις οχημάτων",
  archive_photos: "φωτογραφίες αρχείου",
  check_now: "ΔΕΙΤΕ ΕΚΘΕΣΗ",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Βαθμολογήστε ένα άλλο όχημα",
  type_plate: "Εισαγάγετε τον αριθμό κυκλοφορίας του οχήματος.",
  type_first_reg_date: "",
  price_odometer: "τιμή",
  price_year: "τιμή",
  year: "Ετος",
  next: "Επόμενο",
  help: "Βοήθεια",
  no_data_valuation: "Δεν υπάρχουν στοιχεία για την αποτίμηση",
  check_if_stolen: "Ελέγξτε αν έχει κλαπεί",
  decode_vin_number: "Αποκωδικοποιήστε τον αριθμό VIN",
  additional_links: "Πρόσθετοι σύνδεσμοι",
  check_vin_correct: "Ελέγξτε την ορθότητα του αριθμού VIN",
  how_to_vin_valuation:
    "Πώς να αξιολογήσετε ένα όχημα πριν αγοράσετε/πουλήσετε;",
  calculator_valuation: "Ηλεκτρονικός υπολογιστής αποτίμησης αυτοκινήτου",
  calculator_valuation_desc:
    "Υπάρχουν πολλοί τρόποι για να εκτιμήσετε την αξία ενός αυτοκινήτου, ο πιο γρήγορος και σχετικά ακριβής θα είναι να το ελέγξετε σε μια πύλη αξιολόγησης vin. Ο ιδιόκτητος αλγόριθμός μας που υποστηρίζεται από τεχνητή νοημοσύνη (AI) συγκεντρώνει δεδομένα από χιλιάδες διαδικτυακές διαφημίσεις, αναλύοντας με ακρίβεια την ηλικία, τη μάρκα, τον εξοπλισμό και την κατάσταση των οχημάτων που εμφανίζονται. Σε αυτή τη βάση, υπολογίζουμε πόσο αξίζει το αυτοκίνητο που ελέγχετε.",
  you_get_valuation_for_free:
    "Θα λάβετε μια <strong>προσφορά</strong> για λίγα λεπτά χωρίς να φύγετε από το σπίτι σας, <strong>εντελώς δωρεάν</strong> . Το μόνο που χρειαζόμαστε είναι μερικές παραμέτρους, όπως ο αριθμός VIN ή η ημερομηνία εγγραφής του οχήματος.",
  in_response_you_receive_not_only_valuation:
    "Ως απάντηση, θα λάβετε όχι μόνο μια αποτίμηση του τρύγου που ελέγχετε, αλλά και μερικούς από τους κοντινότερους, νεότερους και μεγαλύτερους. Χάρη σε αυτό, έχετε πλήρη εικόνα για το εάν, για παράδειγμα, αξίζει ακόμα να το κατέχετε ή αν δεν θα χάσετε πάρα πολλά από αυτό και θα ήταν καλό να το πουλήσετε αμέσως.",
  compare_market_price: "Συγκρίνετε τιμές στην αγορά",
  what_we_offer_free:
    "Αυτό που προσφέρουμε δωρεάν, μπορείτε φυσικά να το κάνετε χειροκίνητα. <strong>Περιηγηθείτε στις αγγελίες στο Διαδίκτυο</strong> ή επισκεφτείτε πολλές αντιπροσωπείες αυτοκινήτων για να μάθετε τις τρέχουσες τιμές των προσφερόμενων οχημάτων.",
  if_you_are_not_sure:
    "Εάν δεν είστε βέβαιοι ποια δεδομένα χρειάζονται για την αξία ενός αυτοκινήτου, μπορείτε να επικοινωνήσετε με έναν <strong>εκτιμητή αυτοκινήτου</strong> ή να απευθυνθείτε σε έναν <strong>αντιπρόσωπο</strong> ή αντιπροσωπεία μεταχειρισμένων αυτοκινήτων. Θα σας βοηθήσουν επίσης να υπολογίσετε την αξία του αυτοκινήτου σας. Ωστόσο, λάβετε υπόψη ότι η τιμή που προσφέρει ο προμηθευτής ή ο πωλητής θα είναι πιθανότατα πολύ χαμηλότερη. Πρέπει επίσης να κερδίσουν χρήματα για να αγοράσουν ένα αυτοκίνητο από εσάς.",
  regardless_of_which_method:
    "Ανεξάρτητα από τη μέθοδο που θα επιλέξετε, να θυμάστε ότι η αποτίμηση του αυτοκινήτου είναι μια κατά προσέγγιση τιμή. Η τελική αξία του οχήματος εξαρτάται από πολλούς παράγοντες, όπως η τεχνική κατάσταση, ο εξοπλισμός, το ιστορικό σέρβις και η συνολική εντύπωση που αφήνει το αυτοκίνητο στον υποψήφιο αγοραστή. Είναι σημαντικό να ελέγξετε διεξοδικά την κατάσταση του αυτοκινήτου σας πριν το πουλήσετε ή το αγοράσετε.",
  main_tip:
    "Χρησιμοποιήστε τον ιστότοπό μας για να τιμολογήσετε ηλεκτρονικά αυτοκίνητα χρησιμοποιώντας τον αριθμό VIN. Χάρη σε αυτό, θα μάθετε γρήγορα και προσεκτικά πόσο αξίζει το αυτοκίνητό σας. Το μόνο που έχετε να κάνετε είναι να εισαγάγετε τον αριθμό VIN του αυτοκινήτου σας και θα σας βοηθήσουμε να αξιολογήσετε την αξία του. Μην περιμένετε, ελέγξτε τώρα!",
  h1_title: "Δωρεάν εκτίμηση οχημάτων",
  meta_title:
    "Ηλεκτρονικός υπολογιστής αποτίμησης αυτοκινήτου - βαθμολογήστε γρήγορα και προσεκτικά το αυτοκίνητό σας.",
  meta_desc:
    "Εκτίμηση του αυτοκινήτου online, γρήγορα και με ακρίβεια αξιολογήστε την αξία του αυτοκινήτου σας. Η αριθμομηχανή μας θα δώσει την τιμή του οχήματος που έχει ελεγχθεί και είναι ελαφρώς μεγαλύτερο και νεότερο για σύγκριση.",
};
