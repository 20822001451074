export const it = {
  car_valuation: "CLASSIFICAZIONE DEL VEICOLO",
  toggle_navigation: "attiva/disattiva la navigazione",
  vin: "VIN",
  plate: "tavolo",
  mileage: "chilometraggio",
  first_reg_date: "Data della prima immatricolazione",
  check_valuation: "valutazione",
  contact: "Contatto",
  privacy_policy: "politica sulla riservatezza",
  regulations: "statuto",
  valuation:
    "La valutazione del veicolo è stata preparata sulla base di molti anni di esperienza dei nostri esperti nel mercato automobilistico. La procedura di valutazione utilizza l'intelligenza artificiale (AI) per confrontare le mutevoli tendenze dei prezzi degli autoveicoli sui mercati europei e americani. La valutazione, ovviamente, tiene conto di parametri come l'età del veicolo e il suo chilometraggio. La nostra valutazione tiene conto anche degli ultimi problemi automobilistici e sociali, come la mancanza di sottoassiemi o componenti e l'inflazione, che incidono sul prezzo dei veicoli usati.",
  min_price: "Prezzo minimo",
  med_price: "Prezzo medio",
  max_price: "prezzo massimo",
  we_have_report_for_you: "Abbiamo un rapporto per questo veicolo!",
  incident_history: "storia dell'incidente",
  odometer_reading: "leggi chilometraggio",
  vehicles_inspection: "ispezioni del veicolo",
  archive_photos: "foto d'archivio",
  check_now: "VEDI RAPPORTO",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Valuta un altro veicolo",
  type_plate: "Inserisci il numero di targa del veicolo.",
  type_first_reg_date: "",
  price_odometer: "prezzo",
  price_year: "prezzo",
  year: "Anno",
  meta_title: "Calcolatore di valore dell'auto online - valuta rapidamente e in modo preciso il valore della tua auto",
  meta_desc: "Calcolatore di valore dell'auto online tramite VIN - valuta rapidamente e in modo preciso il valore della tua auto",
  h1_title: "Valuta la tua auto online gratuitamente",
  main_tip:
    "Se volete sapere quanto vale la vostra auto, utilizzate il nostro sito di valutazione auto online. Basta inserire il numero di telaio della vostra auto e vi aiuteremo a valutarne il valore in modo rapido e preciso. Non dovrete più perdere tempo a cercare i prezzi di auto simili sul mercato o a contattare periti o concessionari. La nostra valutazione è gratuita e completamente anonima. Non aspettare, scopri subito quanto vale la tua auto!",
  enter_vin_number: "Inserisci il tuo numero di telaio",
  next: "Prossimo",
  help: "Aiuto",
  check_if_stolen: "Controlla se rubato",
  decode_vin_number: "Decodifica il numero VIN",
  additional_links: "Collegamenti aggiuntivi",
  no_data_valuation: "Nessun dato per la valutazione",
  check_vin_correct: "Verificare la correttezza del numero VIN",
  how_to_vin_valuation: "Come valutare un veicolo prima dell'acquisto/vendita?",
  calculator_valuation: "Calcolatore di valutazione auto online",
  calculator_valuation_desc:
    "Esistono molti modi per stimare il valore di un'auto, il più veloce e relativamente accurato sarà controllarlo su un portale di valutazione del vin. Il nostro algoritmo proprietario supportato dall'intelligenza artificiale (AI) aggrega i dati di migliaia di pubblicità online, analizzando con precisione l'età, la marca, l'equipaggiamento e le condizioni dei veicoli esposti. Su questa base, stimiamo quanto dovrebbe valere l'auto che stai controllando.",
  you_get_valuation_for_free:
    "Riceverai <strong>un preventivo</strong> in pochi istanti senza uscire di casa, <strong>completamente gratuito</strong>. Tutto ciò di cui abbiamo bisogno sono alcuni parametri, come il numero VIN o la data di immatricolazione del veicolo.",
  in_response_you_receive_not_only_valuation:
    "In risposta, riceverai non solo una valutazione dell'annata che stai controllando, ma anche alcune delle tue più vicine, giovani e meno giovani. Grazie a questo, hai una visione completa se, ad esempio, vale ancora la pena possederlo o se non ci perderai troppo e sarebbe bene venderlo subito.",
  compare_market_price: "Confronta i prezzi sul mercato",
  what_we_offer_free:
    "Ciò che offriamo gratuitamente, puoi ovviamente farlo manualmente. <strong>Sfoglia gli annunci su Internet</strong> o visita diversi <strong>concessionari di automobili</strong> per scoprire i prezzi attuali dei veicoli offerti.",
  if_you_are_not_sure:
    "Se non sei sicuro di quali dati siano necessari per valutare un'auto, puoi contattare un perito o rivolgerti a un concessionario o concessionario di auto usate. Ti aiuteranno anche a stimare il valore della tua auto. Tuttavia, tieni presente che il prezzo offerto dal fornitore o dal rivenditore sarà molto probabilmente molto più basso. Devono anche guadagnare soldi per comprare un'auto da te.",
  regardless_of_which_method:
    "Indipendentemente dal metodo scelto, ricorda che la valutazione dell'auto è un prezzo approssimativo. Il valore finale del veicolo dipende da molti fattori, come le condizioni tecniche, l'equipaggiamento, la cronologia dei tagliandi e l'impressione generale che l'auto fa al potenziale acquirente. È importante controllare attentamente le condizioni della tua auto prima di venderla o acquistarla",
};
