export const fr = {
  car_valuation: "CLASSIFICATION DU VÉHICULE",
  toggle_navigation: "Basculer la navigation",
  vin: "NIV",
  plate: "table",
  mileage: "kilométrage",
  first_reg_date: "Date de première inscription",
  check_valuation: "évaluation",
  contact: "Contact",
  privacy_policy: "Politique de confidentialité",
  regulations: "loi",
  valuation:
    "L'estimation du véhicule a été préparée sur la base de nombreuses années d'expérience de nos experts du marché automobile. La procédure d'évaluation utilise l'intelligence artificielle (IA) pour comparer l'évolution des prix des véhicules automobiles sur les marchés européen et américain. L'évaluation tient bien sûr compte de paramètres tels que l'âge du véhicule et son kilométrage. Notre valorisation tient également compte des derniers problèmes automobiles et sociaux, tels que le manque de sous-ensembles ou de composants et l'inflation, qui affectent le prix des véhicules d'occasion.",
  min_price: "Prix minimum",
  med_price: "Prix moyen",
  max_price: "prix maximal",
  we_have_report_for_you: "Nous avons un rapport pour ce véhicule !",
  incident_history: "historique des incidents",
  odometer_reading: "lire le kilométrage",
  vehicles_inspection: "inspections de véhicules",
  archive_photos: "photos d'archives",
  check_now: "VOIR RAPPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Évaluer un autre véhicule",
  type_plate: "Entrez le numéro d'immatriculation du véhicule.",
  type_first_reg_date: "",
  price_odometer: "prix",
  price_year: "prix",
  year: "An",
  meta_title: "Évaluation de la valeur de votre véhicule - trouvez rapidement et précisément la valeur de votre voiture en ligne",
  meta_desc: "Calculateur de valeur de voiture en ligne par VIN - évaluez rapidement et précisément la valeur de votre voiture",
  h1_title: "Faites estimer gratuitement votre voiture en ligne",
  main_tip:
    "Si vous voulez savoir combien vaut votre voiture, utilisez notre site d'estimation de voiture en ligne. Tout ce que vous avez à faire est d'entrer le numéro VIN de votre voiture et nous vous aiderons à évaluer rapidement et précisément sa valeur. Vous n'avez plus à perdre de temps à chercher des prix de voitures similaires sur le marché ou à contacter des évaluateurs ou des concessionnaires. Notre estimation est gratuite et totalement anonyme. N'attendez plus, vérifiez dès maintenant combien vaut votre voiture !",
  enter_vin_number: "Saisissez votre numéro de châssis",
  next: "Prochain",
  help: "Aider",
  check_if_stolen: "Vérifier si volé",
  decode_vin_number: "Décoder le numéro VIN",
  additional_links: "Liens supplémentaires",
  no_data_valuation: "Pas de données pour l'évaluation",
  check_vin_correct: "Vérifier l'exactitude du numéro VIN",
  how_to_vin_valuation: "Comment évaluer un véhicule avant d'acheter/vendre ?",
  calculator_valuation: "Calculateur d'estimation de voiture en ligne",
  calculator_valuation_desc:
    "Il existe de nombreuses façons d'estimer la valeur d'une voiture, la plus rapide et relativement précise sera de la vérifier sur un portail d'estimation de vin. Notre algorithme propriétaire soutenu par l'intelligence artificielle (IA) agrège les données de milliers de publicités en ligne, analysant avec précision l'âge, la marque, l'équipement et l'état des véhicules exposés. Sur cette base, nous estimons combien devrait valoir la voiture que vous contrôlez.",
  you_get_valuation_for_free:
    "Vous <strong>recevrez</strong> un devis quelques instants sans sortir de chez vous, <strong>entièrement gratuit</strong>. Tout ce dont nous avons besoin, c'est de quelques paramètres, tels que le numéro VIN ou la date d'immatriculation du véhicule.",
  in_response_you_receive_not_only_valuation:
    "En réponse, vous recevrez non seulement une évaluation du millésime que vous vérifiez, mais aussi quelques-uns de vos plus proches, plus jeunes et plus âgés. Grâce à cela, vous savez parfaitement si, par exemple, cela vaut toujours la peine de le posséder, ou si vous n'y perdrez pas trop et qu'il serait bon de le vendre tout de suite.",
  compare_market_price: "Comparez les prix sur le marché",
  what_we_offer_free:
    "Ce que nous offrons gratuitement, vous pouvez bien sûr le faire manuellement. <strong>Parcourez les petites annonces sur Internet</strong> ou visitez plusieurs <strong>concessionnaires automobiles</strong> pour connaître les prix actuels des véhicules offerts.",
  if_you_are_not_sure:
    "Si vous n'êtes pas sûr des données nécessaires pour <strong>évaluer une voiture</strong>, vous pouvez contacter un <strong>évaluateur automobile</strong> ou vous tourner vers un concessionnaire ou un concessionnaire de voitures d'occasion. Ils vous aideront également à estimer la valeur de votre voiture. Cependant, tenez compte du fait que le prix proposé par le fournisseur ou le détaillant sera très probablement beaucoup plus bas. Ils doivent également gagner de l'argent pour vous acheter une voiture.",
  regardless_of_which_method:
    "Quelle que soit la méthode que vous choisissez, n'oubliez pas que l'évaluation de la voiture est un prix approximatif. La valeur finale du véhicule dépend de nombreux facteurs, tels que l'état technique, l'équipement, l'historique d'entretien et l'impression générale que la voiture fait sur l'acheteur potentiel. Il est important de bien vérifier l'état de votre voiture avant de la vendre ou de l'acheter",
};
