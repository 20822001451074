export const hu = {
  car_valuation: "GÉPJÁRMŰ OSZTÁLYOZÁS",
  toggle_navigation: "navigáció váltása",
  vin: "VIN",
  plate: "asztal",
  mileage: "futásteljesítmény",
  first_reg_date: "Az első regisztráció dátuma",
  check_valuation: "értékelés",
  contact: "Kapcsolatba lépni",
  privacy_policy: "Adatvédelmi irányelvek",
  regulations: "törvény",
  valuation:
    "A jármű értékbecslését szakembereink több éves autóipari tapasztalata alapján készítettük el. Az értékelési eljárás mesterséges intelligencia (AI) segítségével hasonlítja össze a gépjárművek árának alakulását az európai és amerikai piacokon. Az értékelés során természetesen olyan paramétereket is figyelembe vesznek, mint a jármű kora és futásteljesítménye. Értékelésünk során figyelembe veszik a legújabb autóipari és társadalmi problémákat is, mint például a részegységek vagy alkatrészek hiánya és az infláció, amelyek befolyásolják a használt járművek árát.",
  min_price: "Minimális ár",
  med_price: "Átlag ár",
  max_price: "maximális ár",
  we_have_report_for_you: "Jelentésünk van erről a járműről!",
  incident_history: "eseménytörténet",
  odometer_reading: "futásteljesítményt olvasni",
  vehicles_inspection: "gépjármű ellenőrzések",
  archive_photos: "archív fotók",
  check_now: "LÁSD JELENTÉS",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Értékeljen egy másik járművet",
  type_plate: "Adja meg a jármű rendszámát.",
  type_first_reg_date: "",
  price_odometer: "ár",
  price_year: "ár",
  year: "Év",
  meta_title: "Online autóértékelő kalkulátor - gyorsan és pontosan értékeld el az autódat",
  meta_desc: "Online autóértékelő kalkulátor VIN alapján - gyorsan és pontosan értékeld el az autódat",
  h1_title: "Értékelje autóját online ingyenesen",
  main_tip:
    "Ha tudni szeretné, mennyit ér autója, használja online autóértékelő oldalunkat. Nem kell mást tennie, mint beírnia autója VIN-számát és mi segítünk gyorsan és pontosan felmérni az értékét. Többé nem kell időt vesztegetnie a hasonló autóárak keresésére a piacon, vagy az értékbecslőkkel vagy kereskedőkkel való kapcsolatfelvételre. Értékbecslésünk ingyenes és teljesen anonim. Ne várjon, nézze meg most, mennyit ér az autója!",
  enter_vin_number: "Adja meg az alvázszámát",
  next: "Következő",
  help: "Segítség",
  check_if_stolen: "Ellenőrizze, hogy ellopták-e",
  decode_vin_number: "VIN szám dekódolása",
  additional_links: "További linkek",
  no_data_valuation: "Nincs adat az értékeléshez",
  check_vin_correct: "Ellenőrizze a VIN szám helyességét",
  how_to_vin_valuation:
    "Hogyan kell értékelni egy járművet vásárlás/eladás előtt?",
  calculator_valuation: "Online autóértékelő kalkulátor",
  calculator_valuation_desc:
    "Egy autó értékét sokféleképpen meg lehet becsülni, a leggyorsabb és viszonylag pontos az lesz, ha egy vinértékelő portálon ellenőrizzük. A mesterséges intelligencia (AI) által támogatott, saját fejlesztésű algoritmusunk több ezer online hirdetés adatait összesíti, pontosan elemezve a kiállított járművek korát, gyártmányát, felszereltségét és állapotát. Ennek alapján megbecsüljük, hogy mennyit érhet az Ön által ellenőrzött autó.",
  you_get_valuation_for_free:
    "Néhány <strong>percen</strong> belül árajánlatot kap anélkül, hogy elhagyná otthonát, <strong>teljesen ingyenesen</strong>. Csak néhány paraméterre van szükségünk, mint például a VIN-szám vagy a jármű regisztrációs dátuma.",
  in_response_you_receive_not_only_valuation:
    "Válaszul nem csak az ellenőrzött évjárat értékelését kapja meg, hanem néhány legközelebbi, fiatalabb és idősebb évjáratot is. Ennek köszönhetően teljes rálátása van arra, hogy például érdemes-e még birtokolni, vagy nem veszítesz túl sokat rajta és jó lenne azonnal eladni.",
  compare_market_price: "Hasonlítsa össze az árakat a piacon",
  what_we_offer_free:
    "Amit ingyenesen kínálunk, azt természetesen manuálisan is megteheti. <strong>Böngésszen az apróhirdetések között az interneten</strong>, vagy keressen fel több <strong>autókereskedést</strong>, hogy megtudja a kínált járművek aktuális árait.",
  if_you_are_not_sure:
    "Ha nem biztos abban, hogy milyen adatokra van szükség egy <strong>autó értékének</strong> meghatározásához, forduljon egy <strong>autóbecslőhöz</strong>, vagy forduljon márkakereskedőhöz vagy használtautó kereskedéshez. Segítenek megbecsülni autója értékét is. Vegye azonban figyelembe, hogy a szállító vagy a kiskereskedő által kínált ár valószínűleg sokkal alacsonyabb lesz. Pénzt is kell keresniük ahhoz, hogy autót vegyenek Öntől.",
  regardless_of_which_method:
    "Függetlenül attól, hogy melyik módszert választja, ne feledje, hogy az autó értékelése hozzávetőleges ár. A jármű végső értéke számos tényezőtől függ, például műszaki állapottól, felszereltségtől, szerviztörténettől és attól, hogy az autó milyen összbenyomást kelt a potenciális vásárlóban. Eladás vagy vásárlás előtt fontos alaposan ellenőrizni az autó állapotát",
};
