<template>
  <!-- v-if="inputToShow == 'vin'" -->
  <!-- <div class="mb-3 col-md-4"> -->
  <div class="mb-3 col-md-4 mx-auto">
    <label
      :class="{
        'text-white': theme == 'dark',
        'text-dark': theme == 'light',
      }"
      :for="id"
      class="form-label"
      ><slot></slot
    ></label>
    <input
      :id="id"
      class="form-control text-center"
      :type="type"
      value=""
      :max="id == 'date' ? '2999-12-31' : false"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="t(placeholder)"
      :maxlength="maxlength"
      :aria-describedby="ariaDesc"
      v-on:keyup.enter="$emit('checkValuation')"
    />
    <div v-if="isVinInvalid" id="invalidVin" class="form-text">
      <span
        :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }"
        >Niepoprawny numer VIN</span
      >
    </div>

    <div v-if="!isVinInvalid && id != 'vin'" :id="ariaDesc" class="form-text">
      <span
        :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }"
        >{{ t(alert) }}</span
      >
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "Input",
  props: {
    type: String,
    theme: String,
    isVinInvalid: Boolean,
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: String,
    id: String,
    ariaDesc: String,
    maxlength: String,
    alert: String,
  },
  setup() {
    const { t } = useI18n();

    return {
        t,
    }
  },
};
</script>

<style>
</style>