<template>
  <div>
    <section>
      <LanguageSelector />
    </section>

    <section class="mt-4 mb-0">
      <header>
        <h1 class="mb-0" :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
          {{ t("h1_title") }}
        </h1>
      </header>
    </section>

    <section v-if="!isLoadingVehicleInfo && vehicleInfo.model" class="mt-5">
      <transition name="fade" appear>
        <VehicleInfo :vehicleInfo="vehicleInfo" :theme="theme" />
      </transition>
    </section>

    <section v-else-if="isLoadingVehicleInfo && !vehicleInfo.model" class="mt-5">
      <div class="spinner-border" :class="{
          'text-primary': theme == 'dark',
          'text-danger': theme == 'light',
        }" role="status">
        <span class="visually-hidden"></span>
      </div>
    </section>

    <section v-if="!valuationData.price" class="d-flex" :class="{ 'mt-4': !vehicleInfo.model }">
      <div class="container">
        <transition name="fade" appear>
          <Form :theme="theme" :valuationData="valuationData" :isLoadingVehicleInfo="isLoadingVehicleInfo" />
        </transition>
      </div>
    </section>

    <section v-if="!isLoadingPrecheckData &&
          (precheckData.damages ||
            precheckData.odometers ||
            precheckData.events ||
            precheckData.photos)
          ">
      <FastPrecheck :precheckData="precheckData" :isLoadingPrecheckData="isLoadingPrecheckData" :theme="theme" />
    </section>

    <section v-if="!isLoadingPriceSection && valuationData.price" style="margin-top: 0px">
      <transition name="fade" appear>
        <PriceSection :valuationData="valuationData" :theme="theme" :currency="currency" />
      </transition>
    </section>

    <section v-if="!valuationData.price" class="mt-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              <strong>{{ t("how_to_vin_valuation") }}</strong>
            </h2>
            <h3 :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              <strong>{{ t("calculator_valuation") }}</strong>
            </h3>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              {{ t("calculator_valuation_desc") }}
            </p>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }" v-html="t('you_get_valuation_for_free')"></p>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              {{ t("in_response_you_receive_not_only_valuation") }}
            </p>
            <h3 :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              <strong>{{ t("compare_market_price") }}</strong>
            </h3>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }" v-html="t('what_we_offer_free')"></p>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }" v-html="t('if_you_are_not_sure')"></p>
            <p :class="{
          'text-white': theme == 'dark',
          'text-dark': theme == 'light',
        }">
              {{ t("regardless_of_which_method") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <Chart :currency="currency" />
          <div class="col-8 mx-auto" v-if="!isLoadingPrecheckData && vehicleInfo.vin"></div>

          <div class="col-12 mt-5" v-if="!isLoadingPrecheckData && vehicleInfo.vin">
            <router-link v-if="inputToShow != 'vin'" @click="resetForm" class="btn btn-dark" type="button"
              style="margin-left: 10px" to="/">{{ t("check_another_vin") }}</router-link>
            <!-- <button v-if="inputToShow != 'vin'" @click="resetForm" class="btn btn-dark" type="button"
              style="margin-left: 10px">
              {{ t("check_another_vin") }}
            </button> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from "../components/Form/Form.vue";
import VehicleInfo from "../components/VehicleInfo";
import PriceSection from "../components/PriceSection/PriceSection";
import FastPrecheck from "../components/FastPrecheck";
import Chart from "../components/Chart";
import { useStore } from "vuex";
import { computed, watchEffect } from "@vue/runtime-core";
import LanguageSelector from "../components/LanguageSelector";
import { useI18n } from "vue-i18n";

export default {
  name: "Home",
  components: {
    Form,
    VehicleInfo,
    PriceSection,
    Chart,
    FastPrecheck,
    LanguageSelector,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const theme = computed(() => {
      return store.state.theme;
    });

    const vehicleInfo = computed(() => {
      return store.state.carData;
    });

    const valuationData = computed(() => {
      return store.state.valuation;
    });

    const precheckData = computed(() => {
      return store.state.precheck;
    });

    const isLoadingVehicleInfo = computed(() => {
      return store.state.loadingSections.vehicleInfo;
    });

    const isLoadingPriceSection = computed(() => {
      return store.state.loadingSections.priceSection;
    });

    const isLoadingPrecheckData = computed(() => {
      return store.state.loadingSections.precheckDataSection;
    });

    const currency = computed(() => {
      return store.state.resultCurrency;
    });

    const resetForm = () => {
      store.dispatch("resetData");
    };

    watchEffect(() => {
      if (theme.value == "dark") {
        document.body.style.backgroundColor = "#2d2c37";
        document.body.style.transition = ".2s";
      } else {
        document.body.style.backgroundColor = "#fff";
      }
    });

    return {
      vehicleInfo,
      valuationData,
      precheckData,
      theme,
      isLoadingVehicleInfo,
      isLoadingPriceSection,
      isLoadingPrecheckData,
      currency,
      t,
      resetForm,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>