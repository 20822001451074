<template>
  <form @submit.prevent>
    <label
      :class="{
        'text-white': theme == 'dark',
        'text-dark': theme == 'light',
      }"
      for="vin"
      class="form-label"
      >{{ t("main_tip") }}</label
    >
    <transition v-if="showForm" name="fade" appear>
      <div>
        <div class="row d-flex flex-row justify-content-center">
          <Steps class="mt-4" />

          <div v-for="(singleInput, index) in inputsObj" :key="index">
            <Input
              v-if="inputToShow == index"
              :type="singleInput.type"
              :theme="theme"
              :isVinInvalid="isVinInvalid"
              :placeholder="singleInput.placeholder"
              v-model="singleInput.model"
              :id="singleInput.id"
              :ariaDesc="singleInput.ariaDesc"
              :maxlength="singleInput.maxlength"
              :alert="singleInput.alert"
              @checkValuation="checkValuationButton(inputToShow, country)"
            >
              {{ t(singleInput.slot) }}
            </Input>
          </div>
        </div>
        <div class="row">
          <div
            class="
              col
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            style="margin-top: 10px"
          >
            <vue-recaptcha
              theme="light"
              size="invisible"
              :tabindex="0"
              @widgetId="recaptchaWidget = $event"
              @verify="callbackVerify($event)"
              @expired="callbackExpired()"
              @fail="callbackFail()"
            />
            <Transition name="bounce" appear>
              <button
                @click="checkValuationButton(inputToShow, country)"
                :disabled="!validateForm || disableSubmitButton"
                class="btn"
                :class="{
                  'btn-primary': theme == 'dark',
                  'btn-danger': theme == 'light',
                }"
                type="button"
              >
                <span
                  v-if="
                    (country != 'PL-PL' && inputToShow == 'plate') ||
                    (country == 'PL-PL' &&
                      inputToShow == 'firstRegistrationDate')
                  "
                  >{{ t("check_valuation") }}</span
                >
                <span v-else>{{ t("next") }}</span>
              </button>
            </Transition>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-else-if="!isLoadingVehicleInfo && valuationData == false"
      class="mt-5"
    >
      <transition name="fade" appear>
        <h2
          :class="{
            'text-light': theme == 'dark',
            'text-dark': theme == 'light',
          }"
          class="
            d-flex d-xl-flex
            flex-row
            fw-bold
            justify-content-center
            align-content-center align-self-center
            dark
          "
        >
          {{ t("no_data_valuation") }}
        </h2>
      </transition>
      <a
        :class="{
          'text-light': theme == 'dark',
          'text-dark': theme == 'light',
        }"
        class="mt-2"
        href="/"
        >{{ t("back") }}</a
      >
    </div>

    <div
      v-else
      class="spinner-border mt-5 spin"
      :class="{
        'text-primary': theme == 'dark',
        'text-danger': theme == 'light',
      }"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="visually-hidden"></span>
    </div>
  </form>
</template>

<script>
import Input from "./Input/Input";

import { ref } from "@vue/reactivity";
import reqStartUp from "../../composables/reqStartUp";
import { getCurrency } from "../../utils/config";
import { computed, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Steps from "../Steps";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
// import reqCarValuation from "../../composables/reqCarValuation";
// import reqPrecheck from "../../composables/reqPrecheck";

export default {
  name: "Form",
  components: { VueRecaptcha, Steps, Input },
  props: {
    theme: String,
    valuationData: String || Boolean,
    isLoadingVehicleInfo: String || Boolean,
  },
  setup() {
    const store = useStore();
    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(null);
    const disableSubmitButton = ref(false);
    const showForm = ref(true);

    const callbackVerify = (response) => {
      store.commit("updateToken", response);
      sendForm();
    };
    const callbackExpired = () => {
      store.commit("updateToken", null);
    };
    const callbackFail = () => {
      console.log("fail");
    };
    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    const inputsObj = ref({
      vin: {
        id: "vin",
        type: "text",
        placeholder: "enter_vin_number",
        maxlength: "17",
        model: ref(""),
        ariaDesc: "invalidVin",
        slot: "VIN",
        alert: "Nieprawidłowy VIN!",
      },
      plate: {
        id: "plate",
        type: "text",
        placeholder: "",
        maxlength: "12",
        model: ref(""),
        ariaDesc: "plateDesc",
        slot: "plate",
        alert: "type_plate",
      },
      odometer: {
        id: "odometer",
        type: "number",
        placeholder: "",
        maxlength: null,
        model: ref(0),
        ariaDesc: "odometerDesc",
        slot: "mileage",
        alert: "type_odometer",
      },
      firstRegistrationDate: {
        id: "date",
        type: "date",
        placeholder: "",
        maxlength: null,
        model: ref(null),
        ariaDesc: "firstRegDateDesc",
        slot: "first_reg_date",
        alert: "type_first_reg_date",
      },
      prodYear: {
        id: "prodYear",
        type: "text",
        placeholder: "",
        maxlength: "4",
        model: ref(""),
        ariaDesc: "prodYearType",
        slot: "first_reg_date",
        alert: "unfortunately_not_found_desc",
      },
    });

    const vin = ref("");
    const odometer = ref(0);
    const plate = ref("");
    const firstRegDate = ref(null);
    const prodYear = ref("");
    const isVinInvalid = ref(false);

    const inputToShow = ref("vin");
    const country = ref("");

    const { t } = useI18n();

    const currentCurrency = computed(() => {
      return store.state.lang;
    });

    const vehicleInfo = computed(() => {
      return store.state.carData;
    });

    const currency = ref(currentCurrency.value);

    watchEffect(() => {
      currency.value = getCurrency(currentCurrency.value);

      if (inputToShow.value == "vin") {
        store.commit("updateFormStep", 1);
      } else if (inputToShow.value == "odometer") {
        store.commit("updateFormStep", 2);
      } else if (inputToShow.value == "plate") {
        store.commit("updateFormStep", 3);
      } else if (inputToShow.value == "firstRegistrationDate") {
        store.commit("updateFormStep", 4);
      }

      if (!vehicleInfo.value.make && inputToShow.value == "odometer") {
        disableSubmitButton.value = true;
      } else {
        disableSubmitButton.value = false;
      }
    });

    const validateForm = computed(() => {
      if (
        inputsObj.value.vin.model.length == 17 &&
        (inputToShow.value != "odometer" ||
          (inputToShow.value == "odometer" &&
            inputsObj.value.odometer.model)) &&
        (inputToShow.value != "prodYear" ||
          (inputToShow.value == "prodYear" &&
            inputsObj.value.prodYear.model)) &&
        (inputToShow.value != "plate" ||
          (inputToShow.value == "plate" &&
            inputsObj.value.plate.model.length)) &&
        (inputToShow.value != "firstRegistrationDate" ||
          (inputToShow.value == "firstRegistrationDate" &&
            inputsObj.value.firstRegistrationDate.model))
      ) {
        return true;
      } else {
        return false;
      }
    });

    const resetForm = () => {
      disableSubmitButton.value = false;
      store.dispatch("resetData");
      inputToShow.value = "vin";
      inputsObj.value.odometer.model = null;
      inputsObj.value.prodYear.model = null;
      inputsObj.value.plate.model = "";
      inputsObj.value.firstRegistrationDate.model = null;
    };

    const checkValuationButton = (inputType, country) => {
      if (
        (country != "PL-PL" && inputType == "plate") ||
        (country == "PL-PL" && inputType == "firstRegistrationDate")
      ) {
        showForm.value = false;
      }
      if (store.state.token) {
        sendForm();
      } else {
        window.grecaptcha.execute();
      }
    };

    const sendForm = async () => {
      disableSubmitButton.value = true;
      isVinInvalid.value = false;
      if (store.state.carData.vin != inputsObj.value.vin.model) {
        resetForm();
        store.commit("updateLoadingSectionsVehicleInfo", true);
        store.commit("updateLoadingSectionsPrecheckDataSection", true);
      }
      if (!store.state.carData.currentOdometer) {
        inputToShow.value = "odometer";
      } else {
        inputToShow.value = null;
      }

      store.commit("updateLoadingSectionsPriceStolen", true);
      store.commit("updateLoadingSectionsNearYearChart", true);
      store.commit("updateLoadingSectionsNearOdometerChart", true);

      const { sendReqStartUp, response } = reqStartUp();
      const send = await sendReqStartUp(
        inputsObj.value.vin.model,
        inputsObj.value.plate.model,
        store.state.regCountry,
        inputsObj.value.firstRegistrationDate.model,
        inputsObj.value.odometer.model,
        store.state.token,
        currency.value,
        store.state.carData.prodYear
      );

      if (response.value?.data?.message == "captcha_failed") {
        inputToShow.value = "vin";
        actionReset();
        store.commit("updateToken", null);
        store.commit("updateLoadingSectionsVehicleInfo", false);
        store.commit("updateLoadingSectionsPriceStolen", false);
        store.commit("updateLoadingSectionsNearYearChart", false);
        store.commit("updateLoadingSectionsNearOdometerChart", false);
        store.commit("updateLoadingSectionsPrecheckDataSection", false);
        disableSubmitButton.value = false;
        window.grecaptcha.execute();
        return;
      }

      if (response.value?.data?.message == "invalid_vin") {
        inputToShow.value = "vin";
        isVinInvalid.value = true;
        disableSubmitButton.value = false;
        store.commit("updateLoadingSectionsVehicleInfo", false);
        store.commit("updateLoadingSectionsPriceStolen", false);
        store.commit("updateLoadingSectionsNearYearChart", false);
        store.commit("updateLoadingSectionsNearOdometerChart", false);
        return;
      }

      store.commit("updateLoadingSectionsVehicleInfo", false);

      if (send) {
        const carInfo = {
          vin: inputsObj.value.vin.model,
          make: response.value.data.result.make,
          model: response.value.data.result.model,
          prodYear:
            response.value.data.result.productionYear ??
            inputsObj.value.prodYear.model,
          currentOdometer: inputsObj.value.odometer.model,
        };
        store.dispatch("setCarData", carInfo);
        if (response.value?.data?.result?.odometer !== null) {
          disableSubmitButton.value = false;
          if (
            !inputsObj.value.prodYear.model &&
            !response.value?.data?.result?.productionYear
          ) {
            inputToShow.value = "prodYear";
            return;
          }
          if (!response.value?.data?.result?.plate) {
            inputToShow.value = "plate";
            return;
          } else {
            inputToShow.value = null;
          }
          if (
            response.value?.data?.result?.reg_country == "PL-PL" &&
            !response.value?.data?.result?.firstRegistrationDate
          ) {
            inputToShow.value = "firstRegistrationDate";
            return;
          } else {
            inputToShow.value = null;
          }
          store.dispatch("getValuationApi", {
            ...response.value.data.result,
            productionYear: carInfo.prodYear,
          });
          inputToShow.value = "vin";
        } else {
          inputToShow.value = "odometer";
        }
        store.dispatch("getPrecheckApi", {
          vin: inputsObj.value.vin.model,
          token: response.value.data.result.token,
        });
        inputsObj.value.prodYear.model = null;
        disableSubmitButton.value = false;
        country.value = response.value.data.result.reg_country || "";
      }
    };

    return {
      vin,
      odometer,
      plate,
      firstRegDate,
      sendForm,
      resetForm,
      validateForm,
      prodYear,
      t,
      isVinInvalid,
      disableSubmitButton,
      inputToShow,
      recaptchaWidget,
      callbackVerify,
      callbackFail,
      actionReset,
      callbackExpired,
      checkValuationButton,
      showForm,
      country,
      vehicleInfo,
      inputsObj,
    };
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.form-label {
  color: white;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.spin {
  transition: 0.5s;
}
</style>