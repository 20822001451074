<template>
  <div class="p-3">
    <h4 class="display-6 fw-bold text-white mb-0">{{ valuationPrice }}</h4>
    <p class="mb-0">{{ description }}</p>
  </div>
</template>

<script>
export default {
    name: "Price",
    props: {
        valuationPrice: String || Number,
        description: String,
    }
};
</script>