export const de = {
  car_valuation: "FAHRZEUGWERTUNG",
  toggle_navigation: "Navigation umschalten",
  vin: "Fahrgestellnummer",
  plate: "Tisch",
  mileage: "Kilometerstand",
  first_reg_date: "Datum der Erstzulassung",
  contact: "Kontakt",
  privacy_policy: "Datenschutz-Bestimmungen",
  regulations: "Satzung",
  valuation:
    "Die Bewertung des Fahrzeugs wurde auf Basis der langjährigen Erfahrung unserer Experten auf dem Automobilmarkt erstellt. Das Bewertungsverfahren nutzt künstliche Intelligenz (KI), um die sich ändernde Preisentwicklung von Kraftfahrzeugen auf dem europäischen und amerikanischen Markt zu vergleichen. Bei der Wertermittlung werden selbstverständlich Parameter wie Alter des Fahrzeugs und Laufleistung berücksichtigt. Unsere Bewertung berücksichtigt auch die neuesten automobilen und sozialen Probleme, wie z. B. der Mangel an Baugruppen oder Komponenten und die Inflation, die sich auf den Preis von Gebrauchtfahrzeugen auswirken.",
  min_price: "Minimaler Preis",
  med_price: "Durchschnittlicher Preis",
  max_price: "Höchstpreis",
  we_have_report_for_you: "Wir haben ein Gutachten zu diesem Fahrzeug!",
  incident_history: "Vorfallgeschichte",
  odometer_reading: "Kilometerstand lesen",
  vehicles_inspection: "Fahrzeuginspektionen",
  archive_photos: "Archivfotos",
  check_now: "SIEHE BERICHT",
  unfortunately_not_found_desc:
    "Leider haben wir für dieses Fahrzeug kein Baujahr gefunden, bitte tragen Sie es oben in das Feld ein.",
  type_odometer:
    "Geben Sie den aktuellen Kilometerstand des Fahrzeugs in Kilometern ein, um den Wert besser einschätzen zu können.",
  check_another_vin: "Bewerten Sie ein anderes Fahrzeug",
  type_plate: "Geben Sie das Kennzeichen des Fahrzeugs ein.",
  type_first_reg_date:
    "Geben Sie das Datum der Erstzulassung des Fahrzeugs ein.",
  price_odometer: "Preis",
  price_year: "Preis",
  year: "Jahr",
  meta_title: "Online-Autowertberechner - Schnell und präzise den Wert Ihres Autos ermitteln",
  meta_desc: "Bewertung des Autos online, beurteilen Sie den Wert Ihres Autos schnell und genau. Unser Taschenrechner verleiht den Preis des Fahrzeugs zum Vergleich etwas älter und jünger.",
  h1_title: "Kostenlose Fahrzeugbewertung",
  main_tip:
    "Verwenden Sie unsere Website, um Online -Autos mit der VIN -Nummer zu bewerten. Dank dessen werden Sie schnell und sorgfältig herausfinden, wie viel Ihr Auto wert ist. Alles, was Sie tun müssen, ist die VIN -Anzahl Ihres Autos einzugeben, und wir helfen Ihnen bei der Bewertung des Werts. Warten Sie nicht, überprüfen Sie jetzt!",
  enter_vin_number: "Geben Sie Ihre Fahrgestellnummer ein",
  check_valuation: "Bewertung",
  next: "Nächste",
  help: "Hilfe",
  check_if_stolen: "Überprüfen Sie, ob gestohlen",
  decode_vin_number: "Fahrgestellnummer entschlüsseln",
  additional_links: "Zusätzliche Links",
  no_data_valuation: "Keine Daten zur Bewertung",
  check_vin_correct: "Überprüfen Sie die Korrektheit der Fahrgestellnummer",
  how_to_vin_valuation: "Wie bewertet man ein Fahrzeug vor dem Kauf/Verkauf?",
  calculator_valuation: "Autowertrechner online",
  calculator_valuation_desc: "Es gibt viele Möglichkeiten, den Wert eines Autos zu schätzen, am schnellsten und relativ genauen ist es, ihn auf einem Vin-Bewertungsportal zu überprüfen. Unser proprietärer Algorithmus, der von künstlicher Intelligenz (KI) unterstützt wird, aggregiert Daten aus Tausenden von Online-Anzeigen und analysiert genau Alter, Marke, Ausstattung und Zustand der ausgestellten Fahrzeuge. Auf dieser Grundlage schätzen wir, wie viel das von Ihnen überprüfte Auto wert sein sollte.",
  you_get_valuation_for_free: "Sie erhalten <strong>ein Angebot</strong> in wenigen Augenblicken, ohne Ihr Zuhause zu verlassen, <strong>völlig kostenlos</strong>. Alles, was wir brauchen, sind ein paar Parameter, wie die Fahrgestellnummer oder das Zulassungsdatum des Fahrzeugs.",
  in_response_you_receive_not_only_valuation: "Als Antwort erhalten Sie nicht nur eine Bewertung des von Ihnen geprüften Jahrgangs, sondern auch einige Ihrer engsten, jüngeren und älteren. Dank dessen haben Sie vollen Einblick, ob es sich zum Beispiel noch lohnt, es zu besitzen, oder ob Sie nicht zu viel verlieren werden und es gut wäre, es sofort zu verkaufen.",
  compare_market_price: "Vergleichen Sie die Preise auf dem Markt",
  what_we_offer_free: "Was wir kostenlos anbieten, können Sie selbstverständlich manuell erledigen. <strong>Stöbern Sie in den Kleinanzeigen im Internet</strong> oder besuchen Sie mehrere <strong>Autohäuser</strong>, um sich über die aktuellen Preise der angebotenen Fahrzeuge zu informieren.",
  if_you_are_not_sure: "Wenn Sie sich nicht sicher sind, welche Daten für <strong>die Bewertung eines Autos</strong> benötigt werden, können Sie sich an einen Autogutachter wenden oder sich an <strong>einen Händler</strong> oder Gebrauchtwagenhändler wenden. Sie helfen Ihnen auch dabei, den Wert Ihres Autos einzuschätzen. Berücksichtigen Sie jedoch, dass der vom Lieferanten oder Einzelhändler angebotene Preis höchstwahrscheinlich viel niedriger sein wird. Sie müssen auch Geld verdienen, um ein Auto von Ihnen zu kaufen.",
  regardless_of_which_method: "Unabhängig davon, für welche Methode Sie sich entscheiden, denken Sie daran, dass es sich bei der Autobewertung um einen ungefähren Preis handelt. Der endgültige Wert des Fahrzeugs hängt von vielen Faktoren ab, wie dem technischen Zustand, der Ausstattung, der Wartungshistorie und dem Gesamteindruck, den das Auto auf den potenziellen Käufer hinterlässt. Es ist wichtig, den Zustand Ihres Autos gründlich zu überprüfen, bevor Sie es verkaufen oder kaufen",
};
