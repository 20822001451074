<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | SITE_NAME` : `SITE_NAME`
    }}</template>
  </metainfo>
  <div>
    <Navbar />
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <footer
      class="text-center mt-5"
      :class="{ 'bg-dark': theme == 'dark', 'bg-light': theme == 'light' }"
    >
      <Footer :theme="theme" />
    </footer>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useStore } from "vuex";
import { computed, watchEffect } from "@vue/runtime-core";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();
    const theme = computed(() => {
      return store.state.theme;
    });

    const { t } = useI18n();

    const { meta } = useMeta({
      title: "VIN Valuation",
    });

    watchEffect(() => {
      meta.description = t("meta_desc");
      meta.title = t("meta_title");
    });

    return {
      theme,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.router-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
