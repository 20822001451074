<template>
  <div class="container mt-5">
    <router-link to="/">{{ t("back") }}</router-link>
    <h4 class="mt-4 text-white">{{ t("admission") }}</h4>

    <p
      :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}"
      v-html="t('regulations_admission', { email: 'info@cardataexperts.com' })"
    ></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("definition") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_definition')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("license_use_page") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_license_desc')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("use_page") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_use_page')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("limited_access") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_limited_access')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("user_posted_content") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_user_posted')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("placing_vehicle_data_in_db") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_placing_data_in_db')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("limited_and_warranties_complaints") }}</h4>

    <p
      :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}"
      v-html="t('regulations_limited_and_warranties_complaints')"
    ></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("disclaimer_of_liability") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_liability')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("relief_from_obligations") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_relief')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("violations_of_regulations") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('reulations_violations')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("changing_of_provisions_regulations") }}</h4>

    <p
      :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}"
      v-html="t('regulations_changing_provisions_regulations')"
    ></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("transfer") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_transfer')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("severability_clause") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_severability_clause')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("exclusion_third_parts") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_exclusion_parts')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("scope_of_contract") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_scope_contract')"></p>

    <h4 :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}">{{ t("governing_law_and_jurisdiction") }}</h4>

    <p :class="{ 'text-white': theme == 'dark' , 'text-dark': theme == 'light'}" v-html="t('regulations_governing_law')"></p>
  </div>
</template>
  
  <script>
import { computed } from '@vue/runtime-core';
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';

export default {
  name: "Regulations",
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const theme = computed(() => {
      return store.state.theme;
    });

    return {
      t,
      theme,
    };
  },
};
</script>
  
<style scoped>
p {
  font-size: 14px;
  /* margin-right: 30px; */
}

a:hover {
  text-decoration: underline;
}
</style>