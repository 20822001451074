<template>
  <div>
    <div
      v-if="!isMobile || showModal == true"
      :class="{
        dark: theme == 'dark',
        light: theme == 'light',
        'slide-up': showModal == true,
        'slide-down': showModal == false,
      }"
      class="
        d-flex d-xl-flex
        flex-column
        justify-content-xl-center
        align-items-xl-center
        p-3
        text-center text-white-50
        border
        rounded
        border-2 border-danger
        box
        p-4
      "
    >
      <div>
        <div>
          <i
            :class="{
              'dark-icon': theme == 'light',
              'light-icon': theme == 'dark',
            }"
            class="bi bi-x-lg position-absolute icon"
            @click="showModal = !showModal"
          ></i>
          <h3
            class="
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="margin-bottom: 0px"
          >
            {{ t("we_have_report_for_you") }}
          </h3>
          <h4
            class="
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            {{ vin }}
          </h4>
        </div>
        <ul class="list-group" style="background: transparent">
          <li
            class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0;
              padding: 3px 10px;
            "
          >
            <span>{{ t("incident_history") }}</span>
            <i
              :class="precheckData.damages > 0 ? 'ion-checkmark' : 'ion-close'"
              class="icon"
              style="margin-left: 5px"
            ></i>
          </li>
          <li
            class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0;
              padding: 3px 10px;
            "
          >
            <span>{{ t("odometer_reading") }}</span>
            <i
              :class="
                precheckData.odometers > 0 ? 'ion-checkmark' : 'ion-close'
              "
              class="icon"
              style="margin-left: 5px"
            ></i>
          </li>
          <li
            class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0px;
              padding: 3px 10px;
            "
          >
            <span>{{ t("vehicles_inspection") }}</span>
            <i
              :class="precheckData.events > 0 ? 'ion-checkmark' : 'ion-close'"
              class="icon"
              style="margin-left: 5px"
            ></i>
          </li>
          <li
            class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            "
            :class="{
              'text-white': theme == 'dark',
              'text-dark': theme == 'light',
            }"
            style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0px;
              padding: 3px 10px;
            "
          >
            <span>{{ t("archive_photos") }}</span>
            <i
              :class="precheckData.photos > 0 ? 'ion-checkmark' : 'ion-close'"
              class="icon"
              style="margin-left: 5px"
            ></i>
          </li>
        </ul>
      </div>
      <button
        class="
          btn btn-danger
          w-50
          d-flex
          justify-content-center
          align-self-center
        "
        type="button"
        style="margin-top: 15px"
        @click="redirectToPartner()"
      >
        {{ t("check_now") }}
      </button>
    </div>

    <div
      v-if="isMobile || !showModal"
      class="d-block"
      :class="{ gift: showModal == false, 'd-none': showModal == true }"
    >
      <button
        class="
          btn btn-danger
          w-100
          d-flex
          justify-content-center
          align-self-center
        "
        type="button"
        style="margin-top: 15px"
        @click="
          showModal = true;
          isMobile = !isMobile;
        "
      >
        {{ t("check_now") }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { partnerLink } from "../utils/config";

export default {
  name: "FastPrecheck",
  props: {
    precheckData: Object,
    theme: String,
    isLoadingPrecheckData: Boolean,
  },
  setup() {
    const store = useStore();

    const showModal = ref(true);
    const isMobile = ref(showModal.value);

    const lang = computed(() => {
      return store.state.lang;
    });

    const vin = computed(() => {
      return store.state.carData.vin;
    });
    const redirectToPartner = function () {
      return (window.location.href = `${partnerLink(lang.value)}/${vin.value}`);
    };

    onMounted(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        showModal.value = false;
        isMobile.value = true;
      } else {
        showModal.value = true;
        isMobile.value = false;
      }
    });

    const { t } = useI18n();

    return {
      vin,
      t,
      redirectToPartner,
      showModal,
      isMobile,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .box {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
    position: fixed;
    bottom: 10%;
    right: 5px;
    transform: translateX(5px);
    z-index: 999;
    opacity: 0.9;
  }
}
.box {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 999;
  opacity: 0.9;
}

.gift {
  animation: fadeInFromNone 0.82s ease-out;
  transition: .2s;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 999;
  cursor: pointer;
}

.gift:hover {
  scale: 1.1;
}

.dark {
  background-color: #2d2c37;
}

.light {
  background-color: #efefef;
}
.dark-icon {
  color: #000;
}

.icon {
  top: 2%;
  right: 2%;
}

.icon:hover {
  cursor: pointer;
  color: white;
}

.light-icon {
  color: #fff;
}

.slide-up {
  animation: slide-up 0.82s forwards;
}

.slide-down {
  animation: slide-down 0.82s forwards;
}

.slide-up-gift {
  animation: slide-up forwards;
  animation-delay: 0.5s;
}

.slide-down-gift {
  animation: slide-down forwards;
  animation-delay: 0.5s;
}

@keyframes slide-up {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 10%;
  }
}

@keyframes slide-down {
  0% {
    bottom: 10%;
  }

  100% {
    bottom: -100%;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
</style>