export const tr = {
  car_valuation: "ARAÇ SINIFLANDIRMASI",
  toggle_navigation: "gezinmeyi değiştir",
  vin: "VIN",
  plate: "masa",
  mileage: "kilometre",
  first_reg_date: "İlk kayıt tarihi",
  check_valuation: "değerleme",
  contact: "İletişim",
  privacy_policy: "Gizlilik Politikası",
  regulations: "tüzük",
  valuation:
    "Aracın değerlemesi, uzmanlarımızın otomotiv pazarındaki uzun yıllara dayanan deneyimlerine dayanarak hazırlanmıştır. Değerleme prosedürü, Avrupa ve Amerika pazarlarındaki motorlu araç fiyatlarındaki değişen eğilimleri karşılaştırmak için yapay zekayı (AI) kullanır. Değerleme, elbette, aracın yaşı ve kilometresi gibi parametreleri dikkate alır. Değerlememiz, kullanılmış araçların fiyatını etkileyen alt montajların veya bileşenlerin eksikliği ve enflasyon gibi en son otomotiv ve sosyal sorunları da dikkate alır.",
  min_price: "Minimum fiyat",
  med_price: "Ortalama fiyat",
  max_price: "maksimum fiyat",
  we_have_report_for_you: "Bu araç için bir raporumuz var!",
  incident_history: "olay geçmişi",
  odometer_reading: "kilometre okumak",
  vehicles_inspection: "araç muayeneleri",
  archive_photos: "arşiv fotoğrafları",
  check_now: "RAPOR GÖR",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Başka bir araca puan ver",
  type_plate: "Araç kayıt numarasını girin.",
  type_first_reg_date: "",
  price_odometer: "fiyat",
  price_year: "fiyat",
  year: "Yıl",
  meta_title: "Çevrimiçi araba değer hesaplama aracı - arabana hızlı ve doğru bir değerlendirme yapın",
  meta_desc: "VIN numarasına göre çevrimiçi araba değer hesaplama aracı - arabana hızlı ve doğru bir değerlendirme yapın",
  h1_title: "Aracınızı çevrimiçi olarak ücretsiz olarak değerlendirin",
  main_tip:
    "Aracınızın değerinin ne kadar olduğunu öğrenmek istiyorsanız, çevrimiçi araç değerleme sitemizi kullanın. Sadece aracınızın VIN numarasını girin ve değerini hızlı ve doğru bir şekilde belirlemenize yardımcı olalım. Artık piyasadaki benzer araçların fiyatlarını araştırmak veya ekspertizlerle ya da satıcılarla iletişime geçmek için zaman harcamanıza gerek yok. Değerlememiz ücretsizdir ve tamamen anonimdir. Beklemeyin, aracınızın değerini şimdi öğrenin!",
  enter_vin_number: "Şase numaranızı girin",
  next: "Sonraki",
  help: "Yardım",
  check_if_stolen: "çalıntı olup olmadığını kontrol edin",
  decode_vin_number: "VIN numarasının kodunu çöz",
  additional_links: "Ek bağlantılar",
  no_data_valuation: "Değerleme için veri yok",
  check_vin_correct: "VIN numarasının doğruluğunu kontrol edin",
  how_to_vin_valuation:
    "Bir aracı almadan/satmadan önce nasıl değerlendirmeli?",
  calculator_valuation: "Çevrimiçi araç değerleme hesaplayıcısı",
  calculator_valuation_desc:
    "Bir arabanın değerini tahmin etmenin birçok yolu vardır, en hızlı ve nispeten doğru olanı, onu bir araba değerleme portalında kontrol etmek olacaktır. Yapay zeka (AI) tarafından desteklenen tescilli algoritmamız, sergilenen araçların yaşını, markasını, donanımını ve durumunu hassas bir şekilde analiz ederek binlerce çevrimiçi reklamdan veri toplar. Buna dayanarak, kontrol ettiğiniz arabanın değerinin ne kadar olması gerektiğini tahmin ediyoruz.",
  you_get_valuation_for_free:
    "Evinizden çıkmadan birkaç dakika içinde <strong>tamamen ücretsiz bir teklif</strong> alacaksınız. Tek ihtiyacımız olan, VIN numarası veya araç tescil tarihi gibi birkaç parametre.",
  in_response_you_receive_not_only_valuation:
    "Yanıt olarak, yalnızca kontrol ettiğiniz vintagein bir değerlendirmesini değil, aynı zamanda en yakınınızdan, genç ve yaşlılarınızdan birkaçını da alacaksınız. Bu sayede, örneğin, hala sahip olmaya değer olup olmadığı veya çok fazla kaybetmeyeceğiniz ve hemen satmanın iyi olup olmayacağı konusunda tam bir içgörüye sahip olursunuz.",
  compare_market_price: "Piyasadaki fiyatları karşılaştırın",
  what_we_offer_free:
    "Ücretsiz olarak sunduğumuz şeyi, elbette manuel olarak yapabilirsiniz. <strong>İnternetteki ilanlara göz atın</strong> veya sunulan araçların güncel fiyatlarını öğrenmek için birkaç <strong>otomobil bayisini</strong> ziyaret edin.",
  if_you_are_not_sure:
    "<strong>Bir araca değer biçmek</strong> için hangi verilerin gerekli olduğundan emin değilseniz, bir <strong>araba ekspertiziyle</strong> görüşebilir veya bir bayiye veya ikinci el araba galerisine başvurabilirsiniz. Ayrıca arabanızın değerini tahmin etmenize yardımcı olurlar. Ancak, tedarikçi veya perakendeci tarafından sunulan fiyatın büyük olasılıkla çok daha düşük olacağını dikkate alın. Ayrıca sizden araba almak için para kazanmaları gerekiyor.",
  regardless_of_which_method:
    "Hangi yöntemi seçerseniz seçin, araç değerlemesinin yaklaşık bir fiyat olduğunu unutmayın. Aracın nihai değeri, teknik durum, donanım, servis geçmişi ve aracın potansiyel alıcı üzerinde bıraktığı genel izlenim gibi birçok faktöre bağlıdır. Aracınızı satmadan veya satın almadan önce iyice kontrol etmeniz önemlidir.",
};
