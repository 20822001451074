import { ref } from "vue";
import axios from "axios";

export default function reqStartUp() {
  const response = ref({});

  const sendReqStartUp = async (vin = null, plate = null, regCountry = null, firstRegDate = null, odometer = null, token = 'TOKEN', currency = 'EUR', prodYear = null, vehicleDecoder = {}) => {
    try {
      response.value = await axios.post("/api/startUp", {
        vin: vin || "",
        plate: plate ?? "",
        firstRegDate: firstRegDate || "",
        odometer: odometer || "",
        token: token,
        currency,
        reg_country: regCountry,
        productionYear: prodYear,
        make: vehicleDecoder.make || null,
        model: vehicleDecoder.model || null,
        fuel: vehicleDecoder.fuel || null,
        capacity: vehicleDecoder.capacity || null,
      });

      if (response.value.data.status != 'error') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  return {
    sendReqStartUp,
    response,
  };
}