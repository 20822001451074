<template>
  <div class="container text-white py-4 py-lg-5">
    <div class="row">
      <div class="col-12 col-md-6 p-2">
        <h4
          class="text-center text-md-start"
          :class="{
            'text-white': theme == 'dark',
            'text-dark': theme == 'light',
          }"
        >
        {{  t('help')  }}
        </h4>
        <ul class="list-inline d-flex flex-column text-center text-md-start">
          <li class="list-inline-item">
            <router-link
              :class="{
                'text-dark': theme == 'light',
              }"
              :title="t('contact')"
              aria-current="page"
              to="/contact"
              >{{ t("contact") }}</router-link
            >
          </li>
          <li class="list-inline-item">
            <router-link
              :class="{
                'text-dark': theme == 'light',
              }"
              :title="t('privacy_policy')"
              aria-current="page"
              to="/privacy-policy"
              >{{ t("privacy_policy") }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-6 p-2">
        <h4
          class="text-center text-md-start"
          :class="{
            'text-white': theme == 'dark',
            'text-dark': theme == 'light',
          }"
        >
        {{  t('additional_links')  }}
        </h4>
        <ul class="list-inline d-flex flex-column text-center text-md-start">
          <li class="list-inline-item">
            <a
              :class="{
                'text-dark': theme == 'light',
              }"
              href="https://www.stolencars.eu/"
              >Stolencars ({{  t('check_if_stolen')  }})</a
            >
          </li>
          <li class="list-inline-item">
            <a
              :class="{
                'text-dark': theme == 'light',
              }"
              href="https://www.vindecoder.pl/"
              >VINDecoder ({{  t('decode_vin_number')  }})</a
            >
          </li>
          <li class="list-inline-item">
            <a
              :class="{
                'text-dark': theme == 'light',
              }"
              href="https://www.vincorrect.com/"
              >VINCorrect ({{  t('check_vin_correct')  }})</a
            >
          </li>
          <li v-show="lang == 'pl'" class="list-inline-item">
            <a
              :class="{
                'text-dark': theme == 'light',
              }"
              href="https://easyvin.pl/"
              >EasyVIN (Sprawdź darmową historię)</a
            >
          </li>
        </ul>
      </div>
    </div>
    <hr
      :class="{
        'text-dark': theme == 'light',
      }"
    />
    <p
      class="mb-0 text-center text-md-start"
      :class="{ 'text-white': theme == 'dark', 'text-dark': theme == 'light' }"
    >
      Copyright © 2022 Car Data Experts
    </p>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  name: "Footer",
  props: {
    theme: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const lang = computed(() => {
      return store.state.lang;
    });

    return {
      lang,
      t,
    };
  },
};
</script>

<style>
</style>