export const ro = {
  car_valuation: "CLASIFICAREA VEHICULELOR",
  toggle_navigation: "Comutare navigare",
  vin: "VIN",
  plate: "masa",
  mileage: "kilometraj",
  first_reg_date: "Data primei înmatriculări",
  check_valuation: "evaluare",
  contact: "a lua legatura",
  privacy_policy: "Politica de Confidențialitate",
  regulations: "statut",
  valuation:
    "Evaluarea vehiculului a fost pregătită pe baza experienței de mulți ani a experților noștri pe piața auto. Procedura de evaluare folosește inteligența artificială (AI) pentru a compara tendințele de schimbare a prețurilor autovehiculelor pe piețele europene și americane. Evaluarea, desigur, ia în considerare parametri precum vârsta vehiculului și kilometrajul acestuia. Evaluarea noastră ține cont și de cele mai recente probleme auto și sociale, precum lipsa subansamblurilor sau componentelor și inflația, care afectează prețul vehiculelor uzate.",
  min_price: "Pret minim",
  med_price: "Prețul mediu",
  max_price: "pret maxim",
  we_have_report_for_you: "Avem un raport pentru acest vehicul!",
  incident_history: "istoricul incidentelor",
  odometer_reading: "citiți kilometrajul",
  vehicles_inspection: "inspectii vehiculelor",
  archive_photos: "arhiva fotografii",
  check_now: "VEZI RAPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Evaluează un alt vehicul",
  type_plate: "Introduceți numărul de înmatriculare al vehiculului.",
  type_first_reg_date: "",
  price_odometer: "pret",
  price_year: "pret",
  year: "An",
  meta_title: "Calculator online de evaluare a mașinilor - evaluați rapid și precis valoarea mașinii dvs.",
  meta_desc: "Calculator online de evaluare a mașinilor prin VIN - aflați rapid și precis valoarea mașinii dvs.",
  h1_title: "Evaluare online a mașinii gratuit",
  main_tip:
    "Dacă doriți să știți cât valorează mașina dumneavoastră, utilizați site-ul nostru de evaluare online. Introduceți pur și simplu numărul VIN al mașinii dvs. și vă vom ajuta să evaluați rapid și precis valoarea acesteia. Nu mai trebuie să pierdeți timp căutând prețurile unor mașini similare de pe piață sau contactând evaluatori sau dealeri. Evaluarea noastră este gratuită și complet anonimă. Nu așteptați, aflați acum cât valorează mașina dumneavoastră!",
  enter_vin_number: "Introduceți numărul de șasiu",
  next: "Următorul",
  help: "Ajutor",
  check_if_stolen: "Verificați dacă este furat",
  decode_vin_number: "Decodați numărul VIN",
  additional_links: "Link-uri suplimentare",
  no_data_valuation: "Nu există date pentru evaluare",
  check_vin_correct: "Verificați corectitudinea numărului VIN",
  how_to_vin_valuation:
    "Cum se evaluează un vehicul înainte de a cumpăra/vânzare?",
  calculator_valuation: "Calculator online de evaluare a mașinii",
  calculator_valuation_desc:
    "Există multe modalități de a estima valoarea unei mașini, cea mai rapidă și relativ precisă va fi să o verifici pe un portal de evaluare vin. Algoritmul nostru proprietar susținut de inteligența artificială (AI) adună date din mii de reclame online, analizând cu precizie vârsta, marca, echipamentul și starea vehiculelor afișate. Pe această bază, estimăm cât ar trebui să valorize mașina pe care o verificați.",
  you_get_valuation_for_free:
    "Vei <strong>primi o cotatie</strong> in cateva momente fara a iesi din casa ta, <strong>complet gratuit</strong>. Tot ce ne trebuie sunt câțiva parametri, cum ar fi numărul VIN sau data de înmatriculare a vehiculului.",
  in_response_you_receive_not_only_valuation:
    "Ca răspuns, veți primi nu doar o evaluare a vintage-ului pe care îl verificați, ci și câțiva dintre cei mai apropiați, mai tineri și mai în vârstă. Datorită acestui lucru, aveți o perspectivă completă dacă, de exemplu, merită încă să îl dețineți sau dacă nu veți pierde prea mult din el și ar fi bine să îl vindeți imediat.",
  compare_market_price: "Comparați prețurile de pe piață",
  what_we_offer_free:
    "Ceea ce oferim gratuit, desigur, puteți face manual. <strong>Răsfoiți anunturile de pe Internet</strong> sau vizitați mai multe <strong>dealeri auto</strong> pentru a afla prețurile actuale ale vehiculelor oferite.",
  if_you_are_not_sure:
    "Dacă nu sunteți sigur de ce date sunt necesare pentru a evalua o mașină, puteți contacta un <strong>evaluator de mașini</strong> sau puteți apela la un <strong>dealer</strong> sau la un dealer de mașini second-hand. De asemenea, vă vor ajuta să estimați valoarea mașinii dvs. Totusi, tine cont de faptul ca pretul oferit de furnizor sau retailer va fi cel mai probabil mult mai mic. De asemenea, trebuie să câștige bani pentru a cumpăra o mașină de la tine.",
  regardless_of_which_method:
    "Indiferent de metoda pe care o alegeți, amintiți-vă că evaluarea mașinii este un preț aproximativ. Valoarea finală a vehiculului depinde de mulți factori, cum ar fi starea tehnică, echipamentul, istoricul service-ului și impresia generală pe care mașina o face asupra potențialului cumpărător. Este important să verificați cu atenție starea mașinii dumneavoastră înainte de a o vinde sau de a o cumpăra",
};
