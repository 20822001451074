export const bg = {
  car_valuation: "КЛАСИФИКАЦИЯ НА ПРЕВОЗНОТО СРЕДСТВО",
  toggle_navigation: "превключване на навигацията",
  vin: "VIN номер",
  plate: "маса",
  mileage: "пробег",
  first_reg_date: "Дата на първа регистрация",
  check_valuation: "оценка",
  contact: "Контакт",
  privacy_policy: "Политика за поверителност",
  regulations: "устав",
  valuation:
    "Оценката на автомобила е изготвена на базата на дългогодишен опит на нашите експерти на автомобилния пазар. Процедурата за оценка използва изкуствен интелект (AI), за да сравни променящите се тенденции в цените на моторните превозни средства на европейския и американския пазар. Оценката, разбира се, взема предвид параметри като възрастта на автомобила и неговия пробег. Нашата оценка също така взема предвид най-новите автомобилни и социални проблеми, като липсата на възли или компоненти и инфлацията, които влияят на цената на употребяваните превозни средства.",
  min_price: "Минимална цена",
  med_price: "Средна цена",
  max_price: "максимална цена",
  we_have_report_for_you: "Имаме доклад за това превозно средство!",
  incident_history: "история на инцидента",
  odometer_reading: "четене на километража",
  vehicles_inspection: "прегледи на превозни средства",
  archive_photos: "архивни снимки",
  check_now: "ВИЖТЕ ДОКЛАДА",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Оценете друго превозно средство",
  type_plate: "Въведете регистрационния номер на автомобила.",
  type_first_reg_date: "",
  price_odometer: "цена",
  price_year: "цена",
  year: "година",
  meta_title: "Онлайн калкулатор за оценяване на коли - бързо и точно оценете стойността на вашата кола",
  meta_desc: "Онлайн калкулатор за оценяване на коли с номер на VIN - бързо и точно оценете стойността на вашата кола.",
  h1_title: "Онлайн калкулатор на стойността на автомобила по VIN - бързо и точно определяне на стойността на вашия автомобил",
  main_tip:
    "Ако искате да знаете колко струва вашата кола, използвайте нашия онлайн сайт за оценка на автомобили. Достатъчно е само да въведете VIN номера на вашия автомобил и ние ще ви помогнем бързо и точно да прецените стойността му. Вече не е нужно да губите време в търсене на подобни цени на автомобили на пазара или в контакт с оценители или търговци. Нашата оценка е безплатна и напълно анонимна. Не чакайте, проверете сега колко струва вашата кола!",
  enter_vin_number: "Въведете номера на шасито си",
  next: "Следващия",
  help: "Помогне",
  check_if_stolen: "Проверете дали са откраднати",
  decode_vin_number: "Декодирайте VIN номера",
  additional_links: "Допълнителни връзки",
  no_data_valuation: "Няма данни за оценка",
  check_vin_correct: "Проверете правилността на VIN номера",
  how_to_vin_valuation:
    "Как да оценим превозно средство преди покупка/продажба?",
  calculator_valuation: "Онлайн калкулатор за оценка на автомобили",
  calculator_valuation_desc:
    "Има много начини за оценка на стойността на автомобил, най-бързият и сравнително точен ще бъде да го проверите на портал за оценка на vin. Нашият собствен алгоритъм, поддържан от изкуствен интелект (AI), събира данни от хиляди онлайн реклами, като прецизно анализира възрастта, марката, оборудването и състоянието на изложените превозни средства. На тази база изчисляваме колко трябва да струва колата, която проверявате.",
  you_get_valuation_for_free:
    "Ще получите <strong>оферта</strong> за няколко минути, без да напускате дома си, <strong>напълно безплатно</strong>. Всичко, от което се нуждаем, са няколко параметъра, като VIN номер или дата на регистрация на автомобила.",
  in_response_you_receive_not_only_valuation:
    "W odpowiedzi otrzymasz nie tylko wycenę sprawdzanego przez ciebie rocznika ale również kilku najbliższych, młodszych i starszych. Masz dzięki temu pełen wgląd w to czy np. warto nadal być jego posiadaczem czy nie stracisz na tym zbyt wiele i dobrze byłoby go sprzedać właśnie teraz.",
  compare_market_price: "Сравнете цените на пазара",
  what_we_offer_free:
    "Това, което предлагаме безплатно, можете, разбира се, да направите ръчно. <strong>Разгледайте обявите в интернет</strong> или посетете <strong>няколко автокъщи</strong>, за да разберете актуалните цени на предлаганите автомобили.",
  if_you_are_not_sure:
    "Ако не сте сигурни какви данни са необходими за <strong>оценка на автомобил</strong>, можете да се свържете с <strong>оценител на автомобили</strong> или да се обърнете към <strong>търговец</strong> или автокъща за употребявани автомобили. Те също така ще ви помогнат да оцените стойността на вашия автомобил. Имайте предвид обаче, че цената, предложена от доставчика или търговеца най-вероятно ще бъде много по-ниска. Те също трябва да спечелят пари, за да си купят кола от вас.",
  regardless_of_which_method:
    "Независимо кой метод изберете, не забравяйте, че оценката на автомобила е приблизителна цена. Крайната стойност на автомобила зависи от много фактори, като техническо състояние, оборудване, сервизна история и цялостното впечатление, което автомобилът прави на потенциалния купувач. Важно е да проверите щателно състоянието на вашия автомобил, преди да го продадете или купите",
};
