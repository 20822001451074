export const sr = {
  car_valuation: "КЛАСИФИКАЦИЈА ВОЗИЛА",
  toggle_navigation: "пребаци навигацију",
  vin: "ВИН",
  plate: "сто",
  mileage: "километража",
  first_reg_date: "Датум прве регистрације",
  check_valuation: "вредновање",
  contact: "Контакт",
  privacy_policy: "Правила о приватности",
  regulations: "статут",
  valuation:
    "Процена вредности возила је направљена на основу вишегодишњег искуства наших стручњака на аутомобилском тржишту. Процедура процене користи вештачку интелигенцију (АИ) за упоређивање промена кретања цена моторних возила на европском и америчком тржишту. Процена, наравно, узима у обзир параметре као што су старост возила и његова километража. Наша процена такође узима у обзир најновије аутомобилске и друштвене проблеме, као што су недостатак подсклопова или компоненти и инфлација, који утичу на цену половних возила.",
  min_price: "Минимална цена",
  med_price: "Просечна цена",
  max_price: "максимална цена",
  we_have_report_for_you: "Имамо извештај за ово возило!",
  incident_history: "историја инцидента",
  odometer_reading: "прочитајте километражу",
  vehicles_inspection: "прегледи возила",
  archive_photos: "архивске фотографије",
  check_now: "ВИДИ ИЗВЕШТАЈ",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Оцените друго возило",
  type_plate: "Унесите регистарски број возила.",
  type_first_reg_date: "",
  price_odometer: "цена",
  price_year: "цена",
  year: "Година",
  meta_title: "Online bilvärde kalkylator - snabbt och exakt bedöm värdet på din bil",
  meta_desc: "Online bilvärde kalkylator med VIN - snabbt och exakt bedöm värdet på din bil",
  h1_title: "Utvärdera din bil online utan kostnad",
  main_tip:
    "Om du vill veta hur mycket din bil är värd kan du använda vår webbplats för bilvärdering online. Ange bara bilens VIN-nummer så hjälper vi dig att snabbt och korrekt bedöma bilens värde. Du behöver inte längre slösa tid på att söka efter priser på liknande bilar på marknaden eller kontakta värderare eller återförsäljare. Vår värdering är gratis och helt anonym. Vänta inte, ta reda på hur mycket din bil är värd!",
  enter_vin_number: "Унесите број своје шасије",
  next: "Следећи",
  help: "Помоћ",
  check_if_stolen: "Проверите да ли је украдено",
  decode_vin_number: "Дешифрирајте ВИН број",
  additional_links: "Додатне везе",
  no_data_valuation: "Nema podataka za procenu",
  check_vin_correct: "Proverite tačnost VIN broja",
  how_to_vin_valuation: "Kako procijeniti vozilo prije kupnje/prodaje?",
  calculator_valuation: "Online kalkulator za procjenu automobila",
  calculator_valuation_desc: "Postoji mnogo načina za procjenu vrijednosti automobila, najbrži i relativno točan bit će to provjeriti na portalu za procjenu vin. Naš vlastiti algoritam podržan umjetnom inteligencijom (AI) prikuplja podatke iz tisuća internetskih oglasa, precizno analizirajući starost, marku, opremu i stanje izloženih vozila. Na temelju toga procjenjujemo koliko bi automobil koji provjeravate trebao vrijediti.",
  you_get_valuation_for_free: "<strong>Ponudu ćete dobiti nekoliko</strong> trenutaka bez napuštanja svog doma, <strong>potpuno besplatno</strong>. Sve što nam treba je nekoliko parametara, kao što je VIN broj ili datum registracije vozila.",
  in_response_you_receive_not_only_valuation: "Kao odgovor dobit ćete ne samo procjenu berbe koju provjeravate, već i nekoliko svojih najbližih, mlađih i starijih. Zahvaljujući tome imate potpuni uvid isplati li ga se, primjerice, još uvijek posjedovati ili nećete na njemu previše izgubiti pa bi ga bilo dobro odmah prodati.",
  compare_market_price: "Usporedite cijene na tržištu",
  what_we_offer_free: "Ono što nudimo besplatno, naravno možete napraviti ručno. <strong>Pregledajte oglasnike na internetu</strong> ili posjetite nekoliko <strong>autokuća</strong> kako biste saznali aktualne cijene vozila u ponudi.",
  if_you_are_not_sure: "Ako niste sigurni koji podaci su potrebni za <strong>procjenu automobila</strong>, možete kontaktirati <strong>procjenitelja automobila</strong> ili se obratiti trgovcu ili salonu rabljenih automobila. Također će vam pomoći u procjeni vrijednosti vašeg automobila. Međutim, uzmite u obzir da će cijena koju nudi dobavljač ili trgovac najvjerojatnije biti znatno niža. Oni također moraju zaraditi novac da kupe auto od vas.",
  regardless_of_which_method: "Bez obzira koju metodu odaberete, imajte na umu da je procjena automobila približna cijena. Konačna vrijednost vozila ovisi o mnogim čimbenicima, kao što su tehničko stanje, oprema, servisna povijest i cjelokupni dojam koji automobil ostavlja na potencijalnog kupca. Važno je temeljito provjeriti stanje vašeg automobila prije prodaje ili kupnje",
};
