import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes/Routes";
import store from "./store/store";
import { i18n } from "./utils/langs";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createGtm } from "vue-gtm";
import VueGtag from "vue-gtag-next";
import App from "./App.vue";
import { createMetaManager } from 'vue-meta';
import VueRecaptcha from "vue3-recaptcha-v2";
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"

// test - 6LfkJu0dAAAAAK1hXWw7Da5nR8tPGixGq6oOZ1R5
// main - 6LfPxZIiAAAAADxtA-jjAUizfL3GfcQRJiuBR3kI

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(i18n)
  .use(createMetaManager())
  .use(router)
  .use(
    createGtm({
      id: "GTM-PLDH6BC",
      queryParams: false,
      defer: true,
      compatibility: false,
      nonce: false,
      enabled: true,
      debug: false,
      loadScript: false,
      vueRouter: router,
      ignoredViews: [],
      trackOnNextTick: false,
    })
  )
  .use(store)
  .use(VueGtag, {
    property: {
      id: "G-GX778Y646V",
      params: {
        send_page_view: true,
        linker: {
          domain: ["vinvaluation.com"],
        },
      },
    },
    useDebugger: false,
  })
  .use(VueRecaptcha, { siteKey: "6LfPxZIiAAAAADxtA-jjAUizfL3GfcQRJiuBR3kI" })
  .mount("#app");
