export const pl = {
  car_valuation: "WYCENA POJAZDU",
  toggle_navigation: "Przełącz nawigację",
  vin: "VIN",
  plate: "Numer rejestracyjny",
  mileage: "Przebieg",
  prod_year: "Rok produkcji",
  first_reg_date: "Data pierwszej rejestracji",
  contact: "Kontakt",
  privacy_policy: "Polityka prywatności",
  regulations: "Regulamin",
  valuation:
    "Wycena pojazdu została przygotowana na podstawie wieloletnich doświadczeń naszych ekspertów rynku motoryzacyjnego. Procedura wyceny używa sztucznej inteligencji (AI) porównującej zmieniające się trendy cen pojazdów samochodowych na rynkach europejskich oraz amerykańskim. Wycena uwzględnia oczywiście takie parametry jak wiek pojazdu i jego przebieg. Nasza wycena uwzględnia także najnowsze problemy motoryzacyjne i społeczne jak brak podzespołów czy komponentów i inflację, które wpływają na cenę pojazdów używanych.",
  min_price: "Cena minimalna",
  med_price: "Cena średnia",
  max_price: "Cena maksymalna",
  we_have_report_for_you: "Mamy raport dla tego pojazdu!",
  incident_history: "Historia incydentów",
  odometer_reading: "Odczyt przebiegu",
  vehicles_inspection: "Inspekcje pojazdów",
  archive_photos: "Zdjęcia archiwalne",
  check_now: "ZOBACZ RAPORT",
  unfortunately_not_found_desc:
    "Niestety nie znaleźliśmy roku produkcji dla tego pojazdu, wprowadź go w polu powyżej.",
  type_odometer:
    "Wprowadź obecny przebieg pojazdu w kilometrach w celu lepszego oszacowania jego wartości.",
  check_another_vin: "Wyceń inny pojazd",
  type_plate: "Wprowadź tablicę rejestracyjną pojazdu.",
  type_first_reg_date:
    "Wprowadź datę pierwszej rejestracji pojazdu. Znajdziesz ją w dowodzie rejestracyjnym w polu B.",
  price_odometer: "Cena",
  price_year: "Cena",
  year: "Rok",
  regulations_admission:
    '<p>Niniejszy Regulamin określa zasady korzystania ze strony internetowej prowadzonej pod nazwą "VinValuation", w domenie internetowej "www.vinValuation.com"(dalej zwanej "Stroną"). Niniejszy Regulamin zawiera zasady korzystania ze Strony. Jeśli nie wyrażasz zgody na stosowanie niniejszego Regulaminu lub któregokolwiek z jego postanowień, powinieneś natychmiast opuścić niniejszą Stronę.</p><p>Pełna nazwa naszej firmy to "Car Data Experts LTD.". Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Companies house number: 10327199. Można się z nami kontaktować za pośrednictwem adresu e-mail: <a href="mailto:{email}">{email}</a></p><p>Głównym celem Strony jest udostępnienie jej użytkownikom możliwości umieszczania danych o skradzionych lub uszkodzonych pojazdach w bazie danych "Car Data" (Dane Pojazdu) i udostępniania tych danych innym osobom korzystającym z tej bazy. </p><p>Usługi dostępne na Stronie przeznaczone są dla osób fizycznych, osób prawnych i innych podmiotów, które mają miejsce zamieszkania lub siedzibę na terenie Unii Europejskiej lub Stanów Zjednoczonych Ameryki Północnej. </p><p>Usługi na Stronie są kierowane wyłącznie do osób mających ukończone 18 lat.</p><p>Zasady przetwarzania przez nas danych osobowych i używania plików zwanych ciasteczkami (cookies) przedstawione są w naszej Polityce Prywatności dostępnej na Stronie.</p><p>By korzystać ze Strony użytkownik musi używać urządzenia mającego dostęp do Internetu oraz przeglądarki internetowej Internet Explorer 9.0 lub podobnego programu. By korzystać z niektórych funkcjonalności Strony należy korzystać z oprogramowania Java i Java Script, jak i zezwolić na używanie ciasteczek. Nie ponosimy odpowiedzialności za brak dostępu do danych zamieszczonych na naszej Stronie w wyniku braku zgodności urządzenia użytkownika z wymaganiami technicznymi wskazanymi powyżej.</p>',
  regulations_definition:
    '<p>W niniejszym Regulaminie terminom wskazanym poniżej przypisuje się następujące znaczenie:</p><p>"Dane Pojazdu"oznaczają dane o skradzionych lub uszkodzonych pojazdach oraz okolicznościach kradzieży lub powstania szkody umieszczane przez użytkowników Strony w bazie danych "Car Data" i udostępniane innym osobom korzystającym z tej bazy.</p><p>"Konsument"oznacza każdą osobę fizyczną, która działa w celach niezwiązanych z działalnością gospodarczą lub zawodową.</p><p>"Baza Danych"oznacza naszą bazę danych "Car Data" o stanie technicznym i historii pojazdów.</p><p>"Numer VIN" oznacza numer identyfikacyjny pojazdu (Vehicle Identification Number), używany przez branżę motoryzacyjną do zidentyfikowania konkretnego egzemplarza pojazdu silnikowego, ciągnika, motocykla, skutera i motoroweru, zgodnie z definicją w normie ISO 3833.</p>',
  regulations_license_desc:
    "<p>Z zastrzeżeniem innych postanowień, wszelkie prawa własności intelektualnej do Strony oraz do materiałów zawartych na Stronie przysługują nam lub naszym licencjodawcom. Z zastrzeżeniem poniższej licencji wszystkie prawa własności intelektualnej są zastrzeżone.</p> <p>Użytkownicy mogą oglądać, ściągać pliki w celu buforowania, wyłącznie w celu własnego użytku, z zastrzeżeniem wyjątków wskazanych poniżej oraz w pozostałych częściach Regulaminu.</p><p>Zakazane są w szczególności:</p><p>a. dystrybucja materiałów uzyskanych ze Strony (wliczając dystrybucję na innej stronie internetowej);</p><p>b. sprzedaż, najem, użyczenie, darowanie, przekazywanie albo udzielenie sublicencji odnośnie Strony i materiałów znajdujących się na Stronie;</p><p>c. odtwarzanie publiczne jakichkolwiek materiałów dostępnych na Stronie;</p><p>d. reprodukowanie, zwielokrotnienie, kopiowanie lub inne korzystanie z materiałów zgromadzonych na Stronie w celach komercyjnych;</p><p>e. zmienianie lub w inny sposób modyfikowanie jakichkolwiek materiałów znajdujących się na Stronie;</p><p>f. dystrybuowanie materiałów uzyskanych ze Strony, bez uzyskania naszej wyraźnej pisemnej zgody na powyższe.</p>",
  regulations_use_page:
    "<p>1. Zakazane jest używanie Strony w jakikolwiek sposób szkodliwy dla niej lub wpływający negatywnie na możliwość korzystania ze Strony; lub w jakikolwiek inny sposób, który jest nielegalny, bezprawny, szkodliwy lub powiązany z jakimkolwiek nielegalnym, bezprawnym lub szkodliwym celem lub działaniem. Zastrzegamy sobie wszelkie prawa do wszelkich informacji i materiałów, w tym graficznych i innych, dostępnych na Stronie.</p><p>2. Zakazane jest korzystanie ze Strony w celu kopiowania, korzystania, przechowywania, transmitowania, wysyłania, publikowania lub dystrybuowania jakichkolwiek materiałów, które zawierają jakiegokolwiek oprogramowanie szpiegowskie, wirusa, konia trojańskiego, robaka, keystroke logger, rootkit lub inne szkodliwe oprogramowanie lub urządzenia, lub odwołują się do nich.</p><p>3. Zakazane jest jakiekolwiek systematyczne lub automatyczne pozyskiwanie danych ze Strony lub danych powiązanych ze Stroną (w tym za pomocą następujących technik: scraping, data mining, data extraction i data harvesting) bez naszej pisemnej zgody.</p><p>4. Zakazane jest korzystanie ze Strony w celu transmisji lub wysyłania niezamówionej informacji handlowej.</p><p>5. Zakazane jest wykorzystywanie Strony do jakichkolwiek celów marketingowych bez naszej pisemnej zgody.</p> ",
  regulations_limited_access:
    "<p>1. Dostęp do części zasobów Strony jest ograniczony. Mamy prawo do ograniczenia dostępu do innych zasobów Strony lub ograniczenia dostępu do całej Strony, z zastrzeżeniem uprawnień użytkowników wynikających z wcześniej zawartych umów.</p><p>2. W przypadku uzyskania od nas przez użytkownika loginu lub loginu i hasła umożliwiających dostęp do zasobów z ograniczonym dostępem znajdujących się na Stronie lub innych zawartości lub usług z ograniczonym dostępem, użytkownik jest zobowiązany do zabezpieczenia hasła i loginu, by nie został on ujawniony żadnej osobie trzeciej.</p>",
  regulations_user_posted:
    "<p>1. Zawartość zamieszczona przez użytkownika nie może być niezgodna z prawem, zasadami współżycia społecznego, Netykietą i innymi ustalonymi zwyczajami lub naruszać jakiekolwiek prawa osób trzecich, ani nie może dawać podstaw do wystąpienia z jakimkolwiek roszczeniem lub środkiem prawnym przeciwko użytkownikowi, nam lub osobie trzeciej (w każdym przypadku, w zakresie każdego prawa właściwego).</p><p>2. Zakazane jest umieszczanie na Stronie jakichkolwiek treści, które są lub były przedmiotem jakiegokolwiek toczącego się obecnie lub grożącego w przyszłości postępowania spornego lub innego jemu podobnego.</p><p>3. Zastrzegamy sobie prawo do zmiany lub usunięcia jakichkolwiek treści lub materiału zamieszczonego na Stronie, przechowywanego lub opublikowanego na niej.</p><p>4. Z zastrzeżeniem przepisów niniejszego Regulaminu i obowiązującego prawa w odniesieniu do treści i innych materiałów umieszczonych na Stronie przez użytkownika, nie jesteśmy zobowiązani do monitorowania zamieszczanych lub publikowanych na Stronie treści i materiałów.</p><p>5. Wszelkie dane zamieszczone przez użytkownika na Stronie powinny być również zachowane na jego własnych urządzeniach w celu dodatkowego zabezpieczenia przed ich utratą.</p>",
  regulations_placing_data_in_db:
    "<p>1. Użytkownik udostępnia Dane Pojazdu w Bazie Danych za pomocą formularza zamieszczonego na Stronie.</p><p>2. Użytkownik odpowiada za zgodność Danych Pojazdu z aktualnym stanem faktycznym. W przypadku zmiany stanu faktycznego użytkownik powinien niezwłocznie zaktualizować stan Danych Pojazdu udostępnionych w Bazie Danych.</p><p>3. Po zgłoszeniu Danych Pojazdu do Bazy Danych użytkownik otrzymuje na podany przez niego adres e-mail informację z potwierdzeniem umieszczenia tych danych w Bazie Danych.</p><p>4. Dane Pojazdu są umieszczana w Bazie Danych i udostępniane innym podmiotom korzystającym z tej bazy, w celu uzyskania informacji o określonym pojeździe identyfikowanym za pomocą Numeru VIN tego pojazdu.</p><p>5. Jeśli w Bazie Danych pojawi się nowa informacja dotycząca Danych Pojazdu, będziemy starali się o tym poinformować na podany adres e-mail użytkownika, który zgłosił Dane Pojazdu do tej bazy.</p><p>6. Dane Pojazdu można wycofać z Bazy Danych kontaktując się z nami za pośrednictwem formularza kontaktowego na Stronie lub pisząc na adres e-mail podany we Wstępie do Regulaminu.</p>",
  regulations_limited_and_warranties_complaints:
    '<p>1. Dla uniknięcia wątpliwości, na podstawie umowy z użytkownikiem o udostępnienie Danych Pojazdu w Bazie Danych nie jesteśmy zobowiązani do odnalezienia pojazdu, którego dotyczą Dane Pojazdu.</p><p>2. W przypadku użytkowników niebędących Konsumentami, w granicach przewidzianych prawem właściwym, nie składamy żadnych zapewnień, nie udzielamy gwarancji co do jakości, wyłączamy rękojmię oraz nie składamy zapewnień co do standardów wykonywania usług na Stronie i korzystania ze Strony (w szczególności wyłączamy wszelkie gwarancje, nie składamy żadnych zapewnień przewidzianych w obowiązującym prawie odnośnie satysfakcjonującej jakości, przydatności do celu lub zachowania należytej staranności i umiejętności).</p><p>3. W przypadku zauważenia problemów technicznych na Stronie lub zauważenia niekompletności lub nieaktualności informacji udostępnianych przez nas, jak i wszelkich skarg dotyczących Strony (reklamacje), należy niezwłocznie nas powiadomić przez użycie formularza na stronie "Kontakt" lub przez napisanie do nas wiadomości na adres e-mail wskazany powyżej we Wstępie Regulaminu. Staramy się odpowiadać niezwłocznie.</p>',
  regulations_liability:
    "<p>1. Żadne postanowienie w niniejszym Regulaminie (a) nie wyłącza ani nie ogranicza naszej odpowiedzialności za wyrządzoną z winy umyślnej szkodę lub umyślne wprowadzenie w błąd; (b) nie ogranicza naszej lub odpowiedzialności użytkownika w jakikolwiek sposób, który byłby sprzeczny z prawem właściwym; ani (c) nie wyłącza odpowiedzialności naszej lub użytkownika, jeżeli w tym zakresie odpowiedzialność nie może zostać wyłączona zgodnie z prawem właściwym; w szczególności Regulamin nie wyłącza odpowiedzialności naszej lub użytkownika za szkody spowodowane winą umyślną.</p><p>2. Wyłączenia i ograniczenia odpowiedzialności przewidziane w Regulaminie:</p><p> a. podlegają ograniczeniom wskazanym w ustępie 1 powyżej;</p><p> b. dotyczą wszelkiej odpowiedzialności mogącej powstać na gruncie Regulaminu lub Polityki Prywatności w związku z zakresem przedmiotowym Regulaminu i Polityki Prywatności, wliczając odpowiedzialność kontraktową, deliktową lub inną jej podobną;</p><p> c. nie dotyczą Konsumentów.</p><p>3. W przypadku użytkowników niebędących Konsumentami, tak dalece jak jest to możliwe w ramach prawa właściwego:</p><p> a. nie odpowiadamy za żadne szkody jakiegokolwiek rodzaju, chyba że szkoda powstała z naszej winy umyślnej. Rękojmia oraz jakakolwiek odpowiedzialność wynikająca z instytucji podobnych do rękojmi zostaje niniejszym wyłączona;</p><p> b. nie odpowiadamy za żadną utratę lub uszkodzenie jakichkolwiek danych, bazy danych lub oprogramowania zarówno naszego jak i użytkownika.</p><p>4. Nie odpowiadamy za jakiekolwiek szkody powstałe w związku ze zdarzeniem lub zdarzeniami pozostającymi poza naszą kontrolą.</p>",
  regulations_relief:
    "<p>Użytkownicy niebędący Konsumentami zwalniają nas od rekompensaty wszelkich strat, szkód, odszkodowań, kosztów, odpowiedzialności i wydatków (w tym kosztów związanych z jakimikolwiek postępowaniami oraz każdej zapłaconej przez nas osobie trzeciej kwoty tytułem ugody) związanych z jakimkolwiek naruszeniem przez użytkownika niniejszego Regulaminu, oraz są zobowiązani do zwrócenia nam poniesionych przez nas kosztów, o których mowa powyżej.</p>",
  reulations_violations:
    "<p>Z zastrzeżeniem wszelkich innych naszych uprawnień wynikających z niniejszego Regulaminu, w przypadku naruszenia przez użytkownika w jakikolwiek sposób niniejszego Regulaminu, możemy podjąć takie działania, jakie uznamy za właściwe w celu reakcji na naruszenie, a umożliwiające powstrzymanie dalszego naruszenia oraz ograniczenie powstania dalszej szkody, wliczając w to zawieszenie dostępu użytkownika do Strony, uniemożliwienie dostępu do Strony użytkownikowi, zablokowanie dostępu do Strony dla danego adresu IP, skontaktowanie się z provider-em Internetu użytkownika w celu zablokowania dostępu do Strony lub skierowania sprawy na drogę sądową.</p>",
  regulations_changing_provisions_regulations:
    "<p>1. Regulamin może być zmieniany przez umieszczenie jego nowej wersji na Stronie. W przypadku gdy użytkownik udostępni nam swoje dane osobowe, w szczególności adres e-mail, i gdy będziemy świadczyli na jego rzecz usługi w trakcie zmiany niniejszego Regulaminu, poinformujemy go o wszelkich zmianach niniejszego Regulaminu za pośrednictwem poczty e-mail.</p><p>2. W przypadku użytkowników niebędących Konsumentami zmiana naszych informacji kontaktowych nie stanowi zmiany niniejszego Regulaminu.</p><p>3. W przypadku zmiany adresu e-mail wskazanego podczas rejestracji, użytkownicy są zobowiązani do uaktualnienia tego adresu na Stronie.</p>",
  regulations_transfer:
    "<p>Użytkownicy nie mogą przenosić, udostępnić innym lub w inny sposób rozporządzać swoimi prawami i obowiązkami wynikającymi z niniejszego Regulaminu, jak i zaciągać zobowiązania odnośnie tych praw i obowiązków.</p>",
  regulations_severability_clause:
    "<p>W przypadku gdy którekolwiek postanowienie niniejszego Regulaminu zastanie uznane przez sąd lub inną właściwą władzę za nieważne lub bezskuteczne, pozostałe postanowienia Regulaminu pozostają wiążące. Jeżeli nieważne lub bezskuteczne postanowienie byłoby ważne po usunięciu jego części, ta część będzie uznana za usuniętą, a pozostała część postanowienia będzie uznawana za obowiązującą.</p>",
  regulations_exclusion_parts:
    "<p>Regulamin określa wyłącznie relacje między nami a użytkownikami Strony i nie przewiduje żadnych uprawnień dla osób trzecich ani nie uprawnia ich do powoływania się na którekolwiek z jego postanowień lub żądania wykonania tych postanowień. Wykonanie postanowień Regulaminu nie wymaga uzyskania zgody jakiejkolwiek osoby trzeciej.</p>",
  regulations_scope_contract:
    "<p>Niniejszy Regulamin, łącznie z naszą Polityką Prywatności i cenami usług wskazanymi na Stronie, stanowi umowę między nami a użytkownikiem odnośnie korzystania ze Strony i zastępuje wszelkie wcześniejsze regulacje dotyczące korzystania ze Strony.</p>",
  regulations_governing_law:
    "<p>1. Niniejszy Regulamin podlega prawu polskiemu, a w zakresie nieuregulowanym w Regulaminie zastosowanie znajdują w szczególności ustawa z 23 kwietnia 1964 r. Kodeks cywilny, ustawa z 30 maja 2014 r. o prawach konsumenta (w odniesieniu do Konsumentów) i ustawa z 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.</p><p>2. W przypadku użytkowników niebędących Konsumentami wszelkie spory powstałe na gruncie Regulaminu podlegać będą wyłącznej jurysdykcji polskich sądów.</p><p>3. W przypadku użytkowników będących Konsumentami, których miejsce zamieszkania jest zlokalizowane w Polsce w dacie zawarcia umowy o udostępnienie Danych Pojazdu, wszelkie spory związane z niniejszym Regulaminem lub Polityką Prywatności podlegać będą wyłącznej jurysdykcji sądów polskich.</p>",
  back: "Wróć",
  definition: "Definicje",
  admission: "Wstęp",
  license_use_page:
    "Licencja do używania Strony i dostępnych na niej materiałów",
  use_page: "Użytkowanie Strony",
  limited_access: "Ograniczony dostęp",
  user_posted_content: "Zawartość zamieszczona przez Użytkownika",
  placing_vehicle_data_in_db: "Umieszczanie Danych Pojazdu w Bazie Danych",
  disclaimer_of_liability: "Wyłączenie odpowiedzialności",
  relief_from_obligations: "Zwolnienie ze zobowiązań",
  violations_of_regulations: "Naruszenia Regulaminu",
  transfer: "Cesja",
  severability_clause: "Klauzula salwatoryjna",
  exclusion_third_parts: "Wyłączenie praw osób trzecich",
  scope_of_contract: "Zakres umowy",
  changing_of_provisions_regulations: "Zmiana postanowień Regulaminu",
  governing_law_and_jurisdiction: "Prawo właściwe i jurysdykcja",
  limited_and_warranties_complaints:
    "Ograniczone oświadczenia i zapewnienia oraz zgłaszanie reklamacji",
  main_tip:
    "Skorzystaj z naszej strony do wyceniania samochodów online za pomocą numeru VIN. Dzięki temu szybko i dokładnie dowiesz się, ile jest wart Twój samochód. Wystarczy, że wprowadzisz numer VIN swojego auta, a my pomożemy Ci ocenić jego wartość. Nie czekaj, sprawdź już teraz!",
  enter_vin_number: "Wprowadź numer VIN",
  meta_title: "Kalkulator wyceny samochodu online - szybko i dokładnie oceń swoje auto.",
  meta_desc: "Wycena samochodu online, szybko i dokładnie oceń wartość swojego samochodu. Nasz kalkulator poda cenę sprawdzanego rocznika pojazdu i nieco starszych oraz młodszych dla porówniania.",
  h1_title: "Wycena samochodu online wystarczy numer VIN",
  check_valuation: "Wycena",
  next: "Dalej",
  help: "Pomoc",
  no_data_valuation: "Brak danych do wyceny",
  check_if_stolen: "Sprawdź czy kradziony",
  decode_vin_number: "Dekoduj numer VIN",
  additional_links: "Dodatkowe linki",
  check_vin_correct: "Sprawdź poprawność numeru VIN",
  how_to_vin_valuation: "Jak wycenić pojazd przed kupnem / sprzedażą?",
  calculator_valuation: "Kalkulator wyceny samochodu online",
  calculator_valuation_desc: "Jest wiele metod oszacowania wartości samochodu najszybszą i stosunkowo dokładną będzie sprawdzenie go na stronie internetowej takiej jak vinvaluation. Nasz autorski algorymt wspomagany sztuczną inteligencją (AI) agreguje dane z tysięcy ogłoszeń internetowych precyzyjnie analizując wiek, markę, wyposażenie i stan wystawianych pojazdów. Na tej podstawie szacujemy ile powinien być wart sprawdzany przez ciebie samochód.",
  you_get_valuation_for_free: "<strong>Wycenę otrzymujesz</strong> w kilka chwil bez wychodzenia z domu całkowicie za darmo. Potrzebujemy jedynie kilku parametrów takich jak numer VIN czy datę zarejestrowania pojazdu.",
  in_response_you_receive_not_only_valuation: "W odpowiedzi otrzymasz nie tylko wycenę sprawdzanego przez ciebie rocznika ale również kilku najbliższych, młodszych i starszych. Masz dzięki temu pełen wgląd w to czy np. warto nadal być jego posiadaczem czy nie stracisz na tym zbyt wiele i dobrze byłoby go sprzedać właśnie teraz.",
  compare_market_price: "Porównaj ceny na rynku",
  what_we_offer_free: "To co oferujemy bezpłatnie możesz oczywiście zrobić ręcznie. Przejrzyj <strong>ogłoszenia w internecie</strong> lub odwiedź kilka <strong>komisów samochodowych</strong>, aby poznać aktualne ceny oferowanych pojazdów.",
  if_you_are_not_sure: "Jeśli nie jesteś pewien, jakie dane są potrzebne do wyceny samochodu, możesz skontaktować się z <strong>rzeczoznawcą samochodowym</strong> lub zwrócić się do <strong>dealera</strong> lub komisu samochodowego. Oni również będą w stanie pomóc Ci w oszacowaniu wartości Twojego samochodu. Weź jednak pod uwagę, że oferowana przez komis czy dealera cena będzie najprawdopodobniej mocno zaniżona. Oni też muszą zarobić na odkupieniu auta od Ciebie.",
  regardless_of_which_method: "Niezależnie od tego, którą metodę wybierzesz, pamiętaj, że wycena samochodu to tylko orientacyjna cena. Ostateczna wartość pojazdu zależy od wielu czynników, takich jak stan techniczny, wyposażenie, historia serwisowa i ogólne wrażenie, jakie samochód wywiera na potencjalnym nabywcy. Ważne, aby dokładnie sprawdzić stan samochodu przed jego sprzedażą lub zakupem.",
};
