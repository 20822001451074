export const es = {
  car_valuation: "CLASIFICACIÓN DE VEHÍCULOS",
  toggle_navigation: "navegación de palanca",
  vin: "VIN",
  plate: "mesa",
  mileage: "kilometraje",
  first_reg_date: "Fecha del primer registro",
  check_valuation: "valuación",
  contact: "Contacto",
  privacy_policy: "Política de privacidad",
  regulations: "estatuto",
  valuation:
    "La valoración del vehículo se ha elaborado sobre la base de muchos años de experiencia de nuestros expertos en el mercado de la automoción. El procedimiento de valoración utiliza inteligencia artificial (IA) para comparar las tendencias cambiantes de los precios de los vehículos de motor en los mercados europeo y americano. La valoración, por supuesto, tiene en cuenta parámetros como la antigüedad del vehículo y su kilometraje. Nuestra valoración también tiene en cuenta los últimos problemas automotrices y sociales, como la falta de subconjuntos o componentes y la inflación, que afectan el precio de los vehículos usados.",
  min_price: "Precio mínimo",
  med_price: "Precio promedio",
  max_price: "precio máximo",
  we_have_report_for_you: "¡Tenemos un informe para este vehículo!",
  incident_history: "historial de incidentes",
  odometer_reading: "leer kilometraje",
  vehicles_inspection: "inspecciones de vehículos",
  archive_photos: "fotos de archivo",
  check_now: "VER INFORME",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Valorar otro vehículo",
  type_plate: "Ingrese el número de registro del vehículo.",
  type_first_reg_date: "",
  price_odometer: "precio",
  price_year: "precioaño",
  year: "Año",
  meta_title: "Calculadora de valor de coche en línea - evalúa rápida y precisamente el valor de tu coche",
  meta_desc: "Calculadora de valor de coche en línea por VIN - evalúa rápida y precisamente el valor de tu coche",
  h1_title: "Evalúe su automóvil en línea de forma gratuita",
  main_tip:
    "Si desea saber cuánto vale su automóvil, utilice nuestro sitio de valoración de automóviles en línea. Todo lo que tiene que hacer es ingresar el número VIN de su automóvil y lo ayudaremos a evaluar su valor de manera rápida y precisa. Ya no tiene que perder el tiempo buscando precios de automóviles similares en el mercado o contactando a tasadores o concesionarios. Nuestra valoración es gratuita y completamente anónima. ¡No esperes más, comprueba ahora cuánto vale tu coche!",
  enter_vin_number: "Ingrese su número de chasis",
  next: "próximo",
  help: "Ayudar",
  check_if_stolen: "comprobar si es robado",
  decode_vin_number: "Decodificar número VIN",
  additional_links: "Enlaces adicionales",
  no_data_valuation: "No hay datos para la valoración",
  check_vin_correct: "Verifique la exactitud del número VIN",
  how_to_vin_valuation: "¿Cómo evaluar un vehículo antes de comprar/vender?",
  calculator_valuation: "Calculadora de valoración de coches en línea",
  calculator_valuation_desc:
    "Hay muchas formas de estimar el valor de un automóvil, la más rápida y relativamente precisa será verificarlo en un portal de valoración de vin. Nuestro algoritmo patentado respaldado por inteligencia artificial (IA) agrega datos de miles de anuncios en línea, analizando con precisión la edad, la marca, el equipo y la condición de los vehículos en exhibición. Sobre esta base, estimamos cuánto debería valer el automóvil que está revisando.",
  you_get_valuation_for_free:
    "Recibirás un <strong>presupuesto</strong> en unos instantes sin moverte de tu casa, <strong>completamente gratis</strong>. Todo lo que necesitamos son algunos parámetros, como el número VIN o la fecha de registro del vehículo.",
  in_response_you_receive_not_only_valuation:
    "En respuesta, recibirá no solo una valoración de la cosecha que está consultando, sino también algunas de sus más cercanas, jóvenes y antiguas. Gracias a esto, tiene una idea completa de si, por ejemplo, todavía vale la pena tenerlo, o si no perderá demasiado y sería bueno venderlo de inmediato.",
  compare_market_price: "Comparar precios en el mercado",
  what_we_offer_free:
    "Lo que ofrecemos de forma gratuita, por supuesto, puede hacerlo manualmente. <strong>Explore los anuncios clasificados en Internet</strong> o visite varios concesionarios de automóviles para conocer los precios actuales de los vehículos que se ofrecen.",
  if_you_are_not_sure:
    "Si no estás seguro de qué datos se necesitan para <strong>valorar un coche</strong>, puedes ponerte en contacto con un <strong>tasador de coches</strong> o acudir a un concesionario o concesionario de coches de segunda mano. También le ayudarán a estimar el valor de su automóvil. Sin embargo, tenga en cuenta que el precio ofrecido por el proveedor o minorista probablemente será mucho más bajo. También necesitan ganar dinero para comprarle un automóvil.",
  regardless_of_which_method:
    "Independientemente del método que elijas, recuerda que la valoración del coche es un precio aproximado. El valor final del vehículo depende de muchos factores, como la condición técnica, el equipo, el historial de servicio y la impresión general que el auto genera en el comprador potencial. Es importante comprobar minuciosamente el estado de tu coche antes de venderlo o comprarlo",
};
