<template>
  <div class="container">
    <div class="row d-xl-flex flex-row align-content-center">
      <div
        class="
          col
          d-xl-flex
          flex-column
          justify-content-center
          align-content-center
          justify-content-xl-start
        "
      >
        <h2
          class="
            d-flex d-xl-flex
            flex-row
            fw-bold
            justify-content-center
            align-content-center align-self-center
          "
          :class="{ 'dark': theme == 'dark', 'light': theme == 'light' }"
          style="padding: 20px; margin-bottom: 0px"
        >
          {{ vehicleInfo.make }} {{ vehicleInfo.model }}
          {{ vehicleInfo.prodYear }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleInfo",
  props: {
    vehicleInfo: Object,
    theme: String,
  },
};
</script>

<style scoped>
.dark {
  color: white;
}

.light {
  color: black;
}
</style>