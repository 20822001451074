export const en = {
  car_valuation: "VEHICLE VALUATION",
  toggle_navigation: "Toggle navigation",
  vin: "VIN",
  plate: "Table",
  mileage: "Mileage",
  first_reg_date: "date of first registration",
  contact: "Contact",
  privacy_policy: "Privacy policy",
  regulations: "Statute",
  valuation: "The valuation of the vehicle has been prepared on the basis of many years of experience of our experts in the automotive market. The valuation procedure uses artificial intelligence (AI) to compare the changing trends in the prices of motor vehicles on the European and American markets. The valuation, of course, takes into account such parameters as the age of the vehicle and its mileage. Our valuation also takes into account the latest automotive and social problems, such as the lack of subassemblies or components and inflation, which affect the price of used vehicles.",
  min_price: "Minimal price",
  med_price: "Average price",
  max_price: "Maximum price",
  we_have_report_for_you: "We have a report for this vehicle!",
  incident_history: "Incident history",
  odometer_reading: "Mileage reading",
  vehicles_inspection: "Vehicle inspections",
  archive_photos: "Archival photos",
  check_now: "SEE REPORT",
  unfortunately_not_found_desc:
    "Unfortunately, we have not found the production year for this vehicle, please enter it in the field above.",
  type_odometer:
    "Enter the current mileage of the vehicle in kilometers for a better estimate of its value.",
  check_another_vin: "Rate another vehicle",
  type_plate: "Enter the license plate of the vehicle.",
  type_first_reg_date:
    "Enter the date of the first registration of the vehicle.",
  price_odometer: "Price",
  price_year: "Price",
  year: "Year",
  regulations_admission:
    '<p>1. These Terms and Conditions govern your use of the website ran under the brand "VinValuation", in the Internet domain "www.vinvaluation.com" (hereinafter referred to as the "Website"). If you disagree with these Terms and Conditions or any part of these Terms and Conditions, you must not use the Website.</p><p>2. The full name of our enterprise is "Car Data Experts LTD.". Unit 4E enterprise court, Farfield park, Rotherham S63 5DB. Companies house number: 10327199. You can contact us by the email: <a href="mailto:{email}">{email}</a>.</p><p>3. The main objective of the Website is to provide its users with a possibility to place data on stolen or damaged vehicles in the database “Car Data" (Vehicle Data) and making it available to other persons using that database.</p><p>4. Our services available on the Website are dedicated for individuals, companies and other entities, who or which are located or inhabited in the territory of European Union or United States of America.</p><p>5. Services on the Website are destined for users who are at least 18 years old.</p><p>6. Rules of uur use of your personal data and cookies are subject to regulation in our Privacy Policy available on the Website.</p><p>7. To use the Website you need to use a device which has access to Internet and to Internet browser such as Internet Explorer 9.0 or similar software. For using some options of the Website it may be necessary to use the cookies or to use Java software and Java Script. We do not bear any liability for any lack of access to the data stored on the Website due to the lack of conformity of your device with technical requirements stipulated hereinabove.</p>',
  regulations_definition:
    '<p>In these Terms and Conditions:<p>"Vehicle Data" means data of stolen or damaged cars and circumstances of the vehicle theft and cause of the damage placed by the Website users in the Database and made available to other persons using that database.</p><p>"Consumer" means any natural person who is acting for purposes which are outside his or her trade, business or profession.</p><p>"Database" means our database "Car Data" on technical state and history of vehicles.</p><p>"VIN Number" means the Vehicle Identification Number that is a unique code used by the automotive industry to identify individual motor vehicles, towed vehicles, motorcycles, scooters and mopeds as defined in ISO 3833.</p>',
  regulations_license_desc:
    "<p>1. Unless otherwise stated, we or our licensors own the intellectual property rights in the Website and material on the Website. Subject to the license below, all these intellectual property rights are reserved.</p><p>2.You may view, download for caching purposes only, and print pages from the Website for your own personal use, subject to the restrictions set out below and elsewhere in these Terms and Conditions.</p><p>3. You must not in particular:</p>    <p>a. republish material from the Website (including republication on another website);</p> <p>b. sell, rent, lending, donation, transfer or sub-license material from the Website;</p>    <p>c. show any material from the Website in public;</p> <p>d. reproduce, duplicate, copy or otherwise exploit material on the Website for a commercial purpose;</p> <p>e. edit or otherwise modify any material on the Website; or</p> <p>f. redistribute material from the Website.</p> <p>unless you receive our express written consent to do so.</p>",
  regulations_use_page:
    "<p>1. You must not use the Website in any way that causes, or may cause, damage to the Website or impairment of the availability or accessibility of the Website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. Information, graphics and other materials available on the Website are subject to copyrights protection.</p><p>2. You must not use the Website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p><p>3. You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to the Website without our express written consent.</p><p>4. You must not use the Website to transmit or send unsolicited commercial communications.</p><p>5. You must not use the Website for any purposes related to marketing without our express written consent.</p>",
  regulations_limited_access:
    "<p>1. Access to certain areas of the Website is restricted. We have the right to restrict access to other areas of the Website, or to the whole Website, subject to rights of the users arising from the previously concluded agreements.</p><p>2. If we provide you with a user ID and password to enable you to access restricted areas of the Website or other content or services, you must ensure that the user ID and password are kept confidential.</p>",
  regulations_user_posted:
    "<p>1. Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, rules of social existence, Netiquette nor other established customs, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).</p><p>2. You must not submit any user content to the Website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p><p>3. We reserve the right to edit or remove any material submitted to the Website, or stored on our servers, or hosted or published upon the Website.</p><p>4. Notwithstanding our rights under these Terms and Conditions and binding law in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on the Website.</p><p>5. You are requested to store all data uploaded on the Website also on your own devices to prevent them additionally from being lost.</p>",
  regulations_placing_data_in_db:
    "<p>1. The Website user places Vehicle Data in the Database by filling in a form on the Website.</p><p>2. The Website user is responsible for conformity of the Vehicle Data with the up-to-date actual state. In case of a change in the actual state the user shall immediately update the Vehicle Data placed in the Database.</p><p>3. After the Vehicle Data is placed in the Database the Website user will receive an e-mail to the e-mail address provided to us with confirmation of placement of that data in the Database.</p><p>4. The Vehicle Data is placed in the Database and made available to other persons using the Database in order to collect information on a particular vehicle identified by the VIN Number of that vehicle.</p><p>5. If any new information regarding the Vehicle Data appears in the Database, we will make efforts to notify about it the Website user, who placed the Vehicle Date in the Database, to the e-mail address provided to us.</p>",
  regulations_limited_and_warranties_complaints:
    '<p>1. To avoid any misunderstanding, pursuant to the agreement between you and us on providing the Vehicle Data to the Database we are not obliged to search and find the vehicle referred to in the Vehicle Data.</p><p>2. In case you are not the Consumer, to the maximum extent permitted by applicable law we exclude all representations, warranties and conditions relating to the Website and the use of the Website (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</p><p>3. Should you notice any technical problems of the Website, or if you find that any data provided by us is incomplete, or not up-to-date, or if you have any complaints regarding the Website, you should immediately notify us about this by the "Contact us" form or to the e-mail address stated above in Introduction to the Terms and Conditions. We make efforts to respond to your e-mail as soon as possible.</p>',
  regulations_liability:
    "<p>1. Nothing in these Terms and Conditions will (a) limit or exclude our or your liability for fraud or fraudulent misrepresentation; (b) limit any of our or your liabilities in any way that is not permitted under applicable law; or (c) exclude any of our or your liabilities that may not be excluded under applicable law; in particular it will not exclude any of our or your liability for damage or loss made by fraud (meant as intentional guilt).<p>2. The limitations and exclusions of liability set out in these Terms and Conditions:</p><p>a. are subject to the paragraph 1 above;</p><p>b. govern all liabilities arising under the Terms and Conditions or Private Policy in relation to the subject matter of the Terms and Conditions or Private Policy, including liabilities arising in contract, tort, and/or delict; and</p><p>c. are not applicable to the Consumers.</p><p>3. In case you are not the Consumer, as far as possible under applicable law:</p><p>a. we will not be liable for any loss or damage of any nature, unless those loss or damage were done by fraud (meant as intentional guilt), in particular any liability on the basis of warranty rule (rękojmia) or similar is hereby excluded;</p><p>b. we will not be liable to you in respect of any loss or corruption of any data, database or software both our and yours.</p><p>4. We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.</p>",
  regulations_relief:
    "<p>In case you are not the Consumer, you hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of any breach by you of any provision of these Terms and Conditions, or arising out of any claim that you have breached any provision of these Terms and Conditions.</p>",
  reulations_violations:
    "<p>Without prejudice to our other rights under these Terms and Conditions, if you breach these Terms and Conditions in any way, we may take such action as we deem appropriate to deal with the breach, which aims to stop continuation of the breach and to limit scope of the damage caused by the breach, including suspending your access to the Website, prohibiting you from accessing the Website, blocking computers using your IP address from accessing the Website, contacting your Internet service provider to request that they block your access to the Website and/or bringing court proceedings against you.</p>",
  regulations_changing_provisions_regulations:
    "<p>1. We may update this Terms and Conditions from time-to-time by posting a new version on the Website. In case you have provided us with your personal data, including your e-mail address, and if our services for you are being provided at the time of change of this Terms and Conditions, you will be notified about every such change.</p><p>2. In case you are not the Consumer, change of our contact details will not constitute amendment to this Terms and Conditions.</p><p>3. You should keep your e-mail address specified upon registration on the Website up-to-date.</p>",
  regulations_transfer:
    "<p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these Terms and Conditions.</p>",
  regulations_severability_clause:
    "<p>If a provision of these Terms and Conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>",
  regulations_exclusion_parts:
    "<p>These Terms and Conditions are for the benefit of you and us, and are not intended to benefit any third party or be enforceable by any third party. The exercise of our and your rights in relation to these Terms and Conditions is not subject to the consent of any third party.</p>",
  regulations_scope_contract:
    "<p>These Terms and Conditions, together with our Privacy Policy, and prices stipulated on the Website constitute the entire agreement between you and us in relation to your use of the Website, and supersede all previous agreements in respect of your use of the Website.</p>",
  regulations_governing_law:
    "<p>1. These Terms and Conditions will be governed by and construed in accordance with Polish law; within the scope not regulated by these Terms and Conditions the Polish Civil Code dated April 23, 1964, Act on Consumers Rights dated May 30, 2014 (with respect to the Consumers) and Act on Services Rendered by Electronic Means of Communication dated July 18, 2002 shall apply in particular.</p><p>2. In case you are not a Consumer these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of Poland.</p><p>3. In case you are a Consumer and your domicile is located on the territory of Poland as of the date of conclusion the agreement between you and us on providing the Vehicle Data any disputes relating to these Terms and Conditions or Private Policy shall be subject to the exclusive jurisdiction of the courts of Poland.</p>",
  back: "Back",
  //nowy
  definition: "Definitions",
  admission: "Introduction",
  license_use_page: "License to use the Website",
  use_page: "Acceptable use",
  limited_access: "Restricted access",
  user_posted_content: "User generated content",
  placing_vehicle_data_in_db: "Placing Vehicle Data in the Database",
  disclaimer_of_liability: "Limited warranties and complaints from the users",
  relief_from_obligations: "Indemnity",
  violations_of_regulations: "Breaches of these Terms and Conditions",
  transfer: "Assignment",
  severability_clause: "Severability",
  exclusion_third_parts: "Exclusion of third party rights",
  scope_of_contract: "Entire agreement",
  changing_of_provisions_regulations: "Variation",
  governing_law_and_jurisdiction: "Law and jurisdiction",
  limited_and_warranties_complaints:
    "Limited warranties and complaints from the users",
  enter_vin_number: "Enter your VIN number",
  check_valuation: "Valuation",
  next: "Next",
  help: "Help",
  check_if_stolen: "Check if stolen",
  decode_vin_number: "Decode VIN number",
  additional_links: "Additional links",
  no_data_valuation: "No data for valuation",
  check_vin_correct: "Check the correctness of the VIN number",
  how_to_vin_valuation: "How to evaluate a vehicle before buying/selling?",
  calculator_valuation: "Online car valuation calculator",
  calculator_valuation_desc: "There are many ways to estimate the value of a car, the fastest and relatively accurate will be to check it on a vin valuation portal. Our proprietary algorithm supported by artificial intelligence (AI) aggregates data from thousands of online advertisements, precisely analyzing the age, make, equipment and condition of the vehicles on display. On this basis, we estimate how much the car you are checking should be worth.",
  you_get_valuation_for_free: "You will receive a <strong>quotation</strong> a few moments without leaving your home, <strong>completely free</strong>. All we need is a few parameters, such as the VIN number or vehicle registration date.",
  in_response_you_receive_not_only_valuation: "In response, you will receive not only a valuation of the vintage you are checking, but also a few of your closest, younger and older ones. Thanks to this, you have full insight into whether, for example, it is still worth owning it, or whether you will not lose too much on it and it would be good to sell it right away.",
  compare_market_price: "Compare prices on the market",
  what_we_offer_free: "What we offer free of charge, you can of course do manually. <strong>Browse the classifieds on the Internet</strong> or visit several car dealerships to find out the current prices of the vehicles offered.",
  if_you_are_not_sure: "If you are not sure what data is needed to value a car, you can contact a <strong>car appraiser</strong> or turn to a <strong>dealer</strong> or second-hand car dealership. They will also help you estimate the value of your car. However, take into account that the price offered by the supplier or retailer will most likely be much lower. They also need to earn money to buy a car from you.",
  regardless_of_which_method: "Regardless of which method you choose, remember that the car valuation is an approximate price. The final value of the vehicle depends on many factors, such as technical condition, equipment, service history and the overall impression that the car makes on the potential buyer. It is important to thoroughly check the condition of your car before selling or buying it.",
  main_tip: "Use our website to price online cars using the VIN number. Thanks to this, you will quickly and carefully find out how much your car is worth. All you have to do is enter the VIN number of your car and we will help you evaluate its value. Don't wait, check now!",
  h1_title: "Free vehicle valuation ",
  meta_title: "Online car valuation calculator - quickly and carefully rate your car.",
  meta_desc: "Valuation of the car online, quickly and accurately assess the value of your car. Our calculator will give the price of the vehicle checked and slightly older and younger for comparison.",
};
