export const nl = {
  car_valuation: "VOERTUIGCLASSIFICATIE",
  toggle_navigation: "schakelen tussen navigatie",
  vin: "VIN",
  plate: "tafel",
  mileage: "kilometerstand",
  first_reg_date: "Datum eerste inschrijving",
  check_valuation: "waardering",
  contact: "Contact",
  privacy_policy: "Privacybeleid",
  regulations: "statuut",
  valuation:
    "De taxatie van het voertuig is opgesteld op basis van jarenlange ervaring van onze experts in de automobielmarkt. De waarderingsprocedure maakt gebruik van kunstmatige intelligentie (AI) om de veranderende trends in de prijzen van motorvoertuigen op de Europese en Amerikaanse markt te vergelijken. Bij de taxatie wordt uiteraard rekening gehouden met parameters als de leeftijd van het voertuig en de kilometerstand. Onze taxatie houdt ook rekening met de nieuwste auto- en maatschappelijke problemen, zoals het gebrek aan subassemblages of componenten en inflatie, die de prijs van gebruikte voertuigen beïnvloeden.",
  min_price: "Minimum prijs",
  med_price: "Gemiddelde prijs",
  max_price: "maximale prijs",
  we_have_report_for_you: "We hebben een rapport voor dit voertuig!",
  incident_history: "incident geschiedenis",
  odometer_reading: "kilometerstand lezen",
  vehicles_inspection: "keuringen van voertuigen",
  archive_photos: "archief foto's",
  check_now: "ZIE RAPPORT",
  unfortunately_not_found_desc: "",
  type_odometer: "",
  check_another_vin: "Beoordeel een ander voertuig",
  type_plate: "Voer het kenteken van het voertuig in.",
  type_first_reg_date: "",
  price_odometer: "prijs",
  price_year: "prijs",
  year: "Jaar",
  enter_vin_number: "Vul uw chassisnummer in",
  next: "Volgende",
  help: "Helpen",
  no_data_valuation: "Geen gegevens voor taxatie",
  check_if_stolen: "Controleer of het gestolen is",
  decode_vin_number: "VIN-nummer decoderen",
  additional_links: "Extra koppelingen",
  check_vin_correct: "Controleer de juistheid van het VIN-nummer",
  how_to_vin_valuation:
    "Hoe een voertuig evalueren alvorens te kopen/verkopen?",
  calculator_valuation: "Online auto taxatiecalculator",
  calculator_valuation_desc:
    "Er zijn veel manieren om de waarde van een auto te schatten, de snelste en relatief nauwkeurige is om deze te controleren op een vin-taxatieportaal. Ons eigen algoritme, ondersteund door kunstmatige intelligentie (AI), verzamelt gegevens van duizenden online advertenties en analyseert nauwkeurig de leeftijd, het merk, de uitrusting en de staat van de tentoongestelde voertuigen. Op basis hiervan schatten wij in hoeveel de auto die u checkt waard moet zijn.",
  you_get_valuation_for_free:
    "U ontvangt binnen enkele ogenblikken een <strong>offerte</strong> zonder uw huis te verlaten, <strong>helemaal gratis</strong> . Het enige dat we nodig hebben, zijn enkele parameters, zoals het VIN-nummer of de registratiedatum van het voertuig.",
  in_response_you_receive_not_only_valuation:
    "Als reactie hierop ontvangt u niet alleen een waardering van de vintage die u controleert, maar ook enkele van uw naaste, jongere en oudere. Hierdoor heeft u volledig inzicht of het bijvoorbeeld nog de moeite waard is om het te bezitten, of u er niet te veel aan kwijt bent en het goed is om het direct te verkopen.",
  compare_market_price: "Vergelijk prijzen op de markt",
  what_we_offer_free:
    "Wat wij gratis aanbieden, kunt u natuurlijk handmatig doen. <strong>Blader door de advertenties op internet</strong> of bezoek verschillende autodealers om de huidige prijzen van de aangeboden voertuigen te weten te komen.",
  if_you_are_not_sure:
    "Als u niet zeker weet welke gegevens nodig zijn om een auto te <strong>taxeren, kunt u contact opnemen met een autotaxateur</strong> of een <strong>dealer</strong> of tweedehandsautodealer. Ook helpen ze u bij het inschatten van de waarde van uw auto. Houd er echter rekening mee dat de prijs aangeboden door de leverancier of detailhandelaar hoogstwaarschijnlijk veel lager zal zijn. Ze moeten ook geld verdienen om een auto van je te kopen.",
  regardless_of_which_method:
    "Welke methode u ook kiest, onthoud dat de autowaardering een geschatte prijs is. De uiteindelijke waarde van het voertuig is afhankelijk van vele factoren, zoals de technische staat, uitrusting, onderhoudshistorie en de algehele indruk die de auto maakt op de potentiële koper. Het is belangrijk om de staat van uw auto grondig te controleren voordat u deze verkoopt of koopt.",
  main_tip:
    "Gebruik onze website om online auto's te prijzen met behulp van het VIN-nummer. Hierdoor komt u snel en zorgvuldig te weten hoeveel uw auto waard is. Het enige wat u hoeft te doen is het VIN-nummer van uw auto in te voeren en wij helpen u de waarde ervan te bepalen. Wacht niet langer, check nu!",
  h1_title: "Gratis taxatie van voertuigen",
  meta_title:
    "Online autotaxatiecalculator - beoordeel uw auto snel en zorgvuldig.",
  meta_desc:
    "Waardebepaling van de auto online, snel en nauwkeurig de waarde van uw auto bepalen. Onze calculator geeft de prijs van het gecontroleerde voertuig en iets ouder en jonger ter vergelijking.",
};
